// General
import React, { useCallback, useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { getAllCheckoutsByDate, getCheckoutById, addNewCheckout, updateCheckout, deleteCheckout, resetgetByIdStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storages/slices/backend/CheckoutSlice';
// Contexts
import { useFunctions } from '../../../contexts/FunctionsContext';
import { useData } from '../../../contexts/DataContext';
// Icons
import { TbPrinter } from "react-icons/tb";
import { TbBook2 } from 'react-icons/tb';
import { HiMiniChevronDown } from 'react-icons/hi2';
import { LuFolderPlus, LuFolderCog2, LuFolderMinus } from "react-icons/lu";
// Modals
import CheckoutAddModal from '../modals/checkout/CheckoutAddModal';
import CheckoutUpdateModal from '../modals/checkout/CheckoutUpdateModal';
import DeleteModal from '../modals/DeleteModal';
// Components
import Table from '../components/Table';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';

function Checkout() {
  // Storage Import
  const dispatch = useDispatch();
  const { checkouts, checkout, getByIdStatus, getAllStatusByDate, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.checkout);
  // Context Import
  const { today, lastWeekDate, myUser } = useData();
  const { stringMoney, stringDate } = useFunctions();
  // Variables
  const [canfetchData, setCanFetchData] = useState(false);
  // Filtering
  const [startdate, setStartdate] = useState(lastWeekDate);
  const [enddate, setEnddate] = useState(today);
  // Data & Api
  const [data, setData] = useState([]);
  useEffect(() => {
    if (getAllStatusByDate === "fulfilled") {
      const combined = checkouts.map((data) => {
        const id = data.id;
        const date = stringDate(data.date);
        const user = data.user;
        const info = data.informations;
        const type = `${data.paymentType} ${data.paymentMethod}`;
        const status = data.status;
        const total = stringMoney(data.total, 'TL');
        return { id, date, user, info, type, status, total };
      });
      setData(combined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatusByDate, checkouts, startdate, enddate]);
  const fetchAllDataSequentially = async (dispatch, signal, start, end) => {
    try {
      await dispatch(getAllCheckoutsByDate({ signal, start: start, end: end }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal, startdate, enddate); }
    return () => { controller.abort(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canfetchData, dispatch, startdate, enddate]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseCheckoutAddModal();
      dispatch(getAllCheckoutsByDate({ signal: null, start: startdate, end: enddate }));
      dispatch(resetAddStatus());
      dispatch(addToast({ background: 'success', icon: 'add', message: 'Teklif Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseCheckoutUpdateModal();
      dispatch(getAllCheckoutsByDate({ signal: null, start: startdate, end: enddate }));
      dispatch(resetUpdateStatus());
      dispatch(addToast({ background: 'primary', icon: 'update', message: 'Teklif Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseCheckoutDeleteModal();
      dispatch(getAllCheckoutsByDate({ signal: null, start: startdate, end: enddate }));
      dispatch(resetDeleteStatus());
      dispatch(addToast({ background: 'danger', icon: 'delete', message: 'Teklif Bilgileri Silindi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      setInputDataFoCheckoutUpdateModal(checkout);
      setShowCheckoutUpdateModal(true);
      dispatch(resetgetByIdStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus, dispatch]);
  useEffect(() => {
    if (error && error !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'user', name: 'PERSONEL' }, { value: 'info', name: 'AÇIKLAMA' }, { value: 'type', name: 'ŞEKLİ' }, { value: 'status', name: 'DURUM' }, { value: 'total', name: 'TUTAR' }];
  const filters = ['id', 'date', 'user', 'info', 'type', 'status', 'total'];
  const buttons = [
    {
      text: 'Güncelle',
      icon: <LuFolderCog2 className='button-icon' />,
      onclick: ((rowData) => { handleShowCheckoutUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <LuFolderMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowCheckoutDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  const select = (id) => { handleShowCheckoutUpdateModal(id); }
  const dates = (startDate, endDate) => { setStartdate(startDate); setEnddate(endDate); }
  const tableContent = useMemo(() => {
    if (getAllStatusByDate === 'fulfilled') {
      return (
        <Table
          data={data}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          setDates={{ start: startdate, end: enddate }}
          dates={dates}
          buttons={buttons}
          bodyAlignBroker={['customerInfo']}
        />
      );
    } else if (getAllStatusByDate === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatusByDate, data]);
  // Dropdown
  const [operationsDropdown, setOperationsDropdown] = useState(false);
  const toggleOperationsDropdown = () => { setOperationsDropdown(!operationsDropdown); };
  const handleClickOutside = useCallback((event) => { if (!event.target.closest('.operationsdropdown')) { setOperationsDropdown(false); } }, []);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => { document.removeEventListener('click', handleClickOutside); };
  }, [handleClickOutside]);
  // Modals
  // Checkout Add Modal
  const [showCheckoutAddModal, setShowCheckoutAddModal] = useState(false);
  const handleCloseCheckoutAddModal = () => { setShowCheckoutAddModal(false); }
  const handleShowCheckoutAddModal = () => { setShowCheckoutAddModal(true); }
  const handleAddCheckoutAddModal = (values) => {
    dispatch(addNewCheckout({ newcheckout: values }));
    handleCloseCheckoutAddModal();
  }
  // Checkout Update Modal
  const [showCheckoutUpdateModal, setShowCheckoutUpdateModal] = useState(false);
  const [inputDataForCheckoutUpdateModal, setInputDataFoCheckoutUpdateModal] = useState({});
  const handleCloseCheckoutUpdateModal = () => {
    setShowCheckoutUpdateModal(false);
    setInputDataFoCheckoutUpdateModal({});
  }
  const handleShowCheckoutUpdateModal = (id) => {
    dispatch(getCheckoutById({ id: id }));
  }
  const handleUpdateCheckoutUpdateModal = (values) => {
    dispatch(updateCheckout({ checkout: values }));
    handleCloseCheckoutUpdateModal();
  }
  // Checkout Delete Modal
  const [showCheckoutDeleteModal, setShowCheckoutDeleteModal] = useState(false);
  const [inputDataForCheckoutDeleteModal, setInputDataFoCheckoutDeleteModal] = useState(-1);
  const handleCloseCheckoutDeleteModal = () => {
    setShowCheckoutDeleteModal(false);
    setInputDataFoCheckoutDeleteModal(-1);
  }
  const handleShowCheckoutDeleteModal = (id) => {
    setInputDataFoCheckoutDeleteModal(id);
    setShowCheckoutDeleteModal(true);
  }
  const handleDeleteCheckoutDeleteModal = () => {
    dispatch(deleteCheckout({ id: inputDataForCheckoutDeleteModal }));
    handleCloseCheckoutDeleteModal();
  }
  // HTML
  return (
    <div>
      <div className='card my-3'>
        <div className='card-header m-0 p-1'>
          <div className='row d-flex align-items-center'>
            <div className='col-12 col-md-7'>
              <div className='row h-100 m-0 p-2'>
                <div className='d-flex align-items-center'>
                  <div className='card-title m-0 p-0'>Kasa</div>
                </div>
              </div>
            </div>
            <div className='col-12 ps-3 col-md-5 '>
              <div className='row d-flex justify-content-end m-1'>
                <div className='col-12 col-md-5 ps-0 '>
                  <div className="dropdown">
                    <button className="button-with-icon btn btn-success operationsdropdown  dropdown-toggle w-100" onClick={toggleOperationsDropdown} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <TbBook2 className='button-icon' />İşlemler<HiMiniChevronDown style={{ fontSize: '1.25em' }} />
                    </button>
                    {operationsDropdown && (
                      <ul className="dropdown-menu operationsdropdown w-100 show">
                        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowCheckoutAddModal(); }}>
                          <LuFolderPlus className='button-icon' />
                          Kasa Hareketi Ekle
                        </button>
                        <button onClick={(e) => { e.stopPropagation(); dispatch(exportPdf({ rowsPerPage: rows, data: data, cols: cols, pdftitle: "Teklifler" })) }} className="dropdown-item">
                          <TbPrinter className='button-icon' />
                          Yazdır
                        </button>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body'>
          {tableContent}
          <div className='row d-flex justify-content-end mb-2'>
            <div className='col-12 col-md-12'>
              <div className='row g-1'>
                <div className='col-6 col-md-3 d-flex align-items-stretch'>
                  <div className='card bg-success text-white m-0 p-1 flex-fill'>
                    <div className='card-title'><b>Gelen</b></div>
                    <div className='d-flex flex-column justify-content-center h-100'>
                      <p className='p-0 m-0'><b>Nakit:</b> 0 TL</p>
                      <p className='p-0 m-0'><b>EFT / Havale:</b> 0 TL</p>
                      <p className='p-0 m-0'><b>Kredi Kartı:</b> +5.000,00 TL</p>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-3 d-flex align-items-stretch'>
                  <div className='card bg-dark text-white m-0 p-1 flex-fill'>
                    <div className='card-title'><b>TV Gider</b></div>
                    <div className='d-flex flex-column justify-content-center h-100'>
                      <p className='p-0 m-0'><b>Nakit:</b> 0 TL</p>
                      <p className='p-0 m-0'><b>EFT / Havale:</b> 0 TL</p>
                      <p className='p-0 m-0'><b>Kredi Kartı:</b> 0 TL</p>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-3 d-flex align-items-stretch'>
                  <div className='card bg-primary text-white m-0 p-1 flex-fill'>
                    <div className='card-title'><b>Genel Toplam</b></div>
                    <div className='d-flex align-items-center h-100'>
                      <p className='p-0 m-0'><b>Toplam:</b> +2.000,00 TL</p>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-3 d-flex align-items-stretch'>
                  <div className='card bg-danger text-white m-0 p-1 flex-fill'>
                    <div className='card-title'><b>Giden Toplam</b></div>
                    <div className='d-flex align-items-center h-100'>
                      <p className='p-0 m-0'><b>Toplam:</b> -4.000,00 TL</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CheckoutAddModal
            show={showCheckoutAddModal}
            handleClose={handleCloseCheckoutAddModal}
            handleAdd={handleAddCheckoutAddModal}
          />
          <CheckoutUpdateModal
            show={showCheckoutUpdateModal}
            handleClose={handleCloseCheckoutUpdateModal}
            handleUpdate={handleUpdateCheckoutUpdateModal}
            inputData={inputDataForCheckoutUpdateModal}
          />
          <DeleteModal
            show={showCheckoutDeleteModal}
            handleClose={handleCloseCheckoutDeleteModal}
            handleDelete={handleDeleteCheckoutDeleteModal}
            title={"Kasa İşlemini Silmek İstiyor Musunuz?"}
            message={`${inputDataForCheckoutDeleteModal} id numaralı kasa işlemini silmek istiyor musunuz?`}
            deleteIcon={<LuFolderMinus />}
          />
        </div>
      </div>
    </div>
  );
}

export default Checkout