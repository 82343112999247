// General
import React, { useState, useEffect, useRef } from "react";
// Styles
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import randomColor from "randomcolor";
// Context
import { useFunctions } from "../../../contexts/FunctionsContext";

const PieChart = ({ data = [], unit = "" }) => {
  const chartRef = useRef(null);
  const { stringMoney } = useFunctions();
  const defaultColors = ["#4169e1", "#ff8247", "#66cd00", "#6959cd", "#ffd700", "#ff3030", "#ff4500", "#ff1493", "#008080"];
  const [chartColors, setChartColors] = useState([]);
  const totalValue = React.useMemo(() => {
    return Array.isArray(data) ? data.reduce((acc, item) => acc + (item.value || 0), 0) : 0;
  }, [data]);
  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  useEffect(() => {
    setChartColors(data.length > defaultColors.length ? data.map(() => randomColor()) : defaultColors.slice(0, data.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    const labels = totalValue === 0 ? ["Yeterli Veri Yok"] : data.map((item) => item.name || "Bilinmeyen");
    const series = totalValue === 0 ? [0.000001] : data.map((item) => item.value || 0);
    setChartOptions({
      chart: {
        id: `pie-chart-${Math.random()}`,
        type: "pie",
        animations: { enabled: true, easing: "easeinout", speed: 800, },
      },
      labels,
      colors: totalValue === 0 ? ["#8b8989"] : chartColors,
      legend: { show: true, position: "bottom", horizontalAlign: "center", },
      tooltip: {
        y: {
          formatter: (val) => unit === "TL" || unit === "USD" || unit === "EURO" ? stringMoney(val, unit) : `${val.toFixed(0)} ${unit}`,
        }
      },
    });
    setChartSeries(series);
  }, [data, unit, chartColors, totalValue, stringMoney]);
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (chartRef.current) {
        const chartId = chartOptions.chart?.id;
        if (chartId) { ApexCharts.exec(chartId, "destroy"); }
      }
    };
  }, [chartOptions]);
  const chartHeight = chartRef.current ? chartRef.current.offsetHeight || 300 : 300;
  return (
    <div ref={chartRef} style={{ height: chartHeight }}>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="pie"
        height={chartHeight}
      />
    </div>
  );
};

export default PieChart;