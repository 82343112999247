// General
import React, { useEffect, useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Storages
import { useDispatch } from 'react-redux';
import { getServiceById } from '../../../../storages/slices/backend/ServiceSlice';
// Contexts
import { useValidations } from '../../../../contexts/ValidationContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { RiFolderSettingsLine } from "react-icons/ri";

function OperationReportsUpdateModal({ show, handleClose, handleUpdate, inputData }) {
  // Storages Import
  const dispatch = useDispatch();
  // Context Import
  const { validationSchema_OperationReports } = useValidations();
  const { stringOnlyNumbers } = useFunctions();
  // Variables
  const [serviceData, setServiceData] = useState(null);
  // Functions
  const getService = async (id) => {
    const response = await dispatch(getServiceById({ id: id }));
    if (response.payload) { setServiceData(response.payload) }
    else { setServiceData(null); }
  }
  useEffect(() => {
    if (inputData.serviceId && show) {
      getService(inputData.serviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData, show]);
  // Formik Variables
  const initialValues = {
    id: inputData.id || 0,
    serviceId: inputData.serviceId || 0,
    date: inputData.date || '',
    fault: inputData.fault || '',
    transactions: inputData.transactions || '',
    informations: inputData.informations || '',
    total: inputData.total || 0,
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header closeButton>
          <Modal.Title>İşlem Raporunu Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik validationSchema={validationSchema_OperationReports} initialValues={initialValues} onSubmit={(values) => handleUpdate(values)}>
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='OperationReportsUpdateModal_serviceId'
                      label='Servis'
                      value={`${serviceData?.id} - ${serviceData?.name} - ${serviceData?.brandName}/${serviceData?.deviceName}`}
                      slotProps={{ input: { readOnly: true } }}
                      variant='outlined'
                      size='small'
                      fullWidth
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='OperationReportsUpdateModal_fault'
                      name='fault'
                      label="Arıza"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={4}
                      error={touched.fault && !!errors.fault}
                      helperText={touched.fault && errors.fault}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='OperationReportsUpdateModal_transactions'
                      name='transactions'
                      label="Yapılanlar"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={4}
                      error={touched.transactions && !!errors.transactions}
                      helperText={touched.transactions && errors.transactions}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='OperationReportsUpdateModal_informations'
                      name='informations'
                      label="Bilgiler"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={3}
                      error={touched.informations && !!errors.informations}
                      helperText={touched.informations && errors.informations}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='OperationReportsUpdateModal_total'
                      name='total'
                      label='Maliyet'
                      value={values.total}
                      onChange={(e) => { setFieldValue('total', parseFloat(stringOnlyNumbers(e.target.value))); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.total && !!errors.total}
                      helperText={touched.total && errors.total}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-primary w-100' ><RiFolderSettingsLine className='button-icon' />Güncelle</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  )
}

export default OperationReportsUpdateModal