// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/StockActions/';

// Async Thunks
export const getAllStockActionsByStockId = createAsyncThunk('backend_getallstockactionbystockid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllStockActionsByStockId?stockId=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewStockAction = createAsyncThunk('backend_addnewstockaction', async ({ newstockaction }) => {
  try {
    const res = await axios.post(`${baseurl}AddStockAction`, newstockaction, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteStockAction = createAsyncThunk('backend_deletestockaction', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteStockAction?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const stockActionSlice = createSlice({
  name: 'stockAction',
  initialState: {
    stocks: [],
    getByIdStatus: null,
    addStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetAddStatus: (state) => { state.addStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetgetByIdStatus: (state) => { state.getByIdStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStockActionsByStockId.pending, (state) => { state.getByIdStatus = "pending"; })
      .addCase(getAllStockActionsByStockId.fulfilled, (state, action) => { state.getByIdStatus = "fulfilled"; state.stocks = action.payload || []; })
      .addCase(getAllStockActionsByStockId.rejected, (state, action) => { state.getByIdStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewStockAction.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewStockAction.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewStockAction.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteStockAction.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteStockAction.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteStockAction.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
})

export const { resetAddStatus, resetDeleteStatus, resetgetByIdStatus, resetError } = stockActionSlice.actions;
export default stockActionSlice.reducer;