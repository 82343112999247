// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { getAllShelfs, getShelfById, addNewShelf, updateShelf, deleteShelf, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetgetByIdStatus, resetError } from '../../../storages/slices/backend/ShelfSlice';
import { getAllWarehouses, resetError as resetWarehousesError } from '../../../storages/slices/backend/WarehousesSlice';
// Contexts
import { useData } from '../../../contexts/DataContext';
// Icons
import { TbDatabasePlus, TbDatabaseCog, TbDatabaseMinus } from "react-icons/tb";
import { TbPrinter } from "react-icons/tb";
// Modals
import ShelfAddModal from "../modals/shelves/ShelfAddModal";
import DeleteModal from "../modals/DeleteModal";
import ShelfUpdateModal from "../modals/shelves/ShelfUpdateModal";
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Shelves() {
  // Storage Import
  const dispatch = useDispatch();
  const { shelves, shelf, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.shelf);
  const { error: warehousesError } = useSelector((state) => state.warehouses);
  // Context Import
  const { myUser } = useData();
  // Variables
  const [canfetchData, setCanFetchData] = useState(false);
  // Data & Api
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllShelfs({ signal }));
      await dispatch(getAllWarehouses({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseShelveAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllShelfs({}));
      dispatch(addToast({ background: 'success', icon: 'patchAdd', message: 'Yeni Raf Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseShelveUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllShelfs({}));
      dispatch(addToast({ background: 'primary', icon: 'patchUpdate', message: 'Raf Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseShelveDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllShelfs({}));
      dispatch(addToast({ background: 'danger', icon: 'patchDelete', message: 'Raf Silindi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataForShelveUpdateModal(shelf);
        setShowShelveUpdateModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataForShelveDeleteModal({ id: shelf.id, name: shelf.name });
        setShowShelveDeleteModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus]);
  useEffect(() => {
    if (error && error !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  useEffect(() => {
    if (warehousesError && warehousesError !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: warehousesError, delay: 2000 }));
      dispatch(resetWarehousesError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehousesError]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'RAF' }, { value: 'warehouseName', name: 'DEPO' }, { value: 'description', name: 'AÇIKLAMA' }];
  const filters = ['id', 'name', 'warehouseName', 'description'];
  const select = (id) => {
    handleShowShelveUpdateModal(id);
  }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <TbDatabaseCog className='button-icon' />,
      onclick: ((rowData) => { handleShowShelveUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <TbDatabaseMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowShelveDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={shelves}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowShelveAddModal(); }}>
          <TbDatabasePlus className='button-icon' />
          Yeni Raf Ekle
        </button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: shelves, cols: cols, pdftitle: "Markalar" })) }}>
          <TbPrinter className='button-icon' />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Brand Add Modal
  const [showShelveAddModal, setShowShelveAddModal] = useState(false);
  const handleCloseShelveAddModal = () => { setShowShelveAddModal(false) };
  const handleShowShelveAddModal = () => { setShowShelveAddModal(true) };
  const handleAddShelveAddModal = (values) => {
    dispatch(addNewShelf({ newshelf: values }));
    handleCloseShelveAddModal();
  }
  // Brand Update Modal
  const [showShelveUpdateModal, setShowShelveUpdateModal] = useState(false);
  const [inputDataForShelveUpdateModal, setInputDataForShelveUpdateModal] = useState({});
  const handleCloseShelveUpdateModal = () => { setShowShelveUpdateModal(false); setInputDataForShelveUpdateModal({}); }
  const handleShowShelveUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getShelfById({ id: id }));
  }
  const handleUpdateShelveUpdateModal = (values) => {
    dispatch(updateShelf({ shelf: values }));
    handleCloseShelveUpdateModal();
  }
  // Brand Delete Modal
  const [showShelveDeleteModal, setShowShelveDeleteModal] = useState(false);
  const [inputDataForShelveDeleteModal, setInputDataForShelveDeleteModal] = useState(-1);
  const handleCloseShelveDeleteModal = () => { setShowShelveDeleteModal(false); setInputDataForShelveDeleteModal(-1); }
  const handleShowShelveDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getShelfById({ id: id }));
  }
  const handleDeleteShelveDeleteModal = () => {
    dispatch(deleteShelf({ id: inputDataForShelveDeleteModal.id }));
    handleCloseShelveDeleteModal();
  }
  // Return Modals
  const modals = () => {
    return (
      <>
        <ShelfAddModal
          show={showShelveAddModal}
          handleClose={handleCloseShelveAddModal}
          handleAdd={handleAddShelveAddModal}
        />
        <ShelfUpdateModal
          show={showShelveUpdateModal}
          handleClose={handleCloseShelveUpdateModal}
          handleUpdate={handleUpdateShelveUpdateModal}
          inputData={inputDataForShelveUpdateModal}
        />
        <DeleteModal
          show={showShelveDeleteModal}
          handleClose={handleCloseShelveDeleteModal}
          handleDelete={handleDeleteShelveDeleteModal}
          title="Raf'ı Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForShelveDeleteModal.name} adlı rafı silmek istediğinize emin misiniz?`}
          deleteIcon={<TbDatabaseMinus className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage
      title='Raflar'
      Operations={operations}
      Table={() => tableContent}
      Modals={modals}
    />
  );
}

export default Shelves;