// General
import React, { useEffect, useMemo, useState } from 'react';
// Storage
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { addNewWarehouse, deleteWarehouse, getAllWarehouses, getWarehouseById, resetAddStatus, resetDeleteStatus, resetError, resetgetByIdStatus, resetUpdateStatus, updateWarehouse } from '../../../storages/slices/backend/WarehousesSlice';
// Context
import { useData } from '../../../contexts/DataContext';
// Icons
import { BsFillHouseAddFill, BsFillHouseGearFill, BsFillHouseDashFill } from "react-icons/bs";
import { TbPrinter } from "react-icons/tb";
// Modals
import WarehousesAddModal from "../modals/warehouses/WarehousesAddModal"
import DeleteModal from "../modals/DeleteModal";
import WarehousesUpdateModal from "../modals/warehouses/WarehousesUpdateModal";
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Warehouses() {
  // Storages
  const dispatch = useDispatch();
  const { warehouses, warehouse, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.warehouses);
  // Context Import
  const { myUser, statusTranslator } = useData();
  // Variables
  const [canfetchData, setCanFetchData] = useState(false);
  // Data & Api
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllWarehouses({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseWarehousesAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllWarehouses({}));
      dispatch(addToast({ background: 'success', icon: 'packageAdd', message: 'Yeni Depo Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseWarehousesUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllWarehouses({}));
      dispatch(addToast({ background: 'primary', icon: 'packageUpdate', message: 'Depo Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseWarehousesDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllWarehouses({}));
      dispatch(addToast({ background: 'danger', icon: 'packageDelete', message: 'Depo Silindi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataForWarehousesUpdateModal(warehouse);
        setShowWarehousesUpdateModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataForWarehousesDeleteModal({ id: warehouse.id, name: warehouse.name });
        setShowWarehousesDeleteModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus, dispatch])
  useEffect(() => {
    if (error && error !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'DEPO ADI' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'name', 'status'];
  const select = (id) => {
    handleShowWarehousesUpdateModal(id);
  }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <BsFillHouseGearFill className='button-icon' />,
      onclick: ((rowData) => { handleShowWarehousesUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <BsFillHouseDashFill className='button-icon' />,
      onclick: ((rowData) => { handleShowWarehousesDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={warehouses}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  // Local Functions
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowWarehousesAddModal(); }}><BsFillHouseAddFill style={{ fontSize: '1.5em' }} /> Yeni Depo Ekle</button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: warehouses, cols: cols, pdftitle: "Depolar" })) }}>
          <TbPrinter style={{ fontSize: '1.5em' }} />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Warehouse Add Modal
  const [showWarehousesAddModal, setShowWarehousesAddModal] = useState(false);
  const handleCloseWarehousesAddModal = () => { setShowWarehousesAddModal(false) };
  const handleShowWarehousesAddModal = () => { setShowWarehousesAddModal(true) };
  const handleAddWarehousesAddModal = (values) => {
    dispatch(addNewWarehouse({ newwarehouse: { ...values, status: statusTranslator[values.status] } }));
    handleCloseWarehousesAddModal();
  };
  // Warehouse Update Modal
  const [showWarehousesUpdateModal, setShowWarehousesUpdateModal] = useState(false);
  const [inputDataForWarehousesUpdateModal, setInputDataForWarehousesUpdateModal] = useState({});
  const handleCloseWarehousesUpdateModal = () => {
    setShowWarehousesUpdateModal(false)
    setInputDataForWarehousesUpdateModal({});
  };
  const handleShowWarehousesUpdateModal = (id) => {
    dispatch(getWarehouseById({ id: id }));
    setDataRequester('update');
  };
  const handleUpdateWarehousesUpdateModal = (values) => {
    dispatch(updateWarehouse({ warehouse: { ...values, status: statusTranslator[values.status] } }));
    handleCloseWarehousesUpdateModal();
  };
  // Warehouse Delete Modal
  const [showWarehousesDeleteModal, setShowWarehousesDeleteModal] = useState(false);
  const [inputDataForWarehousesDeleteModal, setInputDataForWarehousesDeleteModal] = useState(-1);
  const handleCloseWarehousesDeleteModal = () => {
    setShowWarehousesDeleteModal(false)
    setInputDataForWarehousesDeleteModal(-1);
  };
  const handleShowWarehousesDeleteModal = (id) => {
    dispatch(getWarehouseById({ id: id }));
    setDataRequester('delete');
  };
  const handleDeleteWarehousesDeleteModal = () => {
    if (inputDataForWarehousesDeleteModal !== -1) { dispatch(deleteWarehouse({ id: inputDataForWarehousesDeleteModal.id })); }
    handleCloseWarehousesDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <WarehousesAddModal
          show={showWarehousesAddModal}
          handleClose={handleCloseWarehousesAddModal}
          handleAdd={handleAddWarehousesAddModal}
        />
        <WarehousesUpdateModal
          show={showWarehousesUpdateModal}
          handleClose={handleCloseWarehousesUpdateModal}
          handleUpdate={handleUpdateWarehousesUpdateModal}
          inputData={inputDataForWarehousesUpdateModal}
        />
        <DeleteModal
          show={showWarehousesDeleteModal}
          handleClose={handleCloseWarehousesDeleteModal}
          handleDelete={handleDeleteWarehousesDeleteModal}
          title="Depoyu Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForWarehousesDeleteModal.name} adlı depoyu silmek istediğinize emin misiniz?`}
          deleteIcon={<BsFillHouseDashFill className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage title='Depolar' Operations={operations} Table={() => tableContent} Modals={modals} />
  );
}

export default Warehouses;