// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Context
import { useValidations } from '../../../../contexts/ValidationContext';
// Icons
import { FaPlugCircleCheck } from "react-icons/fa6";
import { TbArrowBackUpDouble } from "react-icons/tb";

function DeviceUpdateModal({ show, handleClose, handleUpdate, inputData }) {
  // Context Import
  const { validationSchema_Device } = useValidations();
  // Formik Variables
  const initialValues = {
    id: inputData.id,
    name: inputData.name,
    description: inputData.description,
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header>
          <Modal.Title>Cihaz Bilgilerini Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik validationSchema={validationSchema_Device} initialValues={initialValues} onSubmit={(values) => handleUpdate(values)} >
            {({ errors, touched }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='DeviceAddModal_name'
                      name='name'
                      label='Cihaz Adı *'
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='DeviceAddModal_description'
                      name='description'
                      label="Açıklama"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, } }}
                      multiline
                      rows={4}
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={touched.description && !!errors.description}
                      helperText={touched.description && errors.description}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-primary w-100' ><FaPlugCircleCheck className='button-icon' />Cihaz Güncelle</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default DeviceUpdateModal