// General
import React, { useState } from 'react';
// Styles
import { Autocomplete, TextField } from '@mui/material';
import { Field } from 'formik';

function CheckoutType({ errors, touched, values, setFieldValue, setFieldTouched, type }) {
  // Temp Data
  const suppliers = ["Dışarıdan", "Tedarikçi Ali", "Tedarikçi Veli"];
  const employees = [].map(item => item.name);
  const [serviceFound, setServiceFound] = useState(false);
  // HTML
  return (
    <>
      {type === 'Depo Stok' ? (
        <>
          <div className='col-12 col-md-12'>
            <Field
              as={TextField}
              id='description'
              name='description'
              label="Açıklama"
              value={values.description}
              onChange={(e) => setFieldValue('description', e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              error={touched.description && !!errors.description}
              helperText={touched.description && errors.description}
            />
          </div>
          <div className='col-12 col-md-12'>
            <Field
              as={Autocomplete}
              id="supplier"
              name="supplier"
              value={values.supplier}
              onChange={(event, newValue) => { setFieldValue('supplier', newValue); }}
              onBlur={() => { setFieldTouched('supplier', true); }}
              variant='outlined'
              size='small'
              options={suppliers}
              renderInput={(params) => <TextField {...params} label="Tedarikçi" error={touched.supplier && !!errors.supplier} helperText={touched.supplier && errors.supplier} />}
            />
          </div>
        </>
      ) : type === 'Servis Para' || type === 'Servis Parça' || type === 'Müşteri İade' ? (
        <>
          <div className='col-12 col-md-12'>
            <Field
              as={TextField}
              id='description'
              name='description'
              label="Açıklama"
              value={values.description}
              onChange={(e) => setFieldValue('description', e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              error={touched.description && !!errors.description}
              helperText={touched.description && errors.description}
            />
          </div>
          <div className='col-12 col-md-12'>
            <Field
              as={Autocomplete}
              id="employee"
              name="employee"
              value={values.employee}
              onChange={(event, newValue) => { setFieldValue('employee', newValue); }}
              onBlur={() => { setFieldTouched('employee', true); }}
              variant='outlined'
              size='small'
              options={employees}
              renderInput={(params) => <TextField {...params} label="Personel" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
            />
          </div>
          <div className='col-12 col-md-12'>
            <Field
              as={Autocomplete}
              id="serviceId"
              value={null}
              onChange={(event, newValue) => {
                setFieldValue('serviceId', newValue ? newValue.id : '');
                setServiceFound(newValue ? true : false);
              }}
              onBlur={() => { setFieldTouched('serviceId', true); }}
              variant='outlined'
              size='small'
              options={[]}
              getOptionLabel={(option) => option.id.toString()}
              renderOption={(props, option) => {
                const { key, ...otherProps } = props;
                return (
                  <li key={option.id} {...otherProps}>
                    {`${option.id}`}
                  </li>
                )
              }}
              isOptionEqualToValue={(option, value) => option.id === value}
              renderInput={(params) => (<TextField {...params} label="Servis Numarası" error={touched.serviceId && !!errors.serviceId} helperText={touched.serviceId && errors.serviceId} />)}
            />
          </div>
          <div className='col-12 col-md-12'>
            <Field
              as={TextField}
              id="brand"
              name="brand"
              label="Marka"
              value={values.brand}
              slotProps={{ input: { readOnly: true } }}
              variant='outlined'
              size='small'
              fullWidth
              error={touched.brand && !!errors.brand}
              helperText={touched.brand && errors.brand}
            />
          </div>
          <div className='col-12 col-md-12'>
            <Field
              as={TextField}
              id="device"
              name="device"
              label="Cihaz"
              value={values.device}
              slotProps={{ input: { readOnly: true } }}
              variant='outlined'
              size='small'
              fullWidth
              error={touched.device && !!errors.device}
              helperText={touched.device && errors.device}
            />
          </div>
        </>
      ) : type === 'Maaş' || type === 'Prim' ? (
        <>
          <div className='col-12 col-md-12'>
            <Field
              as={TextField}
              id='description'
              name='description'
              label="Açıklama"
              value={values.description}
              onChange={(e) => setFieldValue('description', e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              error={touched.description && !!errors.description}
              helperText={touched.description && errors.description}
            />
          </div>
          <div className='col-12 col-md-12'>
            <Field
              as={Autocomplete}
              id="employee"
              name="employee"
              value={values.employee}
              onChange={(event, newValue) => { setFieldValue('employee', newValue); }}
              onBlur={() => { setFieldTouched('employee', true); }}
              variant='outlined'
              size='small'
              options={employees}
              renderInput={(params) => <TextField {...params} label="Personel" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
            />
          </div>
        </>
      ) : (type === 'Ofis' || type === 'Kira' || type === 'Google Reklam' || type === 'Araç Bakım' || type === 'Araç Yakıt' || type === 'Mutfak / Yemek' || type === 'Diğer / Muhtelif') && (
        <>
          <div className='col-12 col-md-12'>
            <Field
              as={TextField}
              id='description'
              name='description'
              label="Açıklama"
              value={values.description}
              onChange={(e) => setFieldValue('description', e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              error={touched.description && !!errors.description}
              helperText={touched.description && errors.description}
            />
          </div>
        </>
      )}
    </>
  );
}

export default CheckoutType;