// General
import React, { useState, useEffect } from 'react';
// Styles
import Chart from 'react-apexcharts';

const BarChart = ({ data_x, data_y }) => {
  const [chartOptions, setChartOptions] = useState({
    chart: { type: "bar", height: 200, parentHeightOffset: 0, toolbar: { show: false } },
    colors: ["#537aef"],
    series: [{ name: "Sales", data: [] }],
    fill: { opacity: 1 },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        borderRadius: 4,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        dataLabels: { position: "top", orientation: "vertical" }
      }
    },
    grid: { strokeDashArray: 4, padding: { top: -20, right: 0, bottom: -4 }, xaxis: { lines: { show: true } } },
    xaxis: { type: "datetime", categories: [], axisTicks: { color: "#f0f4f7" } },
    yaxis: { title: { text: "Number of Sales", style: { fontSize: "12px", fontWeight: 600 } } },
    tooltip: { theme: "light" },
    legend: { position: "top", show: true, horizontalAlign: "center" },
    stroke: { width: 0 },
    dataLabels: { enabled: false },
    theme: { mode: "light" }
  });
  useEffect(() => {
    if (data_x?.length > 0 && data_y?.length > 0) {
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        series: [{ name: "Sales", data: data_x }],
        xaxis: { ...prevOptions.xaxis, categories: data_y }
      }));
    }
  }, [data_x, data_y]);

  return (
    <Chart options={chartOptions} series={chartOptions.series} type="bar" height="100%" width="100%" />
  );
};

export default BarChart;