// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Warehouses/';
const controller = new AbortController();
const signal = controller.signal;

// Async Thunks
export const getAllWarehouses = createAsyncThunk('backend_getallwarehouses', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllWarehouses`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getWarehouseById = createAsyncThunk('backend_getwarehousebyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetwarehouseById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewWarehouse = createAsyncThunk('backend_addnewwarehouse', async ({ newwarehouse }) => {
  try {
    const res = await axios.post(`${baseurl}AddWarehouse`, newwarehouse, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateWarehouse = createAsyncThunk('backend_updatewarehouse', async ({ warehouse }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateWarehouse`, warehouse, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteWarehouse = createAsyncThunk('backend_deletewarehouse', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteWarehouse?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const warehouseSlice = createSlice({
  name: 'warehouses',
  initialState: {
    warehouses: [],
    warehouse: {},
    getAllStatus: null,
    getByIdStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetgetByIdStatus: (state) => { state.getByIdStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWarehouses.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllWarehouses.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.warehouses = action.payload || []; })
      .addCase(getAllWarehouses.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getWarehouseById.pending, (state) => { state.getByIdStatus = "pending"; })
      .addCase(getWarehouseById.fulfilled, (state, action) => { state.getByIdStatus = "fulfilled"; state.warehouse = action.payload || {}; })
      .addCase(getWarehouseById.rejected, (state, action) => { state.getByIdStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewWarehouse.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewWarehouse.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewWarehouse.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateWarehouse.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateWarehouse.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateWarehouse.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteWarehouse.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteWarehouse.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteWarehouse.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
})

export const { resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetgetByIdStatus, resetError } = warehouseSlice.actions;
export default warehouseSlice.reducer;

