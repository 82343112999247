// General
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
// Styles
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { setDtFilterValue, setFilterPageIndex } from '../../../storages/slices/PrintSlice';
// Contexts
import { useData } from '../../../contexts/DataContext';
// Icons
import { TbDatabaseShare } from "react-icons/tb";

function Table({ data, rows, columns, filternames = null, select = null, setDates = null, dates = null, singledate = false, search = null, buttons = null, check = null, colors = null, headerAlign = 'left', paginatorColor = 'bg-white', bodyAlignBroker = [], searchfullwidth = false }) {
  // Storage Import
  const dispatch = useDispatch();
  const reduxFilterPageIndex = useSelector((state) => state.print.filterpageindex);
  // Context Import
  const { today, lastWeekDate } = useData();
  // Mobile Data
  const isMobile = useMediaQuery({ query: "(max-width: 575px)" });
  const [expandedRows, setExpandedRows] = useState(null);
  const buttonColumn = (rowData) => {
    return (
      <div className={`d-flex justify-content-${isMobile ? 'start mt-1' : 'center'}`}>
        {buttons.map((button, index) => {
          return (
            <button key={index} className={`${button.icon ? 'button-with-icon ' : ''}btn ${isMobile ? 'btn-sm' : ''} btn-${button.variant} ${isMobile ? 'me-1' : 'mx-1'}`} onClick={(e) => { e.stopPropagation(); button.onclick(rowData); }}>
              {button.icon ? button.icon : ''}
              {button.text}
            </button>
          )
        })}
      </div >
    )
  }
  const checkColumn = (rowData) => {
    return (
      <div className={`d-flex justify-content-${isMobile ? 'start' : 'center'}`}>
        <Checkbox checked={rowData.selected} onChange={() => check.onchange(rowData)} size={`${isMobile ? 'small' : ''}`} />
      </div>
    )
  }
  const rowExpansionTemplate = (rowData) => {
    return (
      <div className="pb-0 ps-2 text-start">
        <ul className='list-unstyled mb-1'>
          {check && checkColumn(rowData)}
          {columns.length >= 3 && (
            columns.slice(2, columns.length).map((column, index) => {
              return (
                <li key={index}><span className='text-capitalize fs-6 fw-bold'>{column.name.toLowerCase()}: </span> <span className='fs-6 text-break '>{rowData[column.value]}</span></li>
              )
            })
          )}
          {buttons && buttonColumn(rowData)}
        </ul>
      </div>
    );
  };
  // Filtering
  const [startDate, setStartDate] = useState(setDates ? setDates.start : lastWeekDate);
  const [endDate, setEndDate] = useState(setDates ? setDates.end : today);
  const [filters, setFilters] = useState({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } });
  const [filterValue, setFilterValue] = useState('');
  const [value, setValue] = useState(search ? search : '');
  const onFilterChange = (e, searchValue = null) => {
    if (searchValue) {
      setValue(searchValue);
    } else {
      setValue(e.target.value);
    }
    setFilterValue(value);
    setFilters({
      ...filters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    });
    dispatch(setDtFilterValue(value))
  };
  const onPageChange = (e) => {
    dispatch(setFilterPageIndex(e.first / e.rows))
  }
  useEffect(() => {
    if (search) {
      onFilterChange(null, search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  // HTML
  return (
    <>
      <div className='row g-2 mb-0 mt-0'>
        {filternames && (
          <div className={`${searchfullwidth && !dates ? 'col-12 ' : 'col-12 col-sm-12 col-lg-12 col-xl-6'}`}>
            <div className='row'>
              <div className={`${searchfullwidth && !dates ? 'col-12' : 'col-12 col-sm-12 col-lg-12  col-xl-8'}`}>
                <TextField
                  label="Ara"
                  value={filterValue}
                  onChange={onFilterChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </div>
            </div>
          </div>
        )}
        {dates && (
          <div className='col-12 col-sm-12 col-lg-12 col-xl-6'>
            <div className='row g-2'>
              <div className={`col-12 col-md-${singledate ? '8' : '4'}`}>
                <TextField
                  label={`${singledate ? 'Tarih' : 'Başlangıç Tarihi'}`}
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    if (singledate) { setEndDate(e.target.value); }
                  }}
                  slotProps={{ input: { shrink: "true" } }}
                  variant='outlined'
                  size='small'
                  fullWidth
                />
              </div>
              {!singledate && (
                <div className='col-12 col-md-4'>
                  <TextField
                    label='Bitiş Tarihi'
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    variant='outlined'
                    size='small'
                    fullWidth
                  />
                </div>
              )}
              <div className='col-12  col-md-4'>
                <button type='button' className='button-with-icon btn btn-primary w-100 h-100' onClick={() => { dates(startDate, endDate); }}>
                  <TbDatabaseShare className='button-icon' />
                  Veri Getir
                </button>
              </div>
            </div>
          </div>
        )}
        {isMobile ? (
          <DataTable
            value={data}
            size='small'
            className={`${select ? 'activehover' : 'unactivehover'} mobileMode`}
            stripedRows
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            emptyMessage="Bu tabloda henüz veri yok!"
            rowClassName={colors ? colors : ``}
            showGridlines
            paginatorLeft
            paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
            paginator
            onPage={onPageChange}
            first={reduxFilterPageIndex * rows}
            paginatorClassName={paginatorColor}
            rows={rows}
            filters={filters}
            onSelectionChange={(e) => select && select((e.value.id))}
            selectionMode="single"
            globalFilterFields={filternames ? filternames : []}
          >
            {((columns.length >= 3) || (columns.length >= 2 && buttons) || (columns.length >= 2 && check)) && <Column align='center' expander style={{ width: "0.5em" }} />}
            <Column align={headerAlign} sortable field={columns[0]?.value} header={columns[0]?.name} headerClassName='fs-6' bodyClassName={`align-middle fs-6 ${bodyAlignBroker.includes(columns[0]?.value) ? 'text-left' : 'text-center'}`} />
            <Column align={headerAlign} sortable field={columns[1]?.value} header={columns[1]?.name} headerClassName='fs-6' bodyClassName={`align-middle fs-6 ${bodyAlignBroker.includes(columns[1]?.value) ? 'text-left' : 'text-center'}`} />
          </DataTable>
        ) : (
          <DataTable
            value={data}
            size='small'
            className={`${select ? 'activehover' : 'unactivehover'}`}
            stripedRows
            emptyMessage="Bu tabloda henüz veri yok!"
            rowClassName={colors ? colors : ``}
            showGridlines
            paginatorLeft
            paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
            paginator
            onPage={onPageChange}
            first={reduxFilterPageIndex * rows}
            paginatorClassName={paginatorColor}
            rows={rows}
            filters={filters}
            onSelectionChange={(e) => select && select((e.value.id))}
            selectionMode="single"
            globalFilterFields={filternames ? filternames : []}
          >
            {check && <Column align={headerAlign} body={checkColumn} bodyClassName="align-middle" />}
            {columns.map((column, index) => {
              return (
                <Column key={index} align={headerAlign} sortable field={column.value} header={column.name} bodyClassName={`align-middle fs-6 ${bodyAlignBroker.includes(column.value) ? 'text-left' : 'text-center'}`} />
              );
            })}
            {buttons && <Column align={headerAlign} body={buttonColumn} bodyClassName="align-middle" />}
          </DataTable>
        )}
      </div>
    </>
  );
}

export default Table;