// General
import React, { useEffect, useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Components
import CustomerType from '../../components/CustomerType';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { getDistricts } from '../../../../storages/slices/DistrictSlice';
import { getCustomerByPhone } from '../../../../storages/slices/backend/CustomerSlice';
import { getUserInfo } from '../../../../storages/slices/LoginSlice';
// Context
import { useData } from '../../../../contexts/DataContext';
import { useValidations } from '../../../../contexts/ValidationContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
// Icons
import { TbFolderPlus, TbUserEdit } from "react-icons/tb";
import { TbArrowBackUpDouble } from 'react-icons/tb';

function ServiceAddModal({ show, handleClose, handleAdd }) {
  // Storage Import
  const dispatch = useDispatch();
  const { districts } = useSelector((state) => state.district);
  const { brands } = useSelector((state) => state.brand);
  const { devices } = useSelector((state) => state.device);
  const { sources } = useSelector((state) => state.source);
  // Context Import
  const { today, cities, warranties } = useData();
  const { validationSchema_ServiceAdd } = useValidations();
  const { stringMaxLenght, stringOnlyNumbers } = useFunctions();
  // Variables
  const [isCustomerFound, setIsCustomerFound] = useState(false);
  const [prevCustomer, setPrevCustomer] = useState({ name: '', customerType: '', identityNumber: '', taxNumber: '', taxOffice: '', phone2: '', city: '', district: '', address: '' });
  const [city, setCity] = useState('');
  useEffect(() => {
    dispatch(getDistricts({ apikey: "tuyak_furkanerhan", city: city }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city]);
  // Functions
  const handleSetCustomer = async (phone, setFieldValue) => {
    const response = await dispatch(getCustomerByPhone({ phone }));
    const customerData = response.payload;
    if (customerData) {
      setIsCustomerFound(true);
      setFieldValue('name', customerData.name);
      setFieldValue('customerType', customerData.customerType);
      setFieldValue('identityNumber', customerData.identityNumber);
      setFieldValue('taxNumber', customerData.taxNumber);
      setFieldValue('taxOffice', customerData.taxOffice);
      setFieldValue('phone2', customerData.phone2);
      setFieldValue('city', customerData.city);
      setFieldValue('district', customerData.district);
      setFieldValue('address', customerData.address);
    } else {
      setIsCustomerFound(false);
    }
  }
  const handleAddFunction = async (values) => {
    const res = await dispatch(getUserInfo());
    if (res) {
      const myUser = res?.payload;
      handleAdd({ ...values, userId: myUser.id });
    }
  }
  // Filtering
  const todayDate = new Date().toLocaleDateString();
  const todayTime = new Date().toLocaleTimeString();
  // Formik Variables
  const initalValues = {
    name: '',
    customerType: 'Bireysel',
    identityNumber: '',
    taxNumber: '',
    taxOffice: '',
    phone1: '',
    phone2: '',
    city: '',
    district: '',
    address: '',
    customerDate: today,
    customerTimeStart: '09:00',
    customerTimeEnd: '20:00',
    brandName: '',
    deviceName: '',
    model: '',
    problem: '',
    note: '',
    warranty: 0,
    sourceName: '',
    isEmergency: false
  }
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered className='modal-xl'>
      <Modal.Body>
        <Formik initialValues={initalValues} validationSchema={validationSchema_ServiceAdd} onSubmit={(values) => handleAddFunction(values)} >
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form>
              <div className='row g-2 d-flex flex-row justify-content-end'>
                <div className='col-12 col-md-12'>
                  <div className='card bg-light m-0 p-1'>
                    <div className='row'>
                      <div className='col-12 col-md-6'>
                        <div className='col-12 col-sm-10 col-lg-7 col-xl-5'>
                          <Field
                            as={TextField}
                            id="ServiceAddModal_todaysdate"
                            name="todaysdate"
                            label="Bugünün Tarihi"
                            value={(todayDate + ' ' + todayTime)}
                            slotProps={{ input: { readOnly: true } }}
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        </div>
                      </div>
                      <div className='col-12 col-md-6'>
                        <div className='row g-2 me-lg-4 me-xl-0 d-flex justify-content-end  justify-content-sm-start justify-content-lg-end  justify-content-xl-end'>
                          <div className='col-12 col-md-6 mt-3 mt-sm-2 mt-lg-2 mt-xl-2 '>
                            <Field
                              as={Autocomplete}
                              id="ServiceAddModal_sourceName"
                              name="sourceName"
                              value={values.sourceName}
                              onChange={(event, newValue) => { setFieldValue('sourceName', newValue); }}
                              onBlur={() => { setFieldTouched('sourceName', true); }}
                              variant='outlined'
                              size='small'
                              options={sources.map(item => item.name)}
                              renderInput={(params) => <TextField {...params} label="Kaynak" error={touched.sourceName && !!errors.sourceName} helperText={touched.sourceName && errors.sourceName} />}
                            />
                          </div>
                          <div className='col-12 col-md-2 mt-0 mt-sm-2 mt-lg-2 mt-xl-2'>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  checked={values.isEmergency}
                                  onChange={(e) => setFieldValue('isEmergency', e.target.checked)}
                                />
                              }
                              label="ACİL!"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 d-flex align-items-stretch'>
                  <div className='card bg-light m-0 flex-fill'>
                    <div className='card-header d-flex justify-content-between py-1'>
                      <div className='card-title my-1'>Müşteri Bilgileri</div>
                      <div>
                        {isCustomerFound && (
                          <button type='button' className='btn btn-outline-warning mx-1 p-1'><TbUserEdit style={{ fontSize: '1.5em' }} /></button>
                        )}
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='row g-2'>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id="ServiceAddModal_phone1"
                            name="phone1"
                            label="Birinci Telefon"
                            value={values.phone1}
                            onChange={(e) => {
                              const phone = stringMaxLenght(stringOnlyNumbers(e.target.value), 10);
                              setFieldValue("phone1", phone);
                              if (phone.length === 10) {
                                handleSetCustomer(phone, setFieldValue);
                              } else if (phone.length === 9 && values.phone1.length === 10) {
                                setFieldValue('name', prevCustomer.name);
                                setFieldValue('customerType', prevCustomer.customerType);
                                setFieldValue('identityNumber', prevCustomer.identityNumber);
                                setFieldValue('taxNumber', prevCustomer.taxNumber);
                                setFieldValue('taxOffice', prevCustomer.taxOffice);
                                setFieldValue('phone2', prevCustomer.phone2);
                                setFieldValue('city', prevCustomer.city);
                                setFieldValue('district', prevCustomer.district);
                                setFieldValue('address', prevCustomer.address);
                              } else if (phone.length === 9) {
                                setPrevCustomer({ name: values.name, customerType: values.customerType, identityNumber: values.identityNumber, taxNumber: values.taxNumber, taxOffice: values.taxOffice, phone2: values.phone2, city: values.city, district: values.district, address: values.address });
                              }
                              setIsCustomerFound(false);
                            }}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={touched.phone1 && !!errors.phone1}
                            helperText={touched.phone1 && errors.phone1}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id="ServiceAddModal_phone2"
                            name="phone2"
                            label="İkinci Telefon"
                            value={values.phone2}
                            onChange={(e) => setFieldValue('phone2', e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                            slotProps={{ input: { readOnly: isCustomerFound } }}
                            error={touched.phone2 && !!errors.phone2}
                            helperText={touched.phone2 && errors.phone2}
                          />
                        </div>
                        <div className='col-12 col-sm-12 col-lg-12 col-xl-12'>
                          <Field
                            as={TextField}
                            id="ServiceAddModal_name"
                            name="name"
                            label="Müşteri Adı"
                            value={values.name}
                            onChange={(e) => setFieldValue('name', e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                            slotProps={{ input: { readOnly: isCustomerFound } }}
                            error={touched.name && !!errors.name}
                            helperText={touched.name && errors.name}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={Autocomplete}
                            id="ServiceAddModal_customerType"
                            name="customerType"
                            value={values.customerType}
                            onChange={(event, newValue) => { setFieldValue('customerType', newValue); }}
                            onBlur={() => { setFieldTouched('customerType', true); }}
                            variant='outlined'
                            size='small'
                            readOnly={isCustomerFound}
                            options={['Bireysel', 'Kurumsal']}
                            renderInput={(params) => <TextField {...params} label="Müşteri Türü" error={touched.customerType && !!errors.customerType} helperText={touched.customerType && errors.customerType} />}
                          />
                        </div>
                        <CustomerType errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} readOnly={isCustomerFound} />
                        <div className='col-12 col-sm-12 col-lg-6 col-xl-6 '>
                          <Field
                            as={Autocomplete}
                            id="ServiceAddModal_city"
                            name="city"
                            value={values.city}
                            onChange={(event, newValue) => { setCity(newValue); setFieldValue('city', newValue); setFieldValue('district', ''); }}
                            onBlur={() => { setFieldTouched('city', true); }}
                            variant='outlined'
                            size='small'
                            readOnly={isCustomerFound}
                            options={cities}
                            renderInput={(params) => <TextField {...params} label="İl" error={touched.city && !!errors.city} helperText={touched.city && errors.city} />}
                          />
                        </div>
                        <div className='col-12 col-sm-12 col-lg-6 col-xl-6'>
                          <Field
                            as={Autocomplete}
                            id="ServiceAddModal_district"
                            name="district"
                            value={values.district}
                            onChange={(event, newValue) => { setFieldValue('district', newValue); }}
                            onBlur={() => { setFieldTouched('district', true); }}
                            variant='outlined'
                            size='small'
                            readOnly={isCustomerFound}
                            disabled={values.city === ''}
                            options={districts}
                            renderInput={(params) => <TextField {...params} label="İlçe" error={touched.district && !!errors.district} helperText={touched.district && errors.district} />}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id="ServiceAddModal_address"
                            name="address"
                            label="Adres"
                            value={values.address}
                            onChange={(e) => setFieldValue('address', e.target.value)}
                            multiline
                            minRows={3}
                            variant="outlined"
                            size="small"
                            slotProps={{ input: { readOnly: isCustomerFound } }}
                            fullWidth
                            error={touched.address && !!errors.address}
                            helperText={touched.address && errors.address}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 d-flex align-items-stretch'>
                  <div className='card bg-light m-0 flex-fill'>
                    <div className='card-header py-1'>
                      <div className='card-title my-1'>Cihaz Bilgileri</div>
                    </div>
                    <div className='card-body'>
                      <div className='row g-2'>
                        <div className='col-12 col-sm-12 col-lg-6 col-xl-6'>
                          <Field
                            as={Autocomplete}
                            id="ServiceAddModal_brandName"
                            name="brandName"
                            value={values.brandName}
                            onChange={(event, newValue) => { setFieldValue('brandName', newValue); }}
                            onBlur={() => { setFieldTouched('brandName', true); }}
                            variant='outlined'
                            size='small'
                            options={brands.map(item => item.name)}
                            renderInput={(params) => <TextField {...params} label="Cihaz Markası" error={touched.brandName && !!errors.brandName} helperText={touched.brandName && errors.brandName} />}
                          />
                        </div>
                        <div className='col-12 col-sm-12 col-lg-6 col-xl-6'>
                          <Field
                            as={Autocomplete}
                            id="ServiceAddModal_deviceName"
                            name="deviceName"
                            value={values.deviceName}
                            onChange={(event, newValue) => { setFieldValue('deviceName', newValue); }}
                            onBlur={() => { setFieldTouched('deviceName', true); }}
                            variant='outlined'
                            size='small'
                            options={devices.map(item => item.name)}
                            renderInput={(params) => <TextField {...params} label="Cihaz Türü" error={touched.deviceName && !!errors.deviceName} helperText={touched.deviceName && errors.deviceName} />}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id='ServiceAddModal_model'
                            name='model'
                            label="Cihaz Modeli"
                            value={values.model}
                            onChange={(e) => setFieldValue('model', e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={touched.model && !!errors.model}
                            helperText={touched.model && errors.model}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id='ServiceAddModal_problem'
                            name='problem'
                            label="Cihaz Arızası"
                            value={values.problem}
                            onChange={(e) => setFieldValue('problem', e.target.value)}
                            slotProps={{ input: { resize: "none" } }}
                            multiline
                            minRows={2}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={touched.problem && !!errors.problem}
                            helperText={touched.problem && errors.problem}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id='ServiceAddModal_note'
                            name='note'
                            label="Operator Notu"
                            value={values.note}
                            onChange={(e) => setFieldValue('note', e.target.value)}
                            slotProps={{ input: { resize: "none" } }}
                            multiline
                            minRows={2}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={touched.note && !!errors.note}
                            helperText={touched.note && errors.note}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={Autocomplete}
                            id="ServiceAddModal_warranty"
                            name="warranty"
                            value={values.warranty}
                            onChange={(event, newValue) => { setFieldValue('warranty', newValue); }}
                            onBlur={() => { setFieldTouched('warranty', true); }}
                            variant='outlined'
                            size='small'
                            options={warranties}
                            getOptionLabel={(option) => option === 0 ? 'Yok' : `${option.toString()} Ay`}
                            renderOption={(props, option) => {
                              const { key, ...otherProps } = props;
                              return (
                                <li key={option} {...otherProps}>
                                  {option === 0 ? 'Yok' : `${option.toString()} Ay`}
                                </li>
                              );
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => <TextField {...params} label="Cihaz Garantisi" error={touched.warranty && !!errors.warranty} helperText={touched.warranty && errors.warranty} />}
                          />
                        </div>
                        <div className='col-12 col-sm-12 col-lg-12 col-xl-6'>
                          <Field
                            as={TextField}
                            id='ServiceAddModal_customerDate'
                            name='customerDate'
                            label='Müsait Zaman'
                            type="date"
                            variant='outlined'
                            size='small'
                            value={values.customerDate}
                            onChange={(e) => setFieldValue('customerDate', e.target.value)}
                            slotProps={{ input: { shrink: "true" } }}
                            fullWidth
                            error={touched.customerDate && !!errors.customerDate}
                            helperText={touched.customerDate && errors.customerDate}
                          />
                        </div>
                        <div className='col-6 col-sm-12 col-lg-6  col-xl-3'>
                          <Field
                            as={TextField}
                            id='ServiceAddModal_customerTimeStart'
                            name='customerTimeStart'
                            label='Başlangıç'
                            type="time"
                            variant='outlined'
                            size='small'
                            value={values.customerTimeStart}
                            onChange={(e) => setFieldValue('customerTimeStart', e.target.value)}
                            slotProps={{ input: { shrink: "true" } }}
                            fullWidth
                            error={touched.customerTimeStart && !!errors.customerTimeStart}
                            helperText={touched.customerTimeStart && errors.customerTimeStart}
                          />
                        </div>
                        <div className='col-6 col-sm-12 col-lg-6  col-xl-3'>
                          <Field
                            as={TextField}
                            id='ServiceAddModal_customerTimeEnd'
                            name='customerTimeEnd'
                            label='Bitiş'
                            type="time"
                            variant='outlined'
                            size='small'
                            value={values.customerTimeEnd}
                            onChange={(e) => setFieldValue('customerTimeEnd', e.target.value)}
                            slotProps={{ input: { shrink: "true" } }}
                            fullWidth
                            error={touched.customerTimeEnd && !!errors.customerTimeEnd}
                            helperText={touched.customerTimeEnd && errors.customerTimeEnd}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-12'>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-sm-6 col-lg-3 col-xl-3'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                        <TbArrowBackUpDouble className='button-icon' />
                        Kaydetmeden Çık
                      </button>
                    </div>
                    <div className='col-12 col-sm-6 col-lg-3 col-xl-3'>
                      <button type='submit' className='button-with-icon btn btn-success w-100'>
                        <TbFolderPlus className='button-icon' />
                        Servisi Ekle
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default ServiceAddModal;