// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Field, Form, Formik } from 'formik';
import { TextField } from '@mui/material';
// Context
import { useValidations } from '../../../../contexts/ValidationContext';
// Icons
import { TbArrowBackUpDouble } from 'react-icons/tb';
import { IoMdAddCircleOutline } from "react-icons/io";

function NoteAddModal({ show, handleClose, handleAdd }) {
  // Context Import
  const { validationSchema_ServiceNote } = useValidations();
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Not Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={{ note: '' }} validationSchema={validationSchema_ServiceNote} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='note'
                    name='note'
                    label="Not"
                    value={values.note}
                    onChange={(e) => setFieldValue('note', e.target.value)}
                    variant="outlined"
                    size="small"
                    slotProps={{ input: { resize: "none" } }}
                    multiline
                    minRows={3}
                    fullWidth
                    error={touched.note && !!errors.note}
                    helperText={touched.note && errors.note}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <button type='button' className='button-with-icon btn btn-secondary w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Kaydetmeden Çık</button>
                </div>
                <div className='col-12 col-md-6'>
                  <button type='submit' className='button-with-icon btn btn-success w-100'><IoMdAddCircleOutline className='button-icon' />Notu Ekle</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default NoteAddModal;