// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

// Async Thunk
export const getExchange = createAsyncThunk('getgeneralapi_exchange', async ({ signal = null }) => {
  try {
    const res = await axios.get(`https://hasanadiguzel.com.tr/api/kurgetir`, { signal });
    return res.data.TCMB_AnlikKurBilgileri.filter(item => ["US DOLLAR", "EURO"].includes(item.CurrencyName)).map(item => ({ currency: item.CurrencyName, buying: item.BanknoteBuying, selling: item.BanknoteSelling }));
  } catch (error) {
    throw error?.response?.data || error;
  }
});

// Slice
const exchangeSlice = createSlice({
  name: 'exchange',
  initialState: {
    exchanges: [],
    status: '',
    error: ''
  },
  reducers: {
    resetStatus: (state) => { state.status = null; },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExchange.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getExchange.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.exchanges = action.payload || [];
      })
      .addCase(getExchange.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
})

export const { resetStatus } = exchangeSlice.actions;
export default exchangeSlice.reducer;