// General
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
// Styles
import { Table } from 'react-bootstrap';
// Context
import { useFunctions } from '../../../contexts/FunctionsContext';
// Charts
import BarChart from '../components/BarChart';

function Dashboard() {
  // Context Import
  const { openNewPage } = useFunctions();
  // Chart Data
  const data_x = [145, 96, 108, 140, 130, 150, 170, 138, 114, 128, 138];
  const data_y = ["01/01/2024", "02/01/2024", "03/01/2024", "04/01/2024", "05/01/2024", "06/01/2024", "07/01/2024", "08/01/2024", "09/01/2024", "10/01/2024", "11/01/2024"];
  // Variables
  const colors = ["danger", "danger", "warning", "warning", "primary", "info", "secondary", "success"];
  const data = [
    { date: '25 Kasım 2024 - Bugün', total: 20, data: [{ name: 'A Servis', value: 5 }, { name: 'B Servis', value: 2 }, { name: 'C Servis', value: 9 }, { name: 'D Servis', value: 4 }] },
    { date: '24 Kasım 2024 - Dün', total: 30, data: [{ name: 'A Servis', value: 5 }, { name: 'B Servis', value: 2 }, { name: 'C Servis', value: 9 }, { name: 'D Servis', value: 4 }] },
    { date: '23 Kasım 2024 - Önceki Gün', total: 50, data: [{ name: 'A Servis', value: 5 }, { name: 'B Servis', value: 2 }, { name: 'C Servis', value: 9 }, { name: 'D Servis', value: 4 }] }];
  const [selected, setSelected] = useState(0);
  const numbers = [8, 7, 6, 5, 4, 3, 2, 1];
  // HTML
  return (
    <div>
      <div className="py-3 d-flex align-items-sm-center flex-sm-row flex-column">
        <div className="flex-grow-1">
          <h4 className="fs-18 fw-semibold m-0">Anasayfa</h4>
        </div>
      </div>
      <div className='row g-3'>
        <div className="col-12 col-md-3 d-flex align-items-stretch">
          <NavLink to='/servisler' className="card m-0 flex-fill">
            <div className="card-body">
              <div className="text-center">
                <div className="fs-14 mb-1 ">Toplam Servis</div>
              </div>
              <div className="text-center">
                <div className="fs-22 mb-0 me-2 fw-semibold text-black">5000 Adet</div>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="col-12 col-md-3 d-flex align-items-stretch">
          <NavLink to='/musteriler' className="card m-0 flex-fill">
            <div className="card-body">
              <div className="text-center">
                <div className="fs-14 mb-1 ">Toplam Müşteri</div>
              </div>
              <div className="text-center">
                <div className="fs-22 mb-0 me-2 fw-semibold text-black">132.000 Kişi</div>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="col-12 col-md-3 d-flex align-items-stretch">
          <NavLink to='/personeller' className="card m-0 flex-fill">
            <div className="card-body">
              <div className="text-center">
                <div className="fs-14 mb-1 ">Toplam Çalışan</div>
              </div>
              <div className="text-center">
                <div className="fs-22 mb-0 me-2 fw-semibold text-black">50 Kişi</div>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="col-12 col-md-3 d-flex align-items-stretch">
          <NavLink to='/kasa' className="card m-0 flex-fill">
            <div className="card-body">
              <div className="text-center">
                <div className="fs-14 mb-1 ">Kasa</div>
              </div>
              <div className="text-center">
                <div className="fs-22 mb-0 me-2 fw-semibold text-black">172.000 ₺</div>
              </div>
            </div>
          </NavLink>
        </div>
        <div className='col-12 col-md-12'>
          <div className='card m-0'>
            <div className='card-body'>
              <h6><b>Son 3 gün gidilen servis sayısı</b></h6>
              <Table responsive striped className="w-100 text-center mb-0">
                <thead>
                  <tr>
                    <th>GİDİLEN TARİH</th>
                    <th>GİDİLEN SERVİS SAYISI</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <React.Fragment key={`outer-${item.date}-${index}`}>
                      <tr>
                        <td>
                          <NavLink className="text-dark" onClick={() => setSelected(selected === index + 1 ? 0 : index + 1)}>
                            {item.date}
                          </NavLink>
                        </td>
                        <td>{item.total}</td>
                      </tr>
                      {selected === index + 1 && (
                        <>
                          {item.data.map((iitem, iindex) => (
                            <tr key={`inner-${item.date}-${iitem.name}-${iindex}`}>
                              <td>
                                <NavLink onClick={() => openNewPage('anasayfa')}>
                                  {iitem.name}
                                </NavLink>
                              </td>
                              <td>{'('}{iitem.value}{')'}</td>
                            </tr>
                          ))}
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className='col-12 col-md-12'>
          <div className="card m-0">
            <div className="card-body">
              <h6><b>Aylık Servis Sayısı</b></h6>
              <BarChart data_x={data_x} data_y={data_y} />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex align-items-stretch">
          <div className="card m-0 flex-fill">
            <div className="card-body">
              <h6><b>En Çok Kullanılan Parçalar</b></h6>
              <Table className="table table-traffic mb-0">
                <thead>
                  <tr>
                    <th>Parça Adı</th>
                    <th colSpan="2">Adet</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {numbers.map((num) => {
                    return (
                      <tr key={`parca_${num}`}>
                        <td>Geçici Veri {num}</td>
                        <td>{num}</td>
                        <td className="w-50">
                          <div className="progress progress-md mt-0">
                            <div className={`progress-bar bg-${colors[num - 1]}`} style={{ width: `${((num / 36) * 100).toFixed(2)}%` }}></div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex align-items-stretch">
          <div className="card m-0 flex-fill ">
            <div className="card-body">
              <h6><b>En Çok Ziyaret Edilen İlçeler</b></h6>
              <Table className="table table-traffic mb-0">
                <thead>
                  <tr>
                    <th>İlçe Adı</th>
                    <th colSpan="2">Ziyaret Miktarı</th>
                  </tr>
                </thead>
                <tbody>
                  {numbers.map((num) => {
                    return (
                      <tr key={`ilce_${num}`}>
                        <td>İlçe {num}</td>
                        <td>{num}</td>
                        <td className="w-50">
                          <div className="progress progress-md mt-0">
                            <div className={`progress-bar bg-${colors[num - 1]}`} style={{ width: `${((num / 36) * 100).toFixed(2)}%` }}></div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;