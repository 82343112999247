// General
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
// Styles
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Autocomplete, Button, Checkbox, FormControlLabel, styled, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Storages
import { useSelector } from 'react-redux';
// Contexts
import { useData } from '../../../../contexts/DataContext';
import { useValidations } from '../../../../contexts/ValidationContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
// Icons
import { TbFolderCog, TbFolderX } from "react-icons/tb";
import { TbUserEdit } from "react-icons/tb";
import { TbArrowBackUpDouble } from 'react-icons/tb';
import { IoMdAddCircleOutline, IoMdRemoveCircleOutline } from "react-icons/io";
import { MdCloudUpload, MdDeleteOutline, MdOutlineEdit } from "react-icons/md";
// Modals
import CustomerUpdateModal from '../customers/CustomerUpdateModal';
import CheckoutAddModal from '../checkout/CheckoutAddModal';
import CheckoutUpdateModal from '../checkout/CheckoutUpdateModal';
import NoteAddModal from './NoteAddModal';
import DeleteModal from '../DeleteModal';
// Components
import ServiceOperations from '../../components/ServiceOperations';

const MAX_FILE_SIZE = 5 * 1024 * 1024;

function ServiceDetailsModal({ show, handleClose, handleUpdate, handleAddOperation, inputData }) {
  // Storage Import
  const { brands } = useSelector((state) => state.brand);
  const { devices } = useSelector((state) => state.device);
  const { sources } = useSelector((state) => state.source);
  const { employees } = useSelector((state) => state.employee);
  const { dealers } = useSelector((state) => state.dealer);
  const { vehicles } = useSelector((state) => state.vehicle);
  const { stocks } = useSelector((state) => state.stock);
  const { consigments } = useSelector((state) => state.consigment);
  const { serviceActions, serviceOperations } = useSelector((state) => state.serviceaction);
  // Context Import
  const { today, warranties } = useData();
  const { stringDate } = useFunctions();
  const { validationSchema_ServiceUpdate } = useValidations();
  // Variables
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const serviceDate = stringDate(inputData.date);
  // TABS
  // TAB - Service Informations
  const initalValues = {
    id: inputData.id || -1,
    brandName: inputData.brandName || '',
    deviceName: inputData.deviceName || '',
    sourceName: inputData.sourceName || '',
    model: inputData.model || '',
    problem: inputData.problem || '',
    note: inputData.note || '',
    customerDate: inputData.customerDate || today,
    customerTimeStart: inputData.customerTimeStart || '09:00',
    customerTimeEnd: inputData.customerTimeEnd || '20:00',
    status: inputData.status || '',
    warranty: inputData.warranty || 0,
    isEmergency: inputData.isEmergency || false
  }
  // TAB - Service Operations
  const [operationData, setOperaionData] = useState([]);
  const [serviceOperationsData, setServiceOperationsData] = useState([]);
  useEffect(() => {
    if (serviceOperations) {
      if (serviceOperations.length === 0) {
        setServiceOperationsData([]);
      } else if (serviceOperations.length === 1) {
        if (serviceOperations[0].nextActionName === null) {
          setServiceOperationsData([]);
        } else {
          setServiceOperationsData(serviceOperations.map(item => item.nextActionName));
        }
      } else {
        setServiceOperationsData(serviceOperations.map(item => item.nextActionName));
      }
    }
  }, [serviceOperations]);
  useEffect(() => {
    if (serviceActions) {
      setOperaionData(serviceActions.map(item => ({ ...item, date: stringDate(item.date.slice(0, 10)) })));
    } else { setOperaionData([]); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceActions]);
  const [operation, setOperations] = useState('');
  const handleAddOperationsFunction = async (values) => {
    const addNewServiceAction = { serviceId: inputData.id, actionTypeName: operation, description: JSON.stringify(values).replace(/"/g, "'") }
    handleAddOperation(addNewServiceAction);
  }
  const serviceOperationsButtons = () => {
    return (
      <div className={`d-flex justify-content-center align-items-${isMobile ? 'start' : 'center'}`} style={{ minWidth: `${isMobile ? '120px' : '240px'}` }}>
        <button className={`button-with-icon btn ${isMobile ? 'btn-sm' : ''} btn-danger ${isMobile ? 'me-1' : 'mx-1'}`} >
          <MdDeleteOutline className='button-icon' />
          Sil
        </button>
        <button className={`button-with-icon btn ${isMobile ? 'btn-sm' : ''} btn-primary ${isMobile ? 'me-1' : 'mx-1'}`} >
          <MdOutlineEdit className='button-icon' />
          Düzenle
        </button>
      </div>
    );
  };
  const [serviceoperationsexpanderopened, setServiceoperationsexpanderopened] = useState(null);
  const serviceoperationsexpander = (rowData) => {
    return (
      <div className="pb-0 ps-2 text-start">
        <ul className='list-unstyled mb-1'>
          <li><span className='text-capitalize fs-6 fw-bold'>İşlemi Yapan: </span><span className='fs-6 text-break '>{rowData.userName}</span></li>
          <li><span className='text-capitalize fs-6 fw-bold'>Açıklama: </span><span className='fs-6 text-break '>{rowData.description}</span></li>
          {serviceOperationsButtons(rowData)}
        </ul>
      </div>
    );
  };
  // TAB - Money Transactions
  const moneyTransactionsButtons = (rowData) => {
    return (
      <div className={`d-flex justify-content-center align-items-${isMobile ? 'start' : 'center'}`} style={{ minWidth: `${isMobile ? '120px' : '240px'}` }}>
        <button className={`button-with-icon btn ${isMobile ? 'btn-sm' : ''} btn-danger ${isMobile ? 'me-1' : 'mx-1'}`} onClick={() => handleShowDeleteModal('kasa işlemi', 'Kasa İşlemi Sil', `${rowData.id} id numaralı kasa işlemini silmek istediğinze emin misiniz?`, <MdDeleteOutline style={{ fontSize: '1.5em' }} />, rowData.id)}>
          <MdDeleteOutline className='button-icon' />
          Sil
        </button>
        <button className={`button-with-icon btn ${isMobile ? 'btn-sm' : ''} btn-primary ${isMobile ? 'me-1' : 'mx-1'}`} onClick={() => handleShowCheckoutUpdateModal({})}>
          <MdOutlineEdit className='button-icon' />
          Güncelle
        </button>
      </div>
    )
  }
  const [moneytransactionsexpanderopened, setMoneytransactionsexpanderopened] = useState(null);
  const moneytransactionsexpander = (rowData) => {
    return (
      <div className="pb-0 ps-2 text-start">
        <ul className='list-unstyled mb-1'>
          <li><span className='text-capitalize fs-6 fw-bold'>İşlemi Yapan: </span><span className='fs-6 text-break '>{rowData.user}</span></li>
          <li><span className='text-capitalize fs-6 fw-bold'>Ödeme Şekli: </span><span className='fs-6 text-break '>{rowData.direction}</span></li>
          <li><span className='text-capitalize fs-6 fw-bold'>Durum: </span><span className='fs-6 text-break '>{rowData.status}</span></li>
          <li><span className='text-capitalize fs-6 fw-bold'>Açıklama: </span><span className='fs-6 text-break '>{rowData.description}</span></li>
          {moneyTransactionsButtons(rowData)}
        </ul>
      </div>
    );
  };
  // TAB - Photos
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  // TAB - Receipt Note
  const receiptNoteButtons = (rowData) => {
    return (
      <div className={`d-flex justify-content-center align-items-${isMobile ? 'start' : 'center'}`}>
        <button className={`button-with-icon btn ${isMobile ? 'btn-sm' : ''} btn-danger`} onClick={() => handleShowDeleteModal('fatura notu', 'Fatura Notunu Sil', `${rowData.id} id numaralı fatura notu silmek istediğinze emin misiniz?`, <IoMdRemoveCircleOutline style={{ fontSize: '1.5em' }} />, rowData.id)}>
          <IoMdRemoveCircleOutline className='button-icon' />
          Sil
        </button>
      </div>
    );
  }
  const [receiptnoteexpanderopened, setReceiptnoteexpanderopened] = useState(null);
  const receiptnoteexpander = (rowData) => {
    return (
      <div className="pb-0 ps-2 text-start">
        <ul className='list-unstyled mb-1'>
          <li><span className='text-capitalize fs-6 fw-bold'>Açıklama: </span><span className='fs-6 text-break '>{rowData.description}</span></li>
          {receiptNoteButtons(rowData)}
        </ul>
      </div>
    );
  };
  // TAB - Operator Note
  const operatorNoteButtons = (rowData) => {
    return (
      <div className={`d-flex justify-content-center align-items-${isMobile ? 'start' : 'center'}`}>
        <button className={`button-with-icon btn ${isMobile ? 'btn-sm' : ''} btn-danger`} onClick={() => handleShowDeleteModal('operatör notu', 'Operatör Notunu Sil', `${rowData.id} id numaralı operatör notu silmek istediğinze emin misiniz?`, <IoMdRemoveCircleOutline style={{ fontSize: '1.5em' }} />, rowData.id)}>
          <IoMdRemoveCircleOutline className='button-icon' />
          Sil
        </button>
      </div>
    );
  }
  const [operatornoteexpanderopened, setOperatornoteexpanderopened] = useState(null);
  const operatornoteexpander = (rowData) => {
    return (
      <div className="pb-0 ps-2 text-start">
        <ul className='list-unstyled mb-1'>
          <li><span className='text-capitalize fs-6 fw-bold'>Açıklama: </span><span className='fs-6 text-break '>{rowData.description}</span></li>
          {operatorNoteButtons(rowData)}
        </ul>
      </div>
    );
  };
  // TAB - Offers
  // TAB - Invoices
  // TAB - Reports
  const [reportsexpanderopened, setReportsexpanderopened] = useState(null);
  const reportsexpander = (rowData) => {
    return (
      <div className="pb-0 ps-2 text-start">
        <ul className='list-unstyled mb-1'>
          <li><span className='text-capitalize fs-6 fw-bold'>Açıklama: </span><span className='fs-6 text-break '>{rowData.description}</span></li>
        </ul>
      </div>
    );
  };
  // Modals
  // Customer Update Modal
  const [showCustomerUpdateModal, setShowCustomerUpdateModal] = useState('');
  const [inputDataForCustomerUpdateModal, setInputDataForCustomerUpdateModal] = useState({});
  const handleCloseCustomerUpdateModal = () => {
    setShowCustomerUpdateModal(false);
    setInputDataForCustomerUpdateModal({});
  }
  const handleShowCustomerUpdateModal = (data) => {
    setInputDataForCustomerUpdateModal(data);
    setShowCustomerUpdateModal(true);
  }
  const handleUpdateCustomerUpdateModal = (values) => { console.log('Kullanıcı Güncellendi: ', inputDataForCustomerUpdateModal, ' ---> ', values); }
  // Checkout Add Modal
  const [showCheckoutAddModal, setShowCheckoutAddModal] = useState(false);
  const [inputDataForCheckoutAddModal, setInputDataForCheckoutAddModal] = useState('Gelen Ödeme');
  const handleCloseCheckoutAddModal = () => { setShowCheckoutAddModal(false); setInputDataForCheckoutAddModal(''); }
  const handleShowCheckoutAddModal = (type) => { setInputDataForCheckoutAddModal(type); setShowCheckoutAddModal(true); }
  const handleAddCheckoutAddModal = (values) => { console.log('Yeni kasa işlemi eklendi: ', values); handleCloseCheckoutAddModal(); }
  // Checkout Update Modal
  const [showCheckoutUpdateModal, setShowCheckoutUpdateModal] = useState(false);
  const [inputDataForCheckoutUpdateModal, setInputDataFoCheckoutUpdateModal] = useState({});
  const handleCloseCheckoutUpdateModal = () => { setShowCheckoutUpdateModal(false); setInputDataFoCheckoutUpdateModal({}); }
  const handleShowCheckoutUpdateModal = (data) => { setInputDataFoCheckoutUpdateModal(data); setShowCheckoutUpdateModal(true); }
  const handleUpdateCheckoutUpdateModal = (values) => { console.log('Kasa İşlemleri Güncellendi: ', inputDataForCheckoutUpdateModal, ' ---> ', values); handleCloseCheckoutUpdateModal(); }
  // Note Add Modal
  const [showNoteAddModal, setShowNoteAddModal] = useState(false);
  const [typeNoteAddModal, setTypeNoteAddModal] = useState('');
  const handleCloseNoteAddModal = () => { setShowNoteAddModal(false); setTypeNoteAddModal(''); }
  const handleShowNoteAddModal = (type) => { setTypeNoteAddModal(type); setShowNoteAddModal(true); }
  const handleAddNoteAddModal = (values) => { console.log(`Yeni ${typeNoteAddModal} notu eklendi: `, values); handleCloseNoteAddModal(); }
  // Delete Modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [typeDeleteModal, setTypeDeleteModal] = useState(null);
  const [titleDeleteModal, setTitleDeleteModal] = useState('');
  const [messageDeleteModal, setMessageDeleteModal] = useState('');
  const [iconDeleteModal, setIconDeleteModal] = useState(null);
  const [inputDataForEmployeeDeleteModal, setInputDataForEmployeeDeleteModal] = useState(-1);
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setTypeDeleteModal(null);
    setTitleDeleteModal('');
    setMessageDeleteModal('');
    setIconDeleteModal('');
    setInputDataForEmployeeDeleteModal(-1);
  }
  const handleShowDeleteModal = (type, title, message, icon, id) => {
    setTypeDeleteModal(type);
    setTitleDeleteModal(title);
    setMessageDeleteModal(message);
    setIconDeleteModal(icon);
    setInputDataForEmployeeDeleteModal(id);
    setShowDeleteModal(true);
  }
  const handleDeleteDeleteModal = () => { console.log(`${inputDataForEmployeeDeleteModal} id numaralı ${typeDeleteModal} silindi.`); handleCloseDeleteModal(); }
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered className='modal-xl' style={{ maxHeight: '90vh', overflowY: 'auto' }}>
      <Modal.Header closeButton>
        <Modal.Title>Servis Detayları ({inputData.id} - {inputData.name} ({inputData.brandName}/{inputData.deviceName}))</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="service-informations" fill>
          <Tab eventKey="service-informations" title='Servis Bilgileri'>
            <Formik key={0} initialValues={initalValues} validationSchema={validationSchema_ServiceUpdate} onSubmit={(values) => handleUpdate(values)} >
              {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
                <Form>
                  <div className='row g-2 my-2'>
                    <div className='col-12 col-md-12'>
                      <div className='card bg-light m-0 p-1'>
                        <div className='row'>
                          <div className='col-12 col-md-6'>
                            <div className='row'>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='ServiceUpdateDate_date'
                                  name='date'
                                  label="Tarih"
                                  value={serviceDate}
                                  slotProps={{ input: { readOnly: true } }}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                />
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-md-6'>
                            <div className='row g-2'>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={Autocomplete}
                                  id="ServiceUpdateDate_sourceName"
                                  name="sourceName"
                                  value={values.sourceName}
                                  onChange={(event, newValue) => { setFieldValue('sourceName', newValue); }}
                                  onBlur={() => { setFieldTouched('sourceName', true); }}
                                  variant='outlined'
                                  size='small'
                                  options={sources.map(item => item.name)}
                                  renderInput={(params) => <TextField {...params} label="Kaynak" error={touched.sourceName && !!errors.sourceName} helperText={touched.sourceName && errors.sourceName} />}
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='ServiceUpdateDate_username'
                                  label="Operatör"
                                  value={inputData.userName}
                                  slotProps={{ input: { readOnly: true } }}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-6 d-flex align-items-stretch'>
                      <div className='card bg-light m-0 flex-fill'>
                        <div className='card-header d-flex justify-content-between py-1'>
                          <div className='card-title my-1'>Müşteri Bilgileri</div>
                          <button type='button' className='btn btn-outline-warning mx-1 p-1' onClick={() => handleShowCustomerUpdateModal({})}><TbUserEdit style={{ fontSize: '1.5em' }} /></button>
                        </div>
                        <div className='card-body'>
                          <div className='row g-2'>
                            <div className='col-6 col-md-6'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_name'
                                name='name'
                                label="Müşteri Adı"
                                value={inputData.name}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-6 col-md-6'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_customerType'
                                name='customerType'
                                label="Müşteri Türü"
                                value={inputData.customerType}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-6 col-md-6'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_phone1'
                                name='phone1'
                                label="Birinci Telefon"
                                value={inputData.phone1}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-6 col-md-6'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_phone2'
                                name='phone2'
                                label="İkinci Telefon"
                                value={inputData.phone2}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-6 col-md-6'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_city'
                                name='city'
                                label="İl"
                                value={inputData.city}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-6 col-md-6'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_district'
                                name='district'
                                label="İlçe"
                                value={inputData.district}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_address'
                                name='address'
                                label="Adres"
                                value={inputData.address}
                                slotProps={{ input: { resize: "none", readOnly: true } }}
                                multiline
                                minRows={2}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-6 col-md-6'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_customerDate'
                                name='customerDate'
                                label='Müsait Zaman'
                                type="date"
                                variant='outlined'
                                size='small'
                                value={values.customerDate}
                                onChange={(e) => setFieldValue('customerDate', e.target.value)}
                                slotProps={{ input: { shrink: "true" } }}
                                fullWidth
                                error={touched.customerDate && !!errors.customerDate}
                                helperText={touched.customerDate && errors.customerDate}
                              />
                            </div>
                            <div className='col-6 col-md-3'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_customerTimeStart'
                                name='customerTimeStart'
                                label='Başlangıç'
                                type="time"
                                variant='outlined'
                                size='small'
                                value={values.customerTimeStart}
                                onChange={(e) => setFieldValue('customerTimeStart', e.target.value)}
                                slotProps={{ input: { shrink: "true" } }}
                                fullWidth
                                error={touched.customerTimeStart && !!errors.customerTimeStart}
                                helperText={touched.customerTimeStart && errors.customerTimeStart}
                              />
                            </div>
                            <div className='col-6 col-md-3'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_customerTimeEnd'
                                name='customerTimeEnd'
                                label='Bitiş'
                                type="time"
                                variant='outlined'
                                size='small'
                                value={values.customerTimeEnd}
                                onChange={(e) => setFieldValue('customerTimeEnd', e.target.value)}
                                slotProps={{ input: { shrink: "true" } }}
                                fullWidth
                                error={touched.customerTimeEnd && !!errors.customerTimeEnd}
                                helperText={touched.customerTimeEnd && errors.customerTimeEnd}
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={Autocomplete}
                                id="ServiceUpdateDate_consignmentDevice"
                                name="consignmentDevice"
                                value={values.consignmentDevice}
                                onChange={(event, newValue) => {
                                  setFieldValue('consignmentDevice', newValue);
                                  console.log(newValue);
                                }}
                                onBlur={() => { setFieldTouched('consignmentDevice', true); }}
                                variant='outlined'
                                size='small'
                                options={consigments}
                                getOptionLabel={(option) => `${option.id} - ${option.brandName}/${option.deviceName} ${option.model}`}
                                renderOption={(props, option) => {
                                  const { key, ...otherProps } = props;
                                  return (
                                    <li key={option.id} {...otherProps}>
                                      {option ? `${option.id} - ${option.brandName}/${option.deviceName} ${option.model}` : ''}
                                    </li>
                                  )
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <TextField {...params} label="Konsinye Cihaz Seçiniz" />}
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_invoice'
                                name='invoice'
                                label="Fatura"
                                value={''}
                                onChange={(e) => setFieldValue('invoice', e.target.value)}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-6 d-flex align-items-stretch'>
                      <div className='card bg-light m-0 flex-fill'>
                        <div className='card-header d-flex justify-content-between py-1'>
                          <div className='card-title my-1'>Cihaz Bilgileri</div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                checked={values.isEmergency}
                                onChange={(e) => setFieldValue('isEmergency', e.target.checked)}
                              />
                            }
                            label="ACİL!"
                          />
                        </div>
                        <div className='card-body'>
                          <div className='row g-2'>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={Autocomplete}
                                id="ServiceUpdateDate_brandName"
                                name="brandName"
                                value={values.brandName}
                                onChange={(event, newValue) => { setFieldValue('brandName', newValue); }}
                                onBlur={() => { setFieldTouched('brandName', true); }}
                                variant='outlined'
                                size='small'
                                options={brands.map(item => item.name)}
                                renderInput={(params) => <TextField {...params} label="Cihaz Markası" error={touched.brandName && !!errors.brandName} helperText={touched.brandName && errors.brandName} />}
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={Autocomplete}
                                id="ServiceUpdateDate_deviceName"
                                name="deviceName"
                                value={values.deviceName}
                                onChange={(event, newValue) => { setFieldValue('deviceName', newValue); }}
                                onBlur={() => { setFieldTouched('deviceName', true); }}
                                variant='outlined'
                                size='small'
                                options={devices.map(item => item.name)}
                                renderInput={(params) => <TextField {...params} label="Cihaz Markası" error={touched.deviceName && !!errors.deviceName} helperText={touched.deviceName && errors.deviceName} />}
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_model'
                                name='model'
                                label="Cihaz Modeli"
                                value={values.model}
                                onChange={(e) => setFieldValue('model', e.target.value)}
                                variant="outlined"
                                size="small"
                                fullWidth
                                error={touched.model && !!errors.model}
                                helperText={touched.model && errors.model}
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_stockNo'
                                name='stockNo'
                                label="Stok Seri Numarası"
                                value={''}
                                onChange={(e) => setFieldValue('stockNo', e.target.value)}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_problem'
                                name='problem'
                                label="Cihaz Arızası"
                                value={values.problem}
                                onChange={(e) => setFieldValue('problem', e.target.value)}
                                slotProps={{ input: { resize: "none" } }}
                                multiline
                                minRows={2}
                                variant="outlined"
                                size="small"
                                fullWidth
                                error={touched.problem && !!errors.problem}
                                helperText={touched.problem && errors.problem}
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id='ServiceUpdateDate_note'
                                name='note'
                                label="Operator Notu"
                                value={values.note}
                                onChange={(e) => setFieldValue('note', e.target.value)}
                                slotProps={{ input: { resize: "none" } }}
                                multiline
                                minRows={2}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={Autocomplete}
                                id="ServiceUpdateDate_warranty"
                                name="warranty"
                                value={values.warranty}
                                onChange={(event, newValue) => { setFieldValue('warranty', newValue); }}
                                onBlur={() => { setFieldTouched('warranty', true); }}
                                variant='outlined'
                                size='small'
                                options={warranties}
                                getOptionLabel={(option) => option === 0 ? 'Yok' : `${option.toString()} Ay`}
                                renderOption={(props, option) => {
                                  const { key, ...otherProps } = props;
                                  return (
                                    <li key={option} {...otherProps}>
                                      {option === 0 ? 'Yok' : `${option.toString()} Ay`}
                                    </li>
                                  );
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <TextField {...params} label="Cihaz Garantisi" error={touched.warranty && !!errors.warranty} helperText={touched.warranty && errors.warranty} />}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-12'>
                      <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                        <div className='col-12 col-md-6'>
                          <div className='row'>
                            <div className='col-12 col-md-6'>
                              <button type='button' className='button-with-icon btn btn-danger w-100' onClick={() => handleDeleteDeleteModal(inputData.id)}><TbFolderX className='button-icon' />Servisi Sil</button>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-md-3'>
                          <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Kaydetmeden Çık</button>
                        </div>
                        <div className='col-12 col-md-3'>
                          <button type='submit' className='button-with-icon btn btn-primary w-100'><TbFolderCog className='button-icon' />Servisi Güncelle</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Tab>
          <Tab eventKey="service-operations" title='Servis İşlemleri'>
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-1 m-1'>
                <div className='row g-2 d-flex justify-content-between'>
                  <div className='col-12 col-md-4'>
                    <TextField
                      label="Bugünün Tarihi"
                      value={serviceDate}
                      slotProps={{ input: { readOnly: true } }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='row g-2'>
                      <div className='col-12 col-md-6'>
                        <TextField
                          label="Kaynak"
                          className='me-2'
                          value={inputData.sourceName}
                          slotProps={{ input: { readOnly: true } }}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </div>
                      <div className='col-12 col-md-6'>
                        <TextField
                          label="Operatör"
                          value={inputData.userName}
                          slotProps={{ input: { readOnly: true } }}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-1 m-1'>
                <div className='row g-2 d-flex justify-content-between'>
                  <div className='col-12 col-md-4'>
                    <TextField
                      label="Servis Durumu"
                      value={(inputData.status)}
                      slotProps={{ input: { readOnly: true } }}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <Autocomplete
                      value={operation}
                      onChange={(event, newValue) => setOperations(newValue ? newValue : '')}
                      variant='outlined'
                      size='small'
                      options={serviceOperationsData}
                      renderInput={(params) => <TextField {...params} label="Yapılacak İşlem" />}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
            </div>
            {operation !== '' && (
              <div className='card bg-light mt-1 mb-0'>
                <div className='card-body p-1 m-1'>
                  <div className='row g-2'>
                    <ServiceOperations
                      type={operation}
                      handleAdd={handleAddOperationsFunction}
                      users={employees.map(item => item.name)}
                      dealers={dealers.map(item => item.name)}
                      products={stocks.map(item => item.name)}
                      vehicles={vehicles.map(item => item.plate)}
                      serviceId={inputData.id}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-0 m-1'>
                {isMobile ? (
                  <DataTable
                    value={operationData}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    expandedRows={serviceoperationsexpanderopened}
                    onRowToggle={(e) => setServiceoperationsexpanderopened(e.data)}
                    rowExpansionTemplate={serviceoperationsexpander}
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' expander style={{ width: "0.5em" }} />
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'actionName'} header={'İŞLEM ADI'} bodyClassName='align-middle fs-6 text-center' />
                  </DataTable >
                ) : (
                  <DataTable
                    value={operationData}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'userName'} header={'İŞLEMİ YAPAN'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'actionName'} header={'İŞLEM ADI'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'description'} header={'AÇIKLAMA'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' body={serviceOperationsButtons} bodyClassName="align-middle" />
                  </DataTable >
                )}
              </div>
            </div>
          </Tab>
          <Tab eventKey="money-transactions" title='Para Hareketleri'>
            <div className='card bg-light mt-2 mb-0'>
              <div className='card-body row g-2 d-flex justify-content-end p-0 m-1'>
                <div className='col-12 col-md-3 m-0'>
                  <button className='button-with-icon btn btn-success w-100' onClick={() => handleShowCheckoutAddModal({ direction: 'Gelen Ödeme' })}><IoMdAddCircleOutline className='button-icon' />Gelir Ekle</button>
                </div>
                <div className='col-12 col-md-3 m-0'>
                  <button className='button-with-icon btn btn-danger w-100' onClick={() => handleShowCheckoutAddModal({ direction: 'Giden Ödeme' })}><IoMdRemoveCircleOutline className='button-icon' />Gider Ekle</button>
                </div>
              </div>
            </div>
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-0 m-1'>
                {isMobile ? (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    expandedRows={moneytransactionsexpanderopened}
                    onRowToggle={(e) => setMoneytransactionsexpanderopened(e.data)}
                    rowExpansionTemplate={moneytransactionsexpander}
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' expander style={{ width: "0.5em" }} />
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'finalamount'} header={'FİYAT'} bodyClassName='align-middle fs-6 text-center' />
                  </DataTable >
                ) : (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'user'} header={'İŞLEMİ YAPAN'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'direction'} header={'ÖDEME ŞEKLİ'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'description'} header={'AÇIKLAMA'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'status'} header={'DURUM'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'finalamount'} header={'FİYAT'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' body={moneyTransactionsButtons} bodyClassName="align-middle" />
                  </DataTable >
                )}
              </div>
            </div>
          </Tab>
          <Tab eventKey="photos" title='Fotoğraflar'>
            <div className='row justify-content-center px-3  pt-4 '>
              <Button
                className='fs-7'
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                startIcon={<MdCloudUpload />}
              >
                Resim dosyası ekleyin (en fazla 5 MB jpg,png dosyaları yüklenebilir )
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleFileChange}
                  multiple
                />
              </Button>
              {/* {photos.map((photo, index) => (
                <div key={index} className='col-md-3 col-sm-6 col-6 mb-4  '>
                  <div className="img-wrapper">
                    <LazyLoadImage
                      alt={`Photo ${index + 1}`}
                      effect='opacity'
                      wrapperProps={{
                        style: { transitionDelay: "1s" },
                      }}
                      src={photo} // Fotoğrafın URL'si
                      className='img-fluid rounded img-custom'
                    />
                  </div>
                </div>
              ))} */}
            </div>
          </Tab>
          <Tab eventKey="receipt-note" title='Fiş Notu'>
            <div className='card bg-light mt-2 mb-0'>
              <div className='card-body row g-2 d-flex justify-content-end p-0 m-1'>
                <div className='col-12 col-md-3 m-0'>
                  <button className='button-with-icon btn btn-success w-100' onClick={() => handleShowNoteAddModal('operatör')}><IoMdAddCircleOutline className='button-icon' />Fatura Notu Ekle</button>
                </div>
              </div>
            </div>
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-0 m-1'>
                {isMobile ? (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    expandedRows={receiptnoteexpanderopened}
                    onRowToggle={(e) => setReceiptnoteexpanderopened(e.data)}
                    rowExpansionTemplate={receiptnoteexpander}
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' expander style={{ width: "0.5em" }} />
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'employee'} header={'PERSONEL'} bodyClassName='align-middle fs-6 text-center' />
                  </DataTable >
                ) : (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'employee'} header={'PERSONEL'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'description'} header={'AÇIKLAMA'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' body={receiptNoteButtons} bodyClassName="align-middle" />
                  </DataTable >
                )}
              </div>
            </div>
          </Tab>
          <Tab eventKey="operator-note" title='Operatör Notu'>
            <div className='card bg-light mt-2 mb-0'>
              <div className='card-body row g-2 d-flex justify-content-end p-0 m-1'>
                <div className='col-12 col-md-3 m-0'>
                  <button className='button-with-icon btn btn-success w-100' onClick={() => handleShowNoteAddModal('operatör')}><IoMdAddCircleOutline className='button-icon' />Operatör Notu Ekle</button>
                </div>
              </div>
            </div>
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-0 m-1'>
                {isMobile ? (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    expandedRows={operatornoteexpanderopened}
                    onRowToggle={(e) => setOperatornoteexpanderopened(e.data)}
                    rowExpansionTemplate={operatornoteexpander}
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' expander style={{ width: "0.5em" }} />
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'employee'} header={'PERSONEL'} bodyClassName='align-middle fs-6 text-center' />
                  </DataTable >
                ) : (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'employee'} header={'PERSONEL'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'description'} header={'AÇIKLAMA'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' body={operatorNoteButtons} bodyClassName="align-middle" />
                  </DataTable >
                )}
              </div>
            </div>
          </Tab>
          <Tab eventKey="offers" title='Teklifler'>
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-0 m-1'>
                <DataTable
                  value={[]}
                  size='small'
                  stripedRows
                  emptyMessage="Bu tabloda henüz veri yok!"
                  showGridlines
                  paginatorLeft
                  paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                  paginator
                  paginatorClassName='bg-light'
                  rows={5}>
                  <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                  <Column align='center' sortable field={'status'} header={'DURUM'} bodyClassName='align-middle fs-6 text-center' />
                  <Column align='center' sortable field={'total'} header={'GENEL TOPLAM'} bodyClassName='align-middle fs-6 text-center' />
                </DataTable >
              </div>
            </div>
          </Tab>
          <Tab eventKey="invoices" title='Faturalar'>
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-0 m-1'>
                <DataTable
                  value={[]}
                  size='small'
                  stripedRows
                  emptyMessage="Bu tabloda henüz veri yok!"
                  showGridlines
                  paginatorLeft
                  paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                  paginator
                  paginatorClassName='bg-light'
                  rows={5}>
                  <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                  <Column align='center' sortable field={'status'} header={'DURUM'} bodyClassName='align-middle fs-6 text-center' />
                  <Column align='center' sortable field={'total'} header={'GENEL TOPLAM'} bodyClassName='align-middle fs-6 text-center' />
                </DataTable >
              </div>
            </div>
          </Tab>
          <Tab eventKey="reports" title='Raporlar'>
            <div className='card bg-light mt-1 mb-0'>
              <div className='card-body p-0 m-1'>
                {isMobile ? (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    expandedRows={reportsexpanderopened}
                    onRowToggle={(e) => setReportsexpanderopened(e.data)}
                    rowExpansionTemplate={reportsexpander}
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' expander style={{ width: "0.5em" }} />
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'total'} header={'TOPLAM'} bodyClassName='align-middle fs-6 text-center' />
                  </DataTable >
                ) : (
                  <DataTable
                    value={[]}
                    size='small'
                    stripedRows
                    emptyMessage="Bu tabloda henüz veri yok!"
                    showGridlines
                    paginatorLeft
                    paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink '}
                    paginator
                    paginatorClassName='bg-light'
                    rows={5}>
                    <Column align='center' sortable field={'date'} header={'TARİH'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'description'} header={'AÇIKLAMA'} bodyClassName='align-middle fs-6 text-center' />
                    <Column align='center' sortable field={'total'} header={'TOPLAM'} bodyClassName='align-middle fs-6 text-center' />
                  </DataTable >
                )}
              </div>
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
      <CustomerUpdateModal
        show={showCustomerUpdateModal}
        handleClose={handleCloseCustomerUpdateModal}
        handleUpdate={handleUpdateCustomerUpdateModal}
        inputData={inputDataForCustomerUpdateModal}
      />
      <CheckoutAddModal
        show={showCheckoutAddModal}
        handleClose={handleCloseCheckoutAddModal}
        handleAdd={handleAddCheckoutAddModal}
        inputData={inputDataForCheckoutAddModal}
      />
      <CheckoutUpdateModal
        show={showCheckoutUpdateModal}
        handleClose={handleCloseCheckoutUpdateModal}
        handleUpdate={handleUpdateCheckoutUpdateModal}
        inputData={inputDataForCheckoutUpdateModal}
      />
      <NoteAddModal
        show={showNoteAddModal}
        handleClose={handleCloseNoteAddModal}
        handleAdd={handleAddNoteAddModal}
      />
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDeleteDeleteModal}
        title={titleDeleteModal}
        message={messageDeleteModal}
        deleteIcon={iconDeleteModal}
      />
    </Modal>
  );
}

function handleFileChange(event) {
  const files = event.target.files;
  if (files.length > 0) {
    [...files].map((file) => {
      const selectfile = file;
      const fileName = selectfile.name.toLowerCase();
      const fileSize = selectfile.size;
      const validFileExtensions = ['.jpg', '.jpeg', '.png'];
      const fileExtension = fileName.slice(fileName.lastIndexOf('.'));
      if (!validFileExtensions.includes(fileExtension)) {
        alert('Sadece JPG ve PNG dosyalarına izin verilmektedir.');
        event.target.value = null;
        return null;
      }
      if (fileSize > MAX_FILE_SIZE) {
        alert('Dosya boyutu 5 MB\'ı geçemez.');
        event.target.value = null;
        return null;
      }
      console.log('Geçerli dosya:', file);
      return null;
    })

  }
}

export default ServiceDetailsModal;