// General
import React from 'react';

function DataErrorPage() {
  // HTML
  return (
    <div className='d-flex justify-content-center align-items-center w-100 h-100'>
      <h2>Verilere Ulaşılamadı.</h2>
    </div>
  );
}

export default DataErrorPage;