// General
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
// Context
import { useData } from '../../contexts/DataContext';
// Icons
import { FiHome, FiSettings, FiUser, FiUsers, FiLayers, FiCalendar, FiTag, FiMessageSquare } from "react-icons/fi";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { IoStatsChartOutline, IoDocumentsOutline } from "react-icons/io5";
import { TbCashRegister, TbInvoice, TbSubtask, TbDatabase, TbBrandCake, TbTruckDelivery } from "react-icons/tb";
import { MdChevronRight, MdOutlineOtherHouses } from "react-icons/md";
import { CiDeliveryTruck, CiUser } from "react-icons/ci";
import { BsHouseDoor, BsPlug } from "react-icons/bs";
import { VscGithubAction } from "react-icons/vsc";
import { FaWarehouse } from "react-icons/fa6";
import { SiOpensourcehardware } from "react-icons/si";
// Image
import logo from '../../assets/images/logo.png';

function Sidebar() {
  // Context Import
  const { sidebar, setSidebar } = useData();
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => { setIsOpen((prev) => !prev); }
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const closeMenu = () => { if (isMobile) { setSidebar(false); } }
  // HTML
  return (
    <>
      <div className="app-sidebar-menu" style={{ width: `${sidebar ? '260px' : '0px'}` }}>
        <div className="h-100 sidebarscrollbar">
          <div id="sidebar-menu">
            <div className="logo-box pt-3">
              <NavLink to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="27" />
                </span>
              </NavLink>
              <NavLink to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="25" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="27" />
                </span>
              </NavLink>
            </div>
            <ul id="side-menu">
              <li className="menu-title">MENÜ</li>
              <li>
                <NavLink to="/anasayfa" className="tp-link" onClick={closeMenu}>
                  <FiHome /><span>Anasayfa</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/servisler" className="tp-link" onClick={closeMenu}>
                  <FiSettings /><span>Servisler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/musteriler" className="tp-link" onClick={closeMenu}>
                  <FiUser /><span>Müşteriler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/personeller" className="tp-link" onClick={closeMenu}>
                  <FiUsers /><span>Personeller</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/depo" className="tp-link" onClick={closeMenu}>
                  <FiLayers /><span>Depo</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/teklifler" className="tp-link" onClick={closeMenu}>
                  <FiTag /><span>Teklifler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/hatirlatici" className="tp-link" onClick={closeMenu}>
                  <FiCalendar /><span>Hatırlatıcı</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/anketler" className="tp-link" onClick={closeMenu}>
                  <HiOutlineClipboardDocumentList /><span>Anketler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/istatistikler" className="tp-link" onClick={closeMenu}>
                  <IoStatsChartOutline /><span>İstatistikler</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/kasa" className="tp-link" onClick={closeMenu}>
                  <TbCashRegister /><span>Kasa</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/fatura" className="tp-link" onClick={closeMenu}>
                  <TbInvoice /><span>Fatura</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/toplumesaj" className="tp-link" onClick={closeMenu}>
                  <FiMessageSquare /><span>Toplu SMS</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/islemraporları" className="tp-link" onClick={closeMenu}>
                  <IoDocumentsOutline /><span>İşlem Raporları</span>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={toggleMenu} className="d-flex align-items-center">
                  <MdOutlineOtherHouses className="priorityicon me-1" />
                  <span>Diğer</span>
                  <MdChevronRight className={`priorityicon menu-arrow ${isOpen ? 'rotate-90' : ''}`} />
                </NavLink>
                {isOpen && (
                  <div className="collapse show">
                    <ul className="nav-second-level px-3">
                      <li>
                        <NavLink to="/araclar" className="tp-link" onClick={closeMenu}>
                          <CiDeliveryTruck className=' fs-4 me-2 ms-1' />
                          <span>Araç Bilgileri</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/tedarikciler" className="tp-link" onClick={closeMenu}>
                          <CiUser className='fs-4 me-2 ms-1' />
                          <span>Tedarikçi Bilgileri</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/depolar" className="tp-link" onClick={closeMenu}>
                          <FaWarehouse className='fs-4 me-2 ms-1' />
                          <span>Depo Bilgileri</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/raflar" className="tp-link" onClick={closeMenu}>
                          <TbDatabase className='fs-4 me-2 ms-1' />
                          <span>Raf Bilgileri</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/roller" className="tp-link" onClick={closeMenu}>
                          <TbSubtask className='fs-4 me-2 ms-1' />
                          <span>Rol Bilgileri</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/markalar" className="tp-link" onClick={closeMenu}>
                          <TbBrandCake className='fs-4 me-2 ms-1' />
                          <span>Marka Bilgileri</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/cihazlar" className="tp-link" onClick={closeMenu}>
                          <BsPlug className='fs-4 me-2 ms-1' />
                          <span>Cihaz Bilgileri</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/bayiler" className="tp-link" onClick={closeMenu}>
                          <BsHouseDoor className='fs-4 me-2 ms-1' />
                          <span >Bayi Bilgileri</span>
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/stokaksiyonlari" className="tp-link" onClick={closeMenu}>
                          <VscGithubAction className='fs-4 me-2 ms-1' />
                          <span >Stok Hareketleri</span>
                        </NavLink>
                      </li> */}
                      <li>
                        <NavLink to="/kaynaklar" className="tp-link" onClick={closeMenu}>
                          <SiOpensourcehardware className='fs-4 me-2 ms-1' />
                          <span >Kaynak Bilgileri</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/konsinyecihazlar" className="tp-link" onClick={closeMenu}>
                          <TbTruckDelivery className='fs-4 me-2 ms-1' />
                          <span >Konsinye</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </>
  );
}
export default Sidebar;