// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { Field, Form, Formik } from 'formik';
// Contexts
import { useValidations } from '../../../../contexts/ValidationContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { AiOutlineProduct } from "react-icons/ai";

function StockTransactionAddModal({ show, handleClose, handleAddTransaction }) {
  // Context Import
  const { stringOnlyNumbers } = useFunctions();
  const { validationSchema_StockTransactionAdd } = useValidations();
  // Variables
  const suppliers = ["Emir", "Furkan"];
  const operations = ["Alış", "Personele Gönder"];
  // Formik Variables
  const initialValues = {
    operationName: '',
    supplierName: '',
    quantity: 0,
    purchasePrice: 0,
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header closeButton>
          <Modal.Title>Yeni Hareket Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik validationSchema={validationSchema_StockTransactionAdd} initialValues={initialValues} onSubmit={(values) => handleAddTransaction(values)}>
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => {
              return (
                <Form>
                  <div className='row g-2'>
                    <div className="col-12 col-md-12">
                      <Field
                        as={Autocomplete}
                        id="StockTransactionAddModal_operationName"
                        name="operationName"
                        options={operations}
                        value={values.operationName}
                        onChange={(event, newValue) => { setFieldValue('operationName', newValue); }}
                        onBlur={() => { setFieldTouched('operationName', true); }}
                        variant='outlined'
                        size='small'
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="İşlem *" error={touched.operationName && !!errors.operationName} helperText={touched.operationName && errors.operationName} />}
                      />
                    </div>
                    <div className="col-12 col-md-12">
                      <Field
                        as={Autocomplete}
                        id="StockTransactionAddModal_supplierName"
                        name="supplierName"
                        options={suppliers}
                        value={values.supplierName}
                        onChange={(event, newValue) => { setFieldValue('supplierName', newValue); }}
                        onBlur={() => { setFieldTouched('supplierName', true); }}
                        variant='outlined'
                        size='small'
                        fullWidth
                        renderInput={(params) => <TextField {...params} label="Tedarikçi" error={touched.supplierName && !!errors.supplierName} helperText={touched.supplierName && errors.supplierName} />}
                      />
                    </div>
                    <div className="col-12 col-md-12">
                      <Field
                        as={TextField}
                        id='StockTransactionAddModal_quantity'
                        name='quantity'
                        label='Adet *'
                        type="number"
                        value={values.quantity}
                        onChange={(e) => setFieldValue("quantity", stringOnlyNumbers(e.target.value))}
                        variant='outlined'
                        size='small'
                        fullWidth
                        error={touched.quantity && !!errors.quantity}
                        helperText={touched.quantity && errors.quantity}
                      />
                    </div>
                    <div className="col-12 col-md-12">
                      <Field
                        as={TextField}
                        id='StockTransactionAddModal_purchasePrice'
                        name='purchasePrice'
                        label='Alış fiyatı'
                        type='number'
                        value={values.purchasePrice}
                        onChange={(e) => setFieldValue("purchasePrice", stringOnlyNumbers(e.target.value))}
                        variant='outlined'
                        size='small'
                        fullWidth
                        error={touched.purchasePrice && !!errors.purchasePrice}
                        helperText={touched.purchasePrice && errors.purchasePrice}
                      />
                    </div>
                    <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                      <div className='col-12 col-md-6'>
                        <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                          <TbArrowBackUpDouble className='button-icon' />
                          Geri
                        </button>
                      </div>
                      <div className='col-12 col-md-6'>
                        <button type='submit' className='button-with-icon btn btn-success w-100' >
                          <AiOutlineProduct className='button-icon' />
                          Hareket Ekle
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  );
}

export default StockTransactionAddModal