// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { getAllDevices, getDeviceById, addNewDevice, updateDevice, deleteDevice, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetgetByIdStatus, resetError } from '../../../storages/slices/backend/DeviceSlice';
// Contexts
import { useData } from '../../../contexts/DataContext';
// Icons
import { FaPlugCirclePlus, FaPlugCircleCheck, FaPlugCircleMinus } from "react-icons/fa6";
import { TbPrinter } from "react-icons/tb";
// Modals
import DeviceAddModal from "../modals/devices/DeviceAddModal";
import DeviceUpdateModal from "../modals/devices/DeviceUpdateModal";
import DeleteModal from "../modals/DeleteModal";
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Devices() {
  // Storage Import
  const dispatch = useDispatch();
  const { devices, device, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.device);
  // Context Import 
  const { myUser } = useData();
  // Variables
  const [canfetchData, setCanFetchData] = useState(false);
  // Data & Api
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllDevices({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseDeviceAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllDevices({}));
      dispatch(addToast({ background: 'success', icon: 'plugAdd', message: 'Yeni Cihaz Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseDeviceUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllDevices({}));
      dispatch(addToast({ background: 'primary', icon: 'plugUpdate', message: 'Cihaz Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      console.log("burada");
      handleDeleteDeviceDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllDevices({}));
      dispatch(addToast({ background: 'danger', icon: 'plugDelete', message: 'Cihaz Silindi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataForDeviceUpdateModal(device);
        setShowDeviceUpdateModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataForDeviceDeleteModal({ id: device.id, name: device.name });
        setShowDeviceDeleteModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus, dispatch]);
  useEffect(() => {
    if (error && error !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'CİHAZ ADI' }, { value: 'description', name: 'AÇIKLAMA' }];
  const filters = ['id', 'name', 'description'];
  const select = (id) => {
    handleShowDeviceUpdateModal(id);
  }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <FaPlugCircleCheck className='button-icon' />,
      onclick: ((rowData) => { handleShowDeviceUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <FaPlugCircleMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowDeviceDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={devices}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowDeviceAddModal(); }}><FaPlugCirclePlus className='button-icon' /> Yeni Cihaz Ekle</button >
        <button onClick={(e) => { e.stopPropagation(); dispatch(exportPdf({ rowsPerPage: rows, data: devices, cols: cols, pdftitle: "Cihazlar" })) }} className="dropdown-item"><TbPrinter className='button-icon' />  Yazdır</button >
      </>
    );
  }
  // Modals
  // Device Add Modal
  const [showDeviceAddModal, setShowDeviceAddModal] = useState(false);
  const handleCloseDeviceAddModal = () => { setShowDeviceAddModal(false) };
  const handleShowDeviceAddModal = () => { setShowDeviceAddModal(true) };
  const handleAddDeviceAddModal = (values) => {
    dispatch(addNewDevice({ newdevice: values }));
    handleCloseDeviceAddModal();
  };
  // Device Update Modal
  const [showDeviceUpdateModal, setShowDeviceUpdateModal] = useState(false);
  const [inputDataForDeviceUpdateModal, setInputDataForDeviceUpdateModal] = useState({});
  const handleCloseDeviceUpdateModal = () => {
    setShowDeviceUpdateModal(false)
    setInputDataForDeviceUpdateModal({});
  };
  const handleShowDeviceUpdateModal = (id) => {
    dispatch(getDeviceById({ id: id }));
    setDataRequester('update');
  }
  const handleUpdateDeviceUpdateModal = (values) => {
    dispatch(updateDevice({ device: values }));
    handleCloseDeviceUpdateModal();
  };
  // Device Delete Modal
  const [showDeviceDeleteModal, setShowDeviceDeleteModal] = useState(false);
  const [inputDataForDeviceDeleteModal, setInputDataForDeviceDeleteModal] = useState(-1);
  const handleCloseDeviceDeleteModal = () => {
    setShowDeviceDeleteModal(false)
    setInputDataForDeviceDeleteModal(-1);
  };
  const handleShowDeviceDeleteModal = (id) => {
    dispatch(getDeviceById({ id: id }));
    setDataRequester('delete');
  };
  const handleDeleteDeviceDeleteModal = () => {
    dispatch(deleteDevice({ id: inputDataForDeviceDeleteModal.id }));
    handleCloseDeviceDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <DeviceAddModal
          show={showDeviceAddModal}
          handleClose={handleCloseDeviceAddModal}
          handleAdd={handleAddDeviceAddModal}
        />
        <DeviceUpdateModal
          show={showDeviceUpdateModal}
          handleClose={handleCloseDeviceUpdateModal}
          handleUpdate={handleUpdateDeviceUpdateModal}
          inputData={inputDataForDeviceUpdateModal}
        />
        <DeleteModal
          show={showDeviceDeleteModal}
          handleClose={handleCloseDeviceDeleteModal}
          handleDelete={handleDeleteDeviceDeleteModal}
          title="Cihazı Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForDeviceDeleteModal.name} adlı cihazı silmek istediğinize emin misiniz?`}
          deleteIcon={<FaPlugCircleMinus className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage title='Cihazlar' Operations={operations} Table={() => tableContent} Modals={modals} />
  );
}

export default Devices;