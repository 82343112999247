// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Consigments/';

// Async Thunks
export const getAllConsigments = createAsyncThunk('backend_getallconsigments', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllConsigments`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getConsigmentById = createAsyncThunk('backend_getconsigmentbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetConsigment?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewConsigment = createAsyncThunk('backend_addnewconsigment', async ({ newconsigment }) => {
  try {
    const res = await axios.post(`${baseurl}AddConsigment`, newconsigment, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateConsigment = createAsyncThunk('backend_updateconsigment', async ({ consigment }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateConsigment`, consigment, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteConsigment = createAsyncThunk('backend_deleteconsigment', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteConsigment?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const consigmentSlice = createSlice({
  name: 'consigment',
  initialState: {
    consigments: [],
    consigment: {},
    getAllStatus: null,
    getByIdStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetgetByIdStatus: (state) => { state.getByIdStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllConsigments.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllConsigments.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.consigments = action.payload || []; })
      .addCase(getAllConsigments.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getConsigmentById.pending, (state) => { state.getByIdStatus = "pending"; })
      .addCase(getConsigmentById.fulfilled, (state, action) => { state.getByIdStatus = "fulfilled"; state.consigment = action.payload || {}; })
      .addCase(getConsigmentById.rejected, (state, action) => { state.getByIdStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewConsigment.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewConsigment.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewConsigment.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateConsigment.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateConsigment.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateConsigment.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteConsigment.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteConsigment.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteConsigment.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
})

export const { resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetgetByIdStatus, resetError } = consigmentSlice.actions;
export default consigmentSlice.reducer;