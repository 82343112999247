// General
import React, { useEffect, useState } from 'react'
// Styles
import { Modal, ModalBody } from 'react-bootstrap'
import { Field, Form, Formik } from 'formik'
import { Autocomplete, TextField } from '@mui/material';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { getDistricts } from '../../../../storages/slices/DistrictSlice';
import { getCustomerByPhone, resetgetByPhoneStatus } from '../../../../storages/slices/backend/CustomerSlice';
// Context
import { useValidations } from '../../../../contexts/ValidationContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
import { useData } from '../../../../contexts/DataContext';
// Icons
import { IoAddCircle } from "react-icons/io5";
import { TbArrowBackUpDouble } from "react-icons/tb";
import { MdDeleteOutline } from 'react-icons/md';
// Components
import CustomerType from '../../components/CustomerType';

function OffersAddModal({ show, handleClose, handleAdd }) {
  // Storage Import
  const dispatch = useDispatch();
  const { districts } = useSelector((state) => state.district);
  const { customer, getByPhoneStatus } = useSelector((state) => state.customer);
  // Context Import
  const { validationSchema_Offers, validationSchema_OffersWithCustomerData } = useValidations();
  const { stringMaxLenght, stringOnlyNumbers, listAddItem, calculateTotalOfColumn, calculateAmountWithoutKdv, calculateTotal, listDeleteItem } = useFunctions();
  const { today, dollar, CustomerTypes, OfferStatuses, cities } = useData();
  // Variables
  const [customerData, setCustomerData] = useState(null);
  const [priceWithKdv, setPriceWithKdv] = useState(0);
  const [priceWithoutKdv, setPriceWithoutKdv] = useState(0);
  // Data & Api
  const [city, setCity] = useState('İstanbul');
  const fetchAllDataSequentially = async (dispatch, signal, selectedCity) => {
    try {
      await dispatch(getDistricts({ signal, city: selectedCity, }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (show && city) { fetchAllDataSequentially(dispatch, signal, city); }
    return () => { controller.abort(); };
  }, [city, show, dispatch]);
  useEffect(() => {
    if (getByPhoneStatus === 'fulfilled') { setCustomerData(customer) }
    else if (getByPhoneStatus) { setCustomerData(null) }
    dispatch(resetgetByPhoneStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByPhoneStatus]);
  // Formik Variables
  const initialValues = {
    customerName: "",
    customerPhone1: "",
    customerPhone2: "",
    customerAddress: "",
    city: "İstanbul",
    district: "",
    identityNumber: "",
    taxNumber: "",
    taxOffice: "",
    customerType: "Bireysel",
    title: "",
    date: today,
    totalText: "",
    currency: 30,
    total: 0,
    kdv: 0,
    generalTotal: 0,
    explanation: "",
    status: "",
    offerDetails: [
      {
        id: 0,
        offerId: 0,
        type: "",
        quantity: 0,
        price: 0,
        total: 0,
      }
    ],
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal-xl'>
        <Modal.Header closeButton className='py-2'>
          <Modal.Title> Teklif Ekle</Modal.Title>
        </Modal.Header>
        <ModalBody>
          <Formik initialValues={initialValues} validationSchema={customerData === null ? validationSchema_Offers : validationSchema_OffersWithCustomerData} onSubmit={(values) => { if (values.offerDetails.length > 0) { handleAdd(customerData === null ? values : { ...values, customerName: customerData.name, customerAddress: customerData.address, city: customerData.city, district: customerData.district, customerType: customerData.customerType, customerPhone2: customerData.phone2, identityNumber: customerData.identityNumber, taxNumber: customerData.taxNumber, taxOffice: customerData.taxOffice }) } }} >
            {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
              <Form>
                <div className='row g-2'>
                  <div className='col-12 col-md-12 mt-0'>
                    <div className="card bg-light m-0">
                      <div className='card-header py-1'>
                        <div className='row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100'>
                          <div className='col-12 col-md-6 d-flex align-items-center'>
                            <div className='card-title my-1'>Teklif Bilgileri</div>
                          </div>
                          <div className="col-12 col-md-3">
                            <Field
                              as={TextField}
                              id='OffersAddModal_KDVCost'
                              name='generalkdvcost'
                              label="KDV'li fiyat"
                              value={priceWithKdv}
                              onChange={(e) => {
                                setPriceWithKdv(e.target.value);
                                setPriceWithoutKdv(calculateAmountWithoutKdv(e.target.value, parseFloat(values.kdv)));
                              }}
                              type="number"
                              variant='outlined'
                              size='small'
                              fullWidth
                            />
                          </div>
                          <div className="col-12 col-md-3">
                            <Field
                              as={TextField}
                              id='OffersAddModal_withoutKDVCost'
                              name='offferpricewithoutvat'
                              label="KDV'siz fiyatı"
                              value={priceWithoutKdv.toFixed(2)}
                              slotProps={{ input: { readOnly: true } }}
                              variant='outlined'
                              size='small'
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='row g-2'>
                          <div className="col-12 col-md-6">
                            <Field
                              as={TextField}
                              id='OffersAddModal_date'
                              name='date'
                              label='Teklif tarihi'
                              type="date"
                              InputLabelProps={{ shrink: true, }}
                              variant='outlined'
                              size='small'
                              fullWidth
                              error={touched.date && !!errors.date}
                              helperText={touched.date && errors.date}
                            />
                          </div>
                          <div className='col-12 col-md-6'>
                            <Field
                              as={TextField}
                              id='OffersAddModal_title'
                              name='title'
                              label='Teklifin adı'
                              variant='outlined'
                              size='small'
                              fullWidth
                              error={touched.title && !!errors.title}
                              helperText={touched.title && errors.title}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 ">
                    <div className='card bg-light  m-0'>
                      <div className='card-header  d-flex justify-content-between py-1'>
                        <div className='card-title my-1'>Ürün Bilgileri</div>
                        <button type='button' onClick={() => {
                          const maxId = values.offerDetails.length > 0 ? Math.max(...values.offerDetails.map(product => product.id)) : 0;
                          const newProduct = { id: maxId + 1, offerId: maxId + 1, type: '', quantity: 0, price: 0, total: 0 };
                          values.offerDetails = listAddItem(values.offerDetails, newProduct);
                          setFieldValue('offerDetails', values.offerDetails);
                        }} className='btn btn-outline-warning mx-2 p-1 fs-6'><IoAddCircle style={{ fontSize: '1.2em' }} />Ürün Satırı Ekle</button>
                      </div>
                      <div className='card-body' style={{ maxHeight: '240px', overflowY: 'auto' }}>
                        {values.offerDetails.map((product, index) => (
                          <div key={index} className="row g-2 my-0">
                            <div className='col-12 col-md-5'>
                              <TextField
                                id="OffersAddModal_offerDetails_type"
                                name="type"
                                label='Ürün Adı'
                                value={product.type}
                                onChange={(e) => setFieldValue(`offerDetails[${index}].type`, e.target.value)}
                                variant='outlined'
                                size='small'
                                fullWidth
                                error={touched.offerDetails?.[index]?.type && !!errors.offerDetails?.[index]?.type}
                                helperText={touched.offerDetails?.[index]?.type && errors.offerDetails?.[index]?.type}

                              />
                            </div>
                            <div className='col-12 col-md-2'>
                              <TextField
                                id="OffersAddModal_offerDetails_quantity"
                                name="quantity"
                                label='Ürün Miktarı'
                                type='number'
                                value={parseFloat(product.quantity)}
                                onChange={(e) => {
                                  const value = parseFloat(e.target.value);
                                  if (value >= 0) {
                                    setFieldValue(`offerDetails[${index}].quantity`, value);
                                    setFieldValue(`offerDetails[${index}].total`, value * values.offerDetails[index].price);
                                    const totalPrice = calculateTotalOfColumn(values.offerDetails.map(item => item.id === values.offerDetails[index].id ? { ...item, total: value * values.offerDetails[index].price } : item), 'total');
                                    setFieldValue('total', totalPrice);
                                    setFieldValue('generalTotal', calculateTotal(totalPrice, values.kdv));
                                  }
                                }}
                                variant='outlined'
                                size='small'
                                fullWidth
                                error={touched.offerDetails?.[index]?.quantity && !!errors.offerDetails?.[index]?.quantity}
                                helperText={touched.offerDetails?.[index]?.quantity && errors.offerDetails?.[index]?.quantity}
                              />
                            </div>
                            <div className='col-12 col-md-2'>
                              <TextField
                                id="OffersAddModal_offerDetails_price"
                                name="price"
                                label='Ürün Fiyatı'
                                type='number'
                                value={parseFloat(product.price)}
                                onChange={(e) => {
                                  const value = parseFloat(e.target.value);
                                  if (value >= 0) {
                                    setFieldValue(`offerDetails[${index}].price`, value);
                                    setFieldValue(`offerDetails[${index}].total`, value * values.offerDetails[index].quantity);
                                    const totalPrice = calculateTotalOfColumn(values.offerDetails.map(item => item.id === values.offerDetails[index].id ? { ...item, total: value * values.offerDetails[index].quantity } : item), 'total');
                                    setFieldValue('total', totalPrice);
                                    setFieldValue('generalTotal', calculateTotal(totalPrice, values.kdv));
                                  }
                                }}
                                variant='outlined'
                                size='small'
                                fullWidth
                                error={touched.offerDetails?.[index]?.price && !!errors.offerDetails?.[index]?.price}
                                helperText={touched.offerDetails?.[index]?.price && errors.offerDetails?.[index]?.price}
                              />
                            </div>
                            <div className='col-12 col-md-2'>
                              <TextField
                                id="OffersAddModal_offerDetails_total"
                                name="total"
                                label='Toplam Fiyat'
                                value={values.offerDetails[index].total}
                                slotProps={{ input: { readOnly: true } }}
                                variant='outlined'
                                size='small'
                                fullWidth
                              />
                            </div>
                            <div className='col-12 col-md-1'>
                              <button
                                type='button'
                                className='button-with-icon btn btn-danger w-100'
                                onClick={() => {
                                  setFieldValue("offerDetails", listDeleteItem(values.offerDetails, values.offerDetails[index].id));
                                  const totalPrice = calculateTotalOfColumn(listDeleteItem(values.offerDetails, values.offerDetails[index].id), 'total');
                                  setFieldValue('total', totalPrice);
                                  setFieldValue('generalTotal', calculateTotal(totalPrice, values.kdv));
                                }}
                              >
                                <MdDeleteOutline className='button-icon' />
                                Sil
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-12 '>
                    <div className="row g-2">
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className='card bg-light m-0'>
                          <div className='card-header py-0'>
                            <div className='card-title my-1'>Müşteri Bilgileri</div>
                          </div>
                          <div className='card-body'>
                            <div className='row g-2'>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='OffersAddModal_customerPhone1'
                                  name='customerPhone1'
                                  label='Birincil telefon numarası'
                                  value={values.customerPhone1}
                                  onChange={(e) => {
                                    const phone = stringMaxLenght(stringOnlyNumbers(e.target.value), 10);
                                    setFieldValue("customerPhone1", phone);
                                    if (phone.length === 10) { dispatch(getCustomerByPhone({ phone })) }
                                    else { setCustomerData(null) }
                                  }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  error={touched.customerPhone1 && !!errors.customerPhone1}
                                  helperText={touched.customerPhone1 && errors.customerPhone1}
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='OffersAddModal_customerPhone2'
                                  name='customerPhone2'
                                  label='İkincil telefon numarası'
                                  value={customerData !== null ? customerData.phone2 : values.customerPhone2}
                                  onChange={(e) => setFieldValue("customerPhone2", stringMaxLenght(stringOnlyNumbers(e.target.value), 10))}
                                  slotProps={{ input: { readOnly: customerData !== null } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  error={touched.customerPhone2 && !!errors.customerPhone2}
                                  helperText={touched.customerPhone2 && errors.customerPhone2}
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='OffersAddModal_customerName'
                                  name='customerName'
                                  label='Müşteri adı-soyadı'
                                  value={customerData !== null ? customerData.name : values.customerName}
                                  onChange={(e) => setFieldValue('customerName', e.target.value)}
                                  slotProps={{ input: { readOnly: customerData !== null } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  error={touched.customerName && !!errors.customerName}
                                  helperText={touched.customerName && errors.customerName}
                                />
                              </div>
                              <div className="col-12 col-md-6">
                                <Field
                                  as={Autocomplete}
                                  id="OffersAddModal_customerType"
                                  name="customerType"
                                  options={CustomerTypes}
                                  value={customerData !== null ? customerData.customerType : values.customerType}
                                  onChange={(event, newValue) => { setFieldValue('customerType', newValue); }}
                                  disabled={customerData !== null}
                                  onBlur={() => { setFieldTouched('customerType', true); }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  renderInput={(params) => <TextField {...params} label="Müşteri Türü " error={touched.customerType && !!errors.customerType} helperText={touched.customerType && errors.customerType} />}
                                />
                              </div>
                              <CustomerType errors={errors} touched={touched} values={customerData === null ? values : customerData} setFieldValue={setFieldValue} readOnly={customerData !== null} />
                              <div className="col-12 col-md-6">
                                <Field
                                  as={Autocomplete}
                                  id="OffersAddModal_city"
                                  name="city"
                                  options={cities}
                                  value={customerData !== null ? customerData.city : values.city}
                                  onChange={(event, newValue) => {
                                    setCity(newValue ? newValue : '');
                                    setFieldValue('city', newValue ? newValue : '');
                                    setFieldValue('district', '');
                                  }}
                                  disabled={customerData !== null}
                                  onBlur={() => { setFieldTouched('city', true); }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  renderInput={(params) => <TextField {...params} label="Şehir" error={touched.city && !!errors.city} helperText={touched.city && errors.city} />}
                                />
                              </div>
                              <div className="col-12 col-md-6">
                                <Field
                                  as={Autocomplete}
                                  id="OffersAddModal_district"
                                  name="district"
                                  options={districts}
                                  value={customerData !== null ? customerData.district : values.district}
                                  onChange={(event, newValue) => { if (city !== '') { setFieldValue("district", newValue); } }}
                                  disabled={city === '' || customerData !== null}
                                  onBlur={() => { setFieldTouched('district', true); }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  renderInput={(params) => <TextField {...params} label="İlçe" error={touched.district && !!errors.district} helperText={touched.district && errors.district} />}
                                />
                              </div>
                              <div className="col-12 col-md-12">
                                <Field
                                  as={TextField}
                                  id='OffersAddModal_customerAddress'
                                  name='customerAddress'
                                  label="Adres"
                                  value={customerData !== null ? customerData.address : values.customerAddress}
                                  onChange={(e) => setFieldValue('customerAddress', e.target.value)}
                                  slotProps={{ input: { style: { overflow: 'auto', resize: 'none' }, readOnly: customerData !== null } }}
                                  multiline
                                  rows={4}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  error={touched.customerAddress && !!errors.customerAddress}
                                  helperText={touched.customerAddress && errors.customerAddress}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-6">
                        <div className='card h-100 bg-light m-0'>
                          <div className='card-header py-0'>
                            <div className='card-title my-1'>Genel Bilgiler</div>
                          </div>
                          <div className='card-body'>
                            <div className='row g-2'>
                              <div className='col-12 col-md-12'>
                                <Field
                                  as={TextField}
                                  id='OffersAddModal_totalText'
                                  name='totalText'
                                  label='Toplam yazıyla'
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  error={touched.totalText && !!errors.totalText}
                                  helperText={touched.totalText && errors.totalText}
                                />
                              </div>
                              <div className="col-12 col-md-6">
                                <Field
                                  as={Autocomplete}
                                  id="OffersAddModal_status"
                                  name="status"
                                  options={OfferStatuses}
                                  value={values.status}
                                  onChange={(event, newValue) => { setFieldValue('status', newValue); }}
                                  onBlur={() => { setFieldTouched('status', true); }}
                                  variant='outlined'
                                  size='small'
                                  renderInput={(params) => <TextField {...params}
                                    label="Durum" error={touched.status && !!errors.status}
                                    helperText={touched.status && errors.status} />}
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={Autocomplete}
                                  id="OffersAddModal_kdv"
                                  name="kdv"
                                  options={['0', '0.01', '0.08', '0.18', '0.2']}
                                  value={values.kdv}
                                  onChange={(event, newValue) => {
                                    setFieldValue('kdv', newValue || '0');
                                    setPriceWithoutKdv(calculateAmountWithoutKdv(priceWithKdv, parseFloat(newValue)));
                                    setFieldValue('generalTotal', calculateTotal(values.total, parseFloat(newValue)));
                                  }}
                                  onBlur={() => { setFieldTouched('kdv', true); }}
                                  getOptionLabel={(option) => option.toString()}
                                  variant='outlined'
                                  size='small'
                                  renderInput={(params) => <TextField {...params}
                                    label="KDV" error={touched.kdv && !!errors.kdv}
                                    helperText={touched.kdv && errors.kdv} />}
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='OffersAddModal_currency'
                                  name='currency'
                                  label='Dolar Alış'
                                  value={dollar?.buying || 'Hata!'}
                                  slotProps={{ input: { readOnly: true } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  error={touched.currency && !!errors.currency}
                                  helperText={touched.currency && errors.currency}
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='OffersAddModal_currency'
                                  name='currency'
                                  label='Dolar Satış'
                                  value={dollar?.selling || 'Hata!'}
                                  slotProps={{ input: { readOnly: true } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                  error={touched.currency && !!errors.currency}
                                  helperText={touched.currency && errors.currency}
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='OffersAddModal_total'
                                  name='total'
                                  label='Ürünlerin Toplam Fiyatı'
                                  slotProps={{ input: { readOnly: true } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                />
                              </div>
                              <div className='col-12 col-md-6'>
                                <Field
                                  as={TextField}
                                  id='OffersAddModal_generalTotal'
                                  name='generalTotal'
                                  label='Genel Toplam'
                                  value={values.generalTotal.toFixed(2)}
                                  slotProps={{ input: { readOnly: true } }}
                                  variant='outlined'
                                  size='small'
                                  fullWidth
                                />
                              </div>
                              <div className="col-12 col-md-12">
                                <Field
                                  as={TextField}
                                  id='OffersAddModal_explanation'
                                  name='explanation'
                                  label="Açıklama"
                                  slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                                  multiline
                                  rows={4}
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  error={touched.explanation && !!errors.explanation}
                                  helperText={touched.explanation && errors.explanation}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                        <TbArrowBackUpDouble className='button-icon' />
                        Geri
                      </button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-success w-100'>
                        <IoAddCircle className='button-icon' />
                        Ekle
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div >
  )
}

export default OffersAddModal