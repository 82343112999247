// General
import React, { useEffect, useMemo, useState } from 'react';
// Storage
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { addNewSource, deleteSource, getAllSources, getSourceById, resetAddStatus, resetDeleteStatus, resetError, resetgetByIdStatus, resetUpdateStatus, updateSource } from '../../../storages/slices/backend/SourceSlice';
// Coxtexts 
import { useData } from '../../../contexts/DataContext';
// Icons
import { SiOpensourcehardware } from "react-icons/si";
import { TbPrinter } from "react-icons/tb";
// Modals
import SourceAddModal from '../modals/sources/SourceAddModal';
import SourceUpdateModal from '../modals/sources/SourceUpdateModal';
import DeleteModal from "../modals/DeleteModal";
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Sources() {
  // Storage Import
  const dispatch = useDispatch();
  const { sources, source, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.source);
  // Context Import
  const { myUser } = useData();
  // Variables
  const [canfetchData, setCanFetchData] = useState(false);
  // Data & Api
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllSources({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseSourceAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllSources({}));
      dispatch(addToast({ background: 'success', icon: 'userAdd', message: 'Yeni Kaynak Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseSourceUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllSources({}));
      dispatch(addToast({ background: 'primary', icon: 'userUpdate', message: 'Kaynak Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseSourceDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllSources({}));
      dispatch(addToast({ background: 'danger', icon: 'userDelete', message: 'Kaynak Silindi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataForSourceUpdateModal(source);
        setShowSourceUpdateModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataForSourceDeleteModal({ id: source.id, name: source.name });
        setShowSourceDeleteModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus, dispatch])
  useEffect(() => {
    if (error && error !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'KAYNAK' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'name', 'status'];
  const select = (id) => { handleShowSourceUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <SiOpensourcehardware className='button-icon' />,
      onclick: ((rowData) => { handleShowSourceUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <SiOpensourcehardware className='button-icon' />,
      onclick: ((rowData) => { handleShowSourceDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={sources}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    }
    else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint - disable - next - line react - hooks / exhaustive - deps
  }, [getAllStatus, sources]);
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowSourceAddModal(); }}>
          <SiOpensourcehardware className='button-icon' />
          Yeni Kaynak Ekle
        </button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: sources, cols: cols, pdftitle: "Kaynaklar" })) }}>
          <TbPrinter className='button-icon' />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Source Add Modal
  const [showSourceAddModal, setShowSourceAddModal] = useState(false);
  const handleCloseSourceAddModal = () => { setShowSourceAddModal(false) };
  const handleShowSourceAddModal = () => { setShowSourceAddModal(true) };
  const handleAddSourceAddModal = (values) => {
    dispatch(addNewSource({ newsource: values }));
    handleCloseSourceAddModal();
  }
  // Source Update Modal
  const [showSourceUpdateModal, setShowSourceUpdateModal] = useState(false);
  const [inputDataForSourceUpdateModal, setInputDataForSourceUpdateModal] = useState({});
  const handleCloseSourceUpdateModal = () => { setShowSourceUpdateModal(false); setInputDataForSourceUpdateModal({}); }
  const handleShowSourceUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getSourceById({ id: id }));
  }
  const handleUpdateSourceUpdateModal = (values) => {
    dispatch(updateSource({ source: values }));
    handleCloseSourceUpdateModal();
  }
  // Source Delete Modal
  const [showSourceDeleteModal, setShowSourceDeleteModal] = useState(false);
  const [inputDataForSourceDeleteModal, setInputDataForSourceDeleteModal] = useState({ id: -1, name: '' });
  const handleCloseSourceDeleteModal = () => { setShowSourceDeleteModal(false); setInputDataForSourceDeleteModal(-1); }
  const handleShowSourceDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getSourceById({ id: id }));
  }
  const handleDeleteSourceDeleteModal = () => {
    dispatch(deleteSource({ id: inputDataForSourceDeleteModal.id }));
    handleCloseSourceDeleteModal();
  }
  // Return Modals
  const modals = () => {
    return (
      <>
        <SourceAddModal
          show={showSourceAddModal}
          handleClose={handleCloseSourceAddModal}
          handleAdd={handleAddSourceAddModal}
        />
        <SourceUpdateModal
          show={showSourceUpdateModal}
          handleClose={handleCloseSourceUpdateModal}
          handleUpdate={handleUpdateSourceUpdateModal}
          inputData={inputDataForSourceUpdateModal}
        />
        <DeleteModal
          show={showSourceDeleteModal}
          handleClose={handleCloseSourceDeleteModal}
          handleDelete={handleDeleteSourceDeleteModal}
          title="Kaynağ'ı Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForSourceDeleteModal.name} adlı kaynağı silmek istediğinize emin misiniz?`}
          deleteIcon={<SiOpensourcehardware className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage
      title='Kaynaklar'
      Operations={operations}
      Table={() => tableContent}
      Modals={modals}
    />
  );
}

export default Sources