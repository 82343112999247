// General
import React from 'react';
// Styles
import Toast from 'react-bootstrap/Toast';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { removeToast } from '../../../storages/slices/ToastSlice';
// Contexts
import { useData } from '../../../contexts/DataContext';

function ToastMessages() {
  // Storage Import
  const dispatch = useDispatch();
  const { toasts } = useSelector((state) => state.toast);
  // Context Import
  const { toastIconTranslator } = useData();
  // HTML
  return (
    <div className="position-fixed top-0 end-0 me-2" style={{ zIndex: 9999 }}>
      {toasts.map((toast) => (
        toast.show && (
          <Toast key={toast.id} show={toast.show} delay={toast.delay} autohide={true} bg={toast.background} className="mt-2" onClose={() => dispatch(removeToast(toast.id))}>
            <Toast.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center text-white">
                  {toast.icon && toastIconTranslator[toast.icon]}
                  <h5 className="ms-2 p-0 m-0">{toast.message}</h5>
                </div>
                <div>
                  <button type="button" className="btn-close btn-close-white ms-auto" aria-label="Close" onClick={() => dispatch(removeToast(toast.id))} />
                </div>
              </div>
            </Toast.Body>
          </Toast>
        )))}
    </div>
  );
}

export default ToastMessages;