// General
import React, { useEffect, useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, IconButton, InputAdornment, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { getDistricts } from '../../../../storages/slices/DistrictSlice';
import { getAllRoles } from '../../../../storages/slices/backend/RoleSlice';
// Context
import { useValidations } from '../../../../contexts/ValidationContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
import { useData } from '../../../../contexts/DataContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { RiUserSettingsFill } from "react-icons/ri";
import { IoEye, IoEyeOff } from "react-icons/io5";

function EmployeesUpdateModal({ show, handleClose, handleUpdate, inputData }) {
  // Storage Import
  const dispatch = useDispatch();
  const { districts } = useSelector((state) => state.district);
  const { roles } = useSelector((state) => state.role);
  // Context Import
  const { validationSchema_EmployeeUpdate } = useValidations();
  const { stringMaxLenght, stringOnlyNumbers } = useFunctions();
  const { today, cities, UserStatuses } = useData();
  // Data & Api
  const [city, setCity] = useState('İstanbul');
  const fetchAllDataSequentially = async (dispatch, signal, selectedCity) => {
    try {
      await dispatch(getDistricts({ signal, city: selectedCity, }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (show && city) { fetchAllDataSequentially(dispatch, signal, city); }
    return () => { controller.abort(); };
  }, [city, show, dispatch]);
  // Password Variables
  const [showPasswordPersonal, setShowPasswordPersonal] = useState(false);
  const handleClickShowPasswordPersonal = () => {
    setShowPasswordPersonal(!showPasswordPersonal);
  };
  // Formik Variables
  const initialValues = {
    id: inputData.id || 0,
    date: inputData.date || today,
    roleName: inputData.roleName || '',
    name: inputData.name || '',
    username: inputData.username || '',
    password: '',
    phone1: inputData.phone1 || '',
    phone2: inputData.phone2 || '',
    prim: inputData.prim || '',
    status: inputData.status || '',
    city: inputData.city || 'İstanbul',
    district: inputData.district || '',
    address: inputData.address || ''
  }
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header closeButton>
          <Modal.Title> Personel Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik validationSchema={validationSchema_EmployeeUpdate} initialValues={initialValues} onSubmit={(values) => handleUpdate(values)}>
            {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='EmployeesAddModal_date'
                      name='date'
                      label='İşe giriş tarihi *'
                      type="date"
                      value={values.date}
                      onChange={(e) => { setFieldValue('date', e.target.value); }}
                      InputLabelProps={{ shrink: true }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.date && !!errors.date}
                      helperText={touched.date && errors.date}
                    />
                  </div>
                  <div className='col-12 col-md-12'>
                    <Field
                      as={Autocomplete}
                      id="EmployeesAddModal_roleName"
                      name="roleName"
                      options={roles.map(item => item.name)}
                      value={values.roleName}
                      onChange={(event, newValue) => { setFieldValue('roleName', newValue); }}
                      onBlur={() => { setFieldTouched('roleName', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Rol *" error={touched.roleName && !!errors.roleName} helperText={touched.roleName && errors.roleName} />}
                    />
                  </div>
                  <div className="col-12 col-md-12 ">
                    <Field
                      as={TextField}
                      id='EmployeesAddModal_name'
                      name='name'
                      label='İsim *'
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                    />
                  </div>
                  <div className="col-12 col-md-6 ">
                    <Field
                      as={TextField}
                      id='EmployeesAddModal_username'
                      name='username'
                      label='Kullanıcı Adı *'
                      autoComplete="off"
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.username && !!errors.username}
                      helperText={touched.username && errors.username}
                    />
                  </div>
                  <div className="col-12 col-md-6 ">
                    <Field
                      as={TextField}
                      id='EmployeesAddModal_password'
                      name='password'
                      label='Şifre *'
                      type={showPasswordPersonal ? 'text' : 'password'}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton aria-label="toggle password visibility" onClick={handleClickShowPasswordPersonal} edge="end">
                                {showPasswordPersonal ? <IoEye /> : <IoEyeOff />}
                              </IconButton>
                            </InputAdornment>)
                        }
                      }}
                      autoComplete="new-password"
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={TextField}
                      id='EmployeesAddModal_phone1'
                      name='phone1'
                      label='Birincil Telefon Numarası *'
                      value={values.phone1}
                      onChange={(e) => setFieldValue("phone1", stringMaxLenght(stringOnlyNumbers(e.target.value), 10))}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.phone1 && !!errors.phone1}
                      helperText={touched.phone1 && errors.phone1}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={TextField}
                      id='EmployeesAddModal_phone2'
                      name='phone2'
                      label='İkincil Telefon Numarası'
                      value={values.phone2}
                      onChange={(e) => setFieldValue("phone2", stringMaxLenght(stringOnlyNumbers(e.target.value), 10))}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.phone2 && !!errors.phone2}
                      helperText={touched.phone2 && errors.phone2}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={TextField}
                      id='EmployeesAddModal_prim'
                      name='prim'
                      label="Prim (%) *"
                      type="number"
                      value={values.prim}
                      onChange={(e) => setFieldValue("prim", stringMaxLenght(stringOnlyNumbers(e.target.value), 3))}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.prim && !!errors.prim}
                      helperText={touched.prim && errors.prim}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={Autocomplete}
                      id="EmployeesAddModal_status"
                      name="status"
                      options={UserStatuses}
                      value={values.status}
                      onChange={(event, newValue) => { setFieldValue('status', newValue); }}
                      onBlur={() => { setFieldTouched('status', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Çalışan Türü *" error={touched.status && !!errors.status} helperText={touched.status && errors.status} />}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={Autocomplete}
                      id="EmployeesAddModal_city"
                      name="city"
                      options={cities}
                      value={values.city}
                      onChange={(event, newValue) => {
                        setCity(newValue ? newValue : '');
                        setFieldValue('city', newValue ? newValue : '');
                        setFieldValue('district', '');
                      }}
                      onBlur={() => { setFieldTouched('city', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Şehir *" error={touched.city && !!errors.city} helperText={touched.city && errors.city} />}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={Autocomplete}
                      id="EmployeesAddModal_district"
                      name="district"
                      options={districts}
                      value={values.district}
                      onChange={(event, newValue) => { if (city !== '') { setFieldValue("district", newValue); } }}
                      onBlur={() => { setFieldTouched('district', true); }}
                      disabled={city === ''}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="İlçe *" error={touched.district && !!errors.district} helperText={touched.district && errors.district} />}
                    />
                  </div>
                  <div className="col-12 col-md-12 mt-3">
                    <Field
                      as={TextField}
                      id='EmployeesAddModal_address'
                      name='address'
                      label="Adres *"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, } }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={4}
                      error={touched.address && !!errors.address}
                      helperText={touched.address && errors.address}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                        <TbArrowBackUpDouble className='button-icon' />
                        Geri
                      </button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-primary w-100' >
                        <RiUserSettingsFill className='button-icon' />
                        Güncelle
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  )
}

export default EmployeesUpdateModal