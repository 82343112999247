// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/CaseActions/';

// Async Thunks
export const getAllCheckouts = createAsyncThunk('backend_getallchekouts', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllCaseActions`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getCheckoutById = createAsyncThunk('backend_getcheckoutbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetCaseActionById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const getAllCheckoutsByDate = createAsyncThunk('backend_getallcheckoutsbydate', async ({ signal = null, start, end }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllCaseActionsByDate?start=${start}&end=${end}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewCheckout = createAsyncThunk('backend_addnewcheckout', async ({ newcheckout }) => {
  try {
    const res = await axios.post(`${baseurl}AddCaseAction`, newcheckout, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateCheckout = createAsyncThunk('backend_updatecheckout', async ({ checkout }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateCaseAction`, checkout, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteCheckout = createAsyncThunk('backend_deletecheckout', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteCaseAction?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    checkouts: [],
    checkout: {},
    getAllStatus: null,
    getByIdStatus: null,
    getAllStatusByDate: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetgetByIdStatus: (state) => { state.getByIdStatus = null; },
    resetGetAllStatusByDate: (state) => { state.getAllStatusByDate = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCheckouts.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllCheckouts.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.checkouts = action.payload || []; })
      .addCase(getAllCheckouts.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getCheckoutById.pending, (state) => { state.getByIdStatus = "pending"; })
      .addCase(getCheckoutById.fulfilled, (state, action) => { state.getByIdStatus = "fulfilled"; state.checkout = action.payload || {}; })
      .addCase(getCheckoutById.rejected, (state, action) => { state.getByIdStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllCheckoutsByDate.pending, (state) => { state.getAllStatusByDate = "pending"; })
      .addCase(getAllCheckoutsByDate.fulfilled, (state, action) => { state.getAllStatusByDate = "fulfilled"; state.checkouts = action.payload || []; })
      .addCase(getAllCheckoutsByDate.rejected, (state, action) => { state.getAllStatusByDate = "rejected"; state.error = action.error.message; })
      .addCase(addNewCheckout.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewCheckout.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewCheckout.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateCheckout.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateCheckout.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateCheckout.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteCheckout.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteCheckout.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteCheckout.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
})

export const { resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetgetByIdStatus, resetGetAllStatusByDate, resetError } = checkoutSlice.actions;
export default checkoutSlice.reducer;