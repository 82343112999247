// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Context
import { useValidations } from '../../../../contexts/ValidationContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { IoAddCircle } from "react-icons/io5";

function VehicleAddModal({ show, handleClose, handleAdd }) {
  // Context Import
  const { validationSchema_Vehicles } = useValidations();
  const { stringUpperCase } = useFunctions();
  // Formik Variables
  const initialValues = {
    plate: '',
    description: '',
  }
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header closeButton>
          <Modal.Title>Yeni Araç Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik validationSchema={validationSchema_Vehicles} initialValues={initialValues} onSubmit={(values) => handleAdd(values)}>
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='VehicleAddModal_plate'
                      name='plate'
                      label='Araç Plakası'
                      values={values.plate}
                      onChange={(e) => setFieldValue('plate', stringUpperCase(e.target.value))}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.plate && !!errors.plate}
                      helperText={touched.plate && errors.plate}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='VehicleAddModal_description'
                      name='description'
                      label="Açıklama"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none' } } }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={4}
                      error={touched.description && !!errors.description}
                      helperText={touched.description && errors.description}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-success w-100' ><IoAddCircle className='button-icon' />Araç Ekle</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  );
}

export default VehicleAddModal;