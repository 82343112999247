// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
// Icons
import { TbArrowBackUpDouble } from 'react-icons/tb';
// Components
import Table from '../../components/Table';


function CustomerDetailsModal({ show, handleClose, inputData }) {
  // Data & Api
  const data = [
    { id: "1", date: '10.05.2024', device: 'telefon 1', }, { id: "1", date: '10.05.2024', device: 'telefon 2', }, { id: "1", date: '10.05.2024', device: 'telefon 3', }];
  // Table Content
  const cols = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'device', name: 'CİHAZ' },];
  const rowsPerPage = 5;
  return (
    <Modal show={show} onHide={handleClose} centered className='modal'>
      <Modal.Header closeButton >
        <Modal.Title>{inputData.id} numaralı müşterinin eski servisleri </Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-0 pt-0'>
        <div className='row m-0 w-100 justify-content-center pt-4'>
          <div className=' col-md-12  '>
            <Table
              data={data}
              rows={rowsPerPage}
              columns={cols}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='px-0'>
        <div className='row m-0 d-flex justify-content-end align-items-center w-100'>
          <div className='col-12 col-md-5'>
            <button className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomerDetailsModal