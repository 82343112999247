// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


const baseurl = 'https://tservis-api.tuyak.com.tr/api/Offers/';

// Async Thunks
export const getAllOffers = createAsyncThunk('backend_getalloffers', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllOffers`, { signal, withCredentials: true });
    return res.data.data.map(item => ({ ...item, date: item.date.slice(0, 10) }));
  } catch (error) { throw error?.response?.data || error; }
});
export const getOfferById = createAsyncThunk('backend_getofferbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetOfferById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data; }
});
export const getOfferPDFById = createAsyncThunk('backend_getofferpdfbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}CreatePdf?id=${id}`, { withCredentials: true, responseType: 'blob', });
    const pdfUrl = URL.createObjectURL(res.data);
    window.open(pdfUrl, "_blank");
    URL.revokeObjectURL(pdfUrl);
  } catch (error) { throw JSON.parse(await error.response.data.text()).message || "Beklenmeyen bir hata oluştu"; }
});
export const getAllOffersByDate = createAsyncThunk('backend_getalloffersbydate', async ({ signal = null, start, end }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllOffersByDate?start=${start}&end=${end}`, { signal, withCredentials: true });
    return res.data.data.map(item => ({ ...item, date: item.date.slice(0, 10) }));
  } catch (error) { throw error.response.data; }
});
export const addNewOffer = createAsyncThunk('backend_addnewoffer', async ({ newoffer }) => {
  try {
    const res = await axios.post(`${baseurl}CreateOffer`, newoffer, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data; }
});
export const updateOffer = createAsyncThunk('backend_updateoffer', async ({ offer }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateOffer`, offer, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data; }
});
export const deleteOffer = createAsyncThunk('backend_deleteoffer', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteOffer?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data; }
});

// Slice
const offerSlice = createSlice({
  name: 'offer',
  initialState: {
    offers: [],
    offer: {},
    getAllStatus: null,
    getByIdStatus: null,
    getAllStatusByDate: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null,
    pdfLoading: false,
  },
  reducers: {
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetgetByIdStatus: (state) => { state.getByIdStatus = null; },
    resetGetAllStatusByDate: (state) => { state.getAllStatusByDate = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOffers.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllOffers.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.offers = action.payload || []; })
      .addCase(getAllOffers.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getOfferById.pending, (state) => { state.getByIdStatus = "pending"; })
      .addCase(getOfferById.fulfilled, (state, action) => { state.getByIdStatus = "fulfilled"; state.offer = action.payload || {}; })
      .addCase(getOfferById.rejected, (state, action) => { state.getByIdStatus = "rejected"; state.error = action.error.message; })
      .addCase(getOfferPDFById.pending, (state) => { state.pdfLoading = true; })
      .addCase(getOfferPDFById.fulfilled, (state) => { state.pdfLoading = false; })
      .addCase(getOfferPDFById.rejected, (state, action) => { state.pdfLoading = false; state.error = action.error.message; })
      .addCase(getAllOffersByDate.pending, (state) => { state.getAllStatusByDate = "pending"; })
      .addCase(getAllOffersByDate.fulfilled, (state, action) => { state.getAllStatusByDate = "fulfilled"; state.offers = action.payload || []; })
      .addCase(getAllOffersByDate.rejected, (state, action) => { state.getAllStatusByDate = "rejected"; state.error = action.error.message; })
      .addCase(addNewOffer.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewOffer.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewOffer.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateOffer.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateOffer.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateOffer.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteOffer.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteOffer.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteOffer.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
})

export const { resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetgetByIdStatus, resetGetAllStatusByDate, resetError } = offerSlice.actions;
export default offerSlice.reducer;