// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { TextField, Autocomplete } from '@mui/material';
// Formik & Yup
import { Formik, Field, Form } from 'formik';
// Context
import { useData } from '../../../../contexts/DataContext';
import { useValidations } from '../../../../contexts/ValidationContext';
// Icons
import { FaSave } from "react-icons/fa";
import { TbArrowBackUpDouble } from "react-icons/tb";

function ServiceIncomingCallsModal({ show, handleClose, handleSave }) {
  // Context Import
  const { sources } = useData();
  const { validationSchema_ServiceIncomingCalls } = useValidations();
  // Formik Variables
  const initialValues = {
    source: '',
    brand: '',
    tel: '',
    desc: ''
  }
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Gelen Çağrı</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={initialValues} validationSchema={validationSchema_ServiceIncomingCalls} onSubmit={(values) => handleSave(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <Field
                    as={Autocomplete}
                    id="source"
                    name="source"
                    value={values.source}
                    onChange={(event, newValue) => { setFieldValue('source', newValue); }}
                    onBlur={() => { setFieldTouched('source', true); }}
                    variant='outlined'
                    size='small'
                    options={sources}
                    renderInput={(params) => <TextField {...params} label="Kaynak" error={touched.source && !!errors.source} helperText={touched.source && errors.source} />}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={Autocomplete}
                    id="brand"
                    name="brand"
                    value={values.brand}
                    onChange={(event, newValue) => { setFieldValue('brand', newValue); }}
                    onBlur={() => { setFieldTouched('brand', true); }}
                    variant='outlined'
                    size='small'
                    options={[]}
                    renderInput={(params) => <TextField {...params} label="Marka" error={touched.brand && !!errors.brand} helperText={touched.brand && errors.brand} />}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id="tel"
                    name="tel"
                    label="Telefon"
                    variant="outlined"
                    size="small"
                    slotProps={{ input: { readOnly: true } }}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id="desc"
                    name="desc"
                    label="Açıklama"
                    variant="outlined"
                    size="small"
                    slotProps={{ input: { resize: "none" } }}
                    multiline
                    minRows={2}
                    fullWidth
                    error={touched.desc && !!errors.desc}
                    helperText={touched.desc && errors.desc}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
                </div>
                <div className='col-12 col-md-6'>
                  <button type='submit' className='button-with-icon btn btn-success w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default ServiceIncomingCallsModal;