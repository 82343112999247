// General
import React, { useMemo, useState } from 'react';
// Storages
import { useDispatch } from 'react-redux';
import { exportPdf } from '../../../storages/slices/PrintSlice';
// Icons
import { IoAddCircle, IoCheckmarkCircle } from "react-icons/io5";
import { TbPrinter } from "react-icons/tb";
// Modals
import StockActionAddModal from '../modals/stockactions/StockActionAddModal';
import StockActionUpdateModal from '../modals/stockactions/StockActionUpdateModal';
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function StockActions() {
  // Storage Import
  const dispatch = useDispatch();
  const getAllStatus = 'fulfilled';
  // Data & Api
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'AKSİYON' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'name', 'description'];
  const select = (id) => { }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <IoCheckmarkCircle className='button-icon' />,
      onclick: ((rowData) => { handleShowStockActionUpdateModal(-1); }),
      variant: 'primary'
    }
  ];
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={[]}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowStockActionAddModal(); }}>
          <IoAddCircle className='button-icon' />
          Yeni Hareket Ekle
        </button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: [], cols: cols, pdftitle: "Markalar" })) }}>
          <TbPrinter className='button-icon' />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Brand Add Modal
  const [showStockActionAddModal, setShowStockActionAddModal] = useState(false);
  const handleCloseStockActionAddModal = () => { setShowStockActionAddModal(false) };
  const handleShowStockActionAddModal = () => { setShowStockActionAddModal(true) };
  const handleAddStockActionAddModal = (values) => { }
  // Brand Update Modal
  const [showStockActionUpdateModal, setShowStockActionUpdateModal] = useState(false);
  const [inputDataForStockActionUpdateModal, setInputDataForStockActionUpdateModal] = useState({});
  const handleCloseStockActionUpdateModal = () => { setShowStockActionUpdateModal(false); setInputDataForStockActionUpdateModal({}); }
  const handleShowStockActionUpdateModal = (id) => { }
  const handleUpdateStockActionUpdateModal = (values) => { }
  // Return Modals
  const modals = () => {
    return (
      <>
        <StockActionAddModal
          show={showStockActionAddModal}
          handleClose={handleCloseStockActionAddModal}
          handleAdd={handleAddStockActionAddModal}
        />
        <StockActionUpdateModal
          show={showStockActionUpdateModal}
          handleClose={handleCloseStockActionUpdateModal}
          handleUpdate={handleUpdateStockActionUpdateModal}
          inputData={inputDataForStockActionUpdateModal}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage
      title='Stok Haraketleri'
      Operations={operations}
      Table={() => tableContent}
      Modals={modals}
    />
  );
}

export default StockActions;