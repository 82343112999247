// General
import React, { useEffect, useMemo, useState } from 'react';
// Storage
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { addOperationReport, deleteOperationReport, getAllOperationReports, getOperationReportById, resetAddStatus, resetDeleteStatus, resetError, resetgetByIdStatus, resetUpdateStatus, updateOperationReport } from '../../../storages/slices/backend/OperationReports';
import { getAllServices } from '../../../storages/slices/backend/ServiceSlice';
// Context
import { useData } from '../../../contexts/DataContext';
import { useFunctions } from '../../../contexts/FunctionsContext';
// Icons
import { TbPrinter } from "react-icons/tb";
import { TiDocumentAdd } from "react-icons/ti";
import { RiFolderSettingsLine } from "react-icons/ri";
import { FiFolderMinus } from "react-icons/fi";
//Modals
import OperationReportsAddModal from '../modals/operationreports/OperationReportsAddModal';
import DeleteModal from '../modals/DeleteModal';
import OperationReportsUpdateModal from '../modals/operationreports/OperationReportsUpdateModal';
// Components
import StandartTablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function OperationReports() {
  // Storages
  const dispatch = useDispatch();
  const { operationReports, operationReport, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.operationReport);
  // Context Import
  const { today, lastWeekDate, myUser } = useData();
  const { stringMoney, stringDate } = useFunctions();
  // Variables
  const [canfetchData, setCanFetchData] = useState(false);
  // Filtering
  const [startdate, setStartdate] = useState(lastWeekDate);
  const [enddate, setEnddate] = useState(today);
  const dates = (startDate, endDate) => { setStartdate(startDate); setEnddate(endDate); }
  // Data & Api
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPrint, setDataToPrint] = useState([]);
  useEffect(() => {
    if (getAllStatus === "fulfilled") {
      const data = operationReports.map((item) => {
        const id = item.id;
        const date = stringDate(item.date);
        const name = item.name;
        const phone1 = item.phone1;
        const address1 = item.address
        const address2 = item.district + '/' + item.city;
        const device = item.device;
        const brand = item.brand;
        const total = stringMoney(item.total, 'TL');
        const fault = item.fault;
        const customerSearch = `${name} - ${phone1}\n${address1}\n${address2}`;
        const deviceInfo = brand + '/' + device;
        const customerInfo = (
          <div className='d-flex flex-column justify-content-start align-items-start'>
            <p className='m-0 p-0'>{name} - {phone1} </p>
            <p className='m-0 p-0'>{address1}</p>
            <p className='m-0 p-0'>{address2}</p>
          </div>
        )
        return { id: id, date: date, name: name, phone1: phone1, address1: address1, address2: address2, customerInfo: customerInfo, customerSearch: customerSearch, brand: brand, total: total, device: device, deviceInfo: deviceInfo, fault: fault };
      });
      setDataToTable(data);
      setDataToPrint(data.map(item => ({ id: item.id, date: item.date, customerInfo: item.customerSearch, deviceInfo: item.deviceInfo, fault: item.fault, total: item.total })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startdate, enddate, getAllStatus, OperationReports]);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllOperationReports({ signal }));
      await dispatch(getAllServices({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseOperationReportsAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllOperationReports({}));
      dispatch(addToast({ background: 'success', icon: 'userAdd', message: 'Yeni İşlem Raporu Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseOperationReportsUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllOperationReports({}));
      dispatch(addToast({ background: 'primary', icon: 'userUpdate', message: ' İşlem Raporu Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseOperationReportsDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllOperationReports({}));
      dispatch(addToast({ background: 'danger', icon: 'userDelete', message: ' İşlem Raporu Silindi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataForOperationReportsUpdateModal(operationReport);
        setShowOperationReportsUpdateModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataForOperationReportsDeleteModal({ id: operationReport.id, name: operationReport.name });
        setShowOperationReportsDeleteModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus, dispatch])
  useEffect(() => {
    if (error && error !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'customerInfo', name: 'MÜŞTERİ BİLGİLERİ' }, { value: 'deviceInfo', name: 'MARKA BİLGİLERİ' }, { value: 'fault', name: 'ARIZA' }, { value: 'total', name: 'GENEL TOPLAM' },];
  const filters = ['id', 'date', 'customerSearch', 'deviceInfo', 'fault', 'total'];
  const select = (id) => { handleShowOperationReportsUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <RiFolderSettingsLine className='button-icon' />,
      onclick: ((rowData) => { handleShowOperationReportsUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <FiFolderMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowOperationReportsDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={dataToTable}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          setDates={{ start: startdate, end: enddate }}
          dates={dates}
          buttons={buttons}
          bodyAlignBroker={['customerInfo']}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus, dataToTable]);
  // Local Functions
  const operations = () => {
    return (
      <>
        <button onClick={(e) => { e.stopPropagation(); handleShowOperationReportsAddModal(); }} className="dropdown-item"><TiDocumentAdd style={{ fontSize: '1.5em' }} /> İşlem Raporu Ekle</button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPrint, cols: cols, pdftitle: "İşlem Raporları" })) }}>
          <TbPrinter style={{ fontSize: '1.5em' }} />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // OperationReports Add Modal
  const [showOperationReportsAddModal, setShowOperationReportsAddModal] = useState(false);
  const handleCloseOperationReportsAddModal = () => { setShowOperationReportsAddModal(false) };
  const handleShowOperationReportsAddModal = () => { setShowOperationReportsAddModal(true) };
  const handleAddOperationReportsAddModal = (values) => {
    dispatch(addOperationReport({ newtransactionreport: { ...values, serviceId: values.serviceId?.id } }));
    handleCloseOperationReportsAddModal();
  };
  // OperationReports Update Modal
  const [showOperationReportsUpdateModal, setShowOperationReportsUpdateModal] = useState(false);
  const [inputDataForOperationReportsUpdateModal, setInputDataForOperationReportsUpdateModal] = useState({});
  const handleCloseOperationReportsUpdateModal = () => {
    setShowOperationReportsUpdateModal(false)
    setInputDataForOperationReportsUpdateModal({});
  };
  const handleShowOperationReportsUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getOperationReportById({ id: id }));
  };
  const handleUpdateOperationReportsUpdateModal = (values) => {
    dispatch(updateOperationReport({ transactionreport: values }));
    handleCloseOperationReportsUpdateModal();
  };
  // OperationReports Delete Modal
  const [showOperationReportsDeleteModal, setShowOperationReportsDeleteModal] = useState(false);
  const [inputDataForOperationReportsDeleteModal, setInputDataForOperationReportsDeleteModal] = useState({ id: -1, name: '' });
  const handleCloseOperationReportsDeleteModal = () => {
    setShowOperationReportsDeleteModal(false)
    setInputDataForOperationReportsDeleteModal(-1);
  };
  const handleShowOperationReportsDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getOperationReportById({ id: id }));
  };
  const handleDeleteOperationReportsDeleteModal = () => {
    dispatch(deleteOperationReport({ id: inputDataForOperationReportsDeleteModal.id }));
    handleCloseOperationReportsDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <OperationReportsAddModal
          show={showOperationReportsAddModal}
          handleClose={handleCloseOperationReportsAddModal}
          handleAdd={handleAddOperationReportsAddModal}>
        </OperationReportsAddModal>
        <OperationReportsUpdateModal
          show={showOperationReportsUpdateModal}
          handleClose={handleCloseOperationReportsUpdateModal}
          handleUpdate={handleUpdateOperationReportsUpdateModal}
          inputData={inputDataForOperationReportsUpdateModal}>
        </OperationReportsUpdateModal>
        <DeleteModal
          show={showOperationReportsDeleteModal}
          handleClose={handleCloseOperationReportsDeleteModal}
          handleDelete={handleDeleteOperationReportsDeleteModal}
          title="İşlem raporunu silme istediğinize emin misiniz ? "
          message={`${inputDataForOperationReportsDeleteModal.name} adlı müşteriye ait işlem raporunu silmek istediğinize emin misiniz?`}
          deleteIcon={<RiFolderSettingsLine className='button-icon' />}
        >
        </DeleteModal>
      </>
    );
  }
  // HTML
  return (
    <StandartTablePage title='İşlem Raporları' Operations={operations} Table={() => tableContent} Modals={modals} />
  );
}

export default OperationReports;