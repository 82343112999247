// General
import React from 'react';
// Image
import ErrorImage from '../../../assets/images/svg/404-error.svg';
import { NavLink } from 'react-router-dom';

function Error404() {
  // HTML
  return (
    <div class="maintenance-pages">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="text-center">
              <div class="maintenance-img">
                <img src={ErrorImage} class="img-fluid" alt="coming-soon" />
              </div>
              <div class="text-center">
                <h3 class="mt-5 fw-semibold text-black text-capitalize">Hay aksi! Aradığınız sayfayı bulamadık.</h3>
              </div>
              <NavLink to="/anasayfa">Ana Sayfa'ya dön</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error404