// General
import React, { useEffect, useMemo, useState } from 'react';
// Storage
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { addNewSupplier, deleteSupplier, getAllSuppliers, getSupplierById, resetAddStatus, resetDeleteStatus, resetError, resetgetByIdStatus, resetUpdateStatus, updateSupplier } from '../../../storages/slices/backend/SupplierSlice';
// Context
import { useData } from '../../../contexts/DataContext';
// Icons
import { RiUserAddLine, RiUserSettingsFill, RiUserStarFill, RiUserUnfollowFill } from "react-icons/ri";
import { TbPrinter } from "react-icons/tb";
// Modals
import SupplierAddModal from "../modals/suppliers/SupplierAddModal";
import DeleteModal from '../modals/DeleteModal';
import SupplierUpdateModal from "../modals/suppliers/SupplierUpdateModal";
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';


function Suppliers() {
  // Storages
  const dispatch = useDispatch();
  const { suppliers, supplier, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.supplier);
  // Context Import
  const { myUser, statusTranslator } = useData();
  // Variables
  const [canfetchData, setCanFetchData] = useState(false);
  // Data & Api
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllSuppliers({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseSupplierAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllSuppliers({}));
      dispatch(addToast({ background: 'success', icon: 'userAdd', message: 'Yeni Tedarikçi Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseSupplierUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllSuppliers({}));
      dispatch(addToast({ background: 'primary', icon: 'userUpdate', message: 'Tedarikçi Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseSupplierDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllSuppliers({}));
      dispatch(addToast({ background: 'danger', icon: 'userDelete', message: 'Tedarikçi Silindi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataForSupplierUpdateModal(supplier);
        setShowSupplierUpdateModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataForSupplierDeleteModal({ id: supplier.id, name: supplier.name });
        setShowSupplierDeleteModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus, dispatch])
  useEffect(() => {
    if (error && error !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'TEDARİKÇİ' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'name', 'description'];
  const select = (id) => {
    handleShowSupplierUpdateModal(id);
  }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <RiUserSettingsFill className='button-icon' />,
      onclick: ((rowData) => { handleShowSupplierUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Detay',
      icon: <RiUserStarFill className='button-icon' />,
      onclick: ((rowData) => { }),
      variant: 'warning'
    },
    {
      text: 'Sil',
      icon: <RiUserUnfollowFill className='button-icon' />,
      onclick: ((rowData) => { handleShowSupplierDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={suppliers}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowSupplierAddModal(); }}><RiUserAddLine style={{ fontSize: '1.5em' }} /> Yeni Tedarikçi Ekle</button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: suppliers, cols: cols, pdftitle: "Tedarikçiler" })) }}>
          <TbPrinter style={{ fontSize: '1.5em' }} />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Supplier Add Modal
  const [showSupplierAddModal, setShowSupplierAddModal] = useState(false);
  const handleCloseSupplierAddModal = () => { setShowSupplierAddModal(false) };
  const handleShowSupplierAddModal = () => { setShowSupplierAddModal(true) };
  const handleAddSupplierAddModal = (values) => {
    dispatch(addNewSupplier({ newsupplier: { ...values, status: statusTranslator[values.status] } }));
    handleCloseSupplierAddModal();
  };
  // Supplier Update Modal
  const [showSupplierUpdateModal, setShowSupplierUpdateModal] = useState(false);
  const [inputDataForSupplierUpdateModal, setInputDataForSupplierUpdateModal] = useState({});
  const handleCloseSupplierUpdateModal = () => {
    setShowSupplierUpdateModal(false)
    setInputDataForSupplierUpdateModal({});
  };
  const handleShowSupplierUpdateModal = (id) => {
    dispatch(getSupplierById({ id: id }));
    setDataRequester('update');
  };
  const handleUpdateSupplierUpdateModal = (values) => {
    dispatch(updateSupplier({ supplier: { ...values, status: statusTranslator[values.status] } }));
    handleCloseSupplierUpdateModal();
  };
  // Supplier Delete Modal
  const [showSupplierDeleteModal, setShowSupplierDeleteModal] = useState(false);
  const [inputDataForSupplierDeleteModal, setInputDataForSupplierDeleteModal] = useState(-1);
  const handleCloseSupplierDeleteModal = () => {
    setShowSupplierDeleteModal(false)
    setInputDataForSupplierDeleteModal(-1);
  };
  const handleShowSupplierDeleteModal = (id) => {
    dispatch(getSupplierById({ id: id }));
    setDataRequester('delete');
  };
  const handleDeleteSupplierDeleteModal = () => {
    if (inputDataForSupplierDeleteModal !== -1) { dispatch(deleteSupplier({ id: inputDataForSupplierDeleteModal.id })); }
    handleCloseSupplierDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <SupplierAddModal
          show={showSupplierAddModal}
          handleClose={handleCloseSupplierAddModal}
          handleAdd={handleAddSupplierAddModal}
        />
        <SupplierUpdateModal
          show={showSupplierUpdateModal}
          handleClose={handleCloseSupplierUpdateModal}
          handleUpdate={handleUpdateSupplierUpdateModal}
          inputData={inputDataForSupplierUpdateModal}
        />
        <DeleteModal
          show={showSupplierDeleteModal}
          handleClose={handleCloseSupplierDeleteModal}
          handleDelete={handleDeleteSupplierDeleteModal}
          title="Tedarikçiyi Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForSupplierDeleteModal.name} adlı tedarikçiyi silmek istediğinize emin misiniz?`}
          deleteIcon={<RiUserUnfollowFill className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage title='Tedarikçiler' Operations={operations} Table={() => tableContent} Modals={modals} />
  );
}

export default Suppliers;