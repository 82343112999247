// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Roles/';

// Async Thunks
export const getAllRoles = createAsyncThunk('backend_getallroles', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllRoles`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getRoleById = createAsyncThunk('backend_getrolebyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetRoleById?id=${id}`, { withCredentials: true });
    return res.data.data;
  } catch (error) { throw error.response.data || error; }
});
export const addNewRole = createAsyncThunk('backend_addnewrole', async ({ newrole }) => {
  try {
    const res = await axios.post(`${baseurl}AddRole`, newrole, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateRole = createAsyncThunk('backend_updaterole', async ({ role }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateRole`, role, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteRole = createAsyncThunk('backend_deleterole', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteRole?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const roleSlice = createSlice({
  name: 'role',
  initialState: {
    roles: [],
    role: {},
    getAllStatus: null,
    getByIdStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetgetByIdStatus: (state) => { state.getByIdStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRoles.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllRoles.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.roles = action.payload || []; })
      .addCase(getAllRoles.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getRoleById.pending, (state) => { state.getByIdStatus = "pending"; })
      .addCase(getRoleById.fulfilled, (state, action) => { state.getByIdStatus = "fulfilled"; state.role = action.payload || {}; })
      .addCase(getRoleById.rejected, (state, action) => { state.getByIdStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewRole.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewRole.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewRole.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateRole.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateRole.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateRole.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteRole.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteRole.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteRole.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
})

export const { resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetgetByIdStatus, resetError } = roleSlice.actions;
export default roleSlice.reducer;