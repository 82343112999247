// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { getOfferById, getOfferPDFById, getAllOffersByDate, addNewOffer, updateOffer, deleteOffer, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetgetByIdStatus, resetError } from '../../../storages/slices/backend/OfferSlice';
import { getExchange, resetStatus as exchangeResetStatus } from '../../../storages/slices/ExchangeSlice';
// Contexts
import { useData } from '../../../contexts/DataContext';
import { useFunctions } from '../../../contexts/FunctionsContext';
// Icons
import { IoAddCircle, IoCheckmarkCircle, IoRemoveCircle } from "react-icons/io5";
import { TbPrinter, } from "react-icons/tb";
import { BsFileEarmarkPdf } from "react-icons/bs";
//Modals
import OffersAddModal from '../modals/offers/OffersAddModal';
import DeleteModal from '../modals/DeleteModal';
import OffersUpdateModal from '../modals/offers/OffersUpdateModal';
import LoadingModal from '../modals/LoadingModal';
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Offers() {
  // Storage Import
  const dispatch = useDispatch();
  const { offers, offer, getByIdStatus, getAllStatusByDate, addStatus, updateStatus, deleteStatus, error, pdfLoading } = useSelector((state) => state.offer);
  const { exchanges, status: exchangeStatus } = useSelector((state) => state.exchange);
  // Context Import
  const { today, setDollar, setEuro, lastWeekDate, myUser } = useData();
  const { stringMoney, stringDate } = useFunctions();
  // Variables
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [canfetchData, setCanFetchData] = useState(false);
  // Filtering
  const [startdate, setStartdate] = useState(lastWeekDate);
  const [enddate, setEnddate] = useState(today);
  // Data & Api
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPrint, setDataToPrint] = useState([]);
  useEffect(() => {
    if (getAllStatusByDate === "fulfilled") {
      const data = offers.map((data) => {
        const id = data.id;
        const date = stringDate(data.date);
        const customerName = data.customerName;
        const phone = data.customerPhone1;
        const customerAddress = data.customerAddress;
        const district = data.district;
        const city = data.city;
        const total = stringMoney(data.total, 'TL');
        const status = data.status;
        const addressString = `${customerName} - ${phone}\n${customerAddress}\n${district} / ${city}`;
        const customerInfo = (
          <div className='d-flex flex-column text-start justify-content-start align-items-start'>
            <p className='m-0 p-0'>{customerName} - {phone} </p>
            <p className='m-0 p-0'>{customerAddress}</p>
            <p className='m-0 p-0'>{district}/{city}</p>
          </div>
        )
        const statusstyle = (
          <p className='m-0 p-0  fw-bold'>{status}</p>
        )
        return { id, date, customerName, phone, customerAddress, district, city, addressString, customerInfo, total, status, statusstyle };
      });
      setDataToTable(data.map(item => ({ id: item.id, date: item.date, customerInfo: item.customerInfo, total: item.total, statusstyle: item.statusstyle, searchAddress: item.addressString, searchStatus: item.status })));
      setDataToPrint(data.map(item => ({ id: item.id, date: item.date, customerInfo: item.addressString, total: item.total, statusstyle: item.status })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatusByDate, offers]);
  useEffect(() => {
    if (exchangeStatus === 'succeeded') {
      setDollar(exchanges.find(item => item.currency === 'US DOLLAR'));
      setEuro(exchanges.find(item => item.currency === 'EURO'));
      dispatch(exchangeResetStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeStatus]);
  const fetchAllDataSequentially = async (dispatch, signal, start, end) => {
    try {
      await dispatch(getAllOffersByDate({ signal, start: start, end: end }));
      await dispatch(getExchange({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal, startdate, enddate); }
    return () => { controller.abort(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canfetchData, dispatch, startdate, enddate]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseOffersAddModal();
      dispatch(getAllOffersByDate({ signal: null, start: startdate, end: enddate }));
      dispatch(resetAddStatus());
      dispatch(addToast({ background: 'success', icon: 'add', message: 'Teklif Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseOffersUpdateModal();
      dispatch(getAllOffersByDate({ signal: null, start: startdate, end: enddate }));
      dispatch(resetUpdateStatus());
      dispatch(addToast({ background: 'primary', icon: 'update', message: 'Teklif Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseOffersDeleteModal();
      dispatch(getAllOffersByDate({ signal: null, start: startdate, end: enddate }));
      dispatch(resetDeleteStatus());
      dispatch(addToast({ background: 'danger', icon: 'delete', message: 'Teklif Bilgileri Silindi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      setInputDataForOffersUpdateModal(offer);
      setShowOffersUpdateModal(true);
      dispatch(resetgetByIdStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus, dispatch]);
  useEffect(() => {
    if (pdfLoading) {
      setLoadingPDF(true);
    }
    else {
      setLoadingPDF(false);
    }
  }, [pdfLoading])
  useEffect(() => {
    if (error && error !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'customerInfo', name: 'MÜŞTERİ BİLGİLERİ' }, { value: 'total', name: 'GENEL TOPLAM' }, { value: 'statusstyle', name: 'DURUM' }];
  const filters = ['id', 'date', 'searchAddress', 'total', 'searchStatus'];
  const select = (id) => { handleShowOffersUpdateModal(id); }
  const dates = (startDate, endDate) => { setStartdate(startDate); setEnddate(endDate); }
  const buttons = [
    {
      text: 'PDF Ekle',
      icon: <BsFileEarmarkPdf className='button-icon' />,
      onclick: ((rowData) => { dispatch(getOfferPDFById({ id: rowData.id })); }),
      variant: 'success'
    },
    {
      text: 'Güncelle',
      icon: <IoCheckmarkCircle className='button-icon' />,
      onclick: ((rowData) => { handleShowOffersUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <IoRemoveCircle className='button-icon' />,
      onclick: ((rowData) => { handleShowOffersDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  const tableContent = useMemo(() => {
    if (getAllStatusByDate === 'fulfilled') {
      return (
        <Table
          data={dataToTable}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          setDates={{ start: startdate, end: enddate }}
          dates={dates}
          buttons={buttons}
          bodyAlignBroker={['customerInfo']}
        />
      );
    } else if (getAllStatusByDate === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatusByDate, dataToTable]);
  // Local Functions
  const operations = () => {
    return (
      <>
        <button onClick={(e) => { e.stopPropagation(); handleShowOffersAddModal(); }} className="dropdown-item"><IoAddCircle style={{ fontSize: '1.5em' }} /> Teklif Ekle</button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: dataToPrint, cols: cols, pdftitle: "Teklifler" })) }}>
          <TbPrinter style={{ fontSize: '1.5em' }} />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Offers Add Modal
  const [showOffersAddModal, setShowOffersAddModal] = useState(false);
  const handleCloseOffersAddModal = () => { setShowOffersAddModal(false) };
  const handleShowOffersAddModal = () => { setShowOffersAddModal(true) };
  const handleAddOffersAddModal = (values) => {
    dispatch(addNewOffer({ newoffer: values }));
    handleCloseOffersAddModal();
  };
  // Offers Update Modal
  const [showOffersUpdateModal, setShowOffersUpdateModal] = useState(false);
  const [inputDataForOffersUpdateModal, setInputDataForOffersUpdateModal] = useState({});
  const handleCloseOffersUpdateModal = () => {
    setShowOffersUpdateModal(false)
    setInputDataForOffersUpdateModal({});
  };
  const handleShowOffersUpdateModal = (id) => {
    dispatch(getOfferById({ id: id }));
  };
  const handleUpdateOffersUpdateModal = (values) => {
    dispatch(updateOffer({ offer: values }));
    handleCloseOffersUpdateModal();
  };
  // Offers Delete Modal
  const [showOffersDeleteModal, setShowOffersDeleteModal] = useState(false);
  const [inputDataForOffersDeleteModal, setInputDataForOffersDeleteModal] = useState(-1);
  const handleCloseOffersDeleteModal = () => {
    setShowOffersDeleteModal(false)
    setInputDataForOffersDeleteModal(-1);
  };
  const handleShowOffersDeleteModal = (id) => {
    setInputDataForOffersDeleteModal(id);
    setShowOffersDeleteModal(true);
  };
  const handleDeleteOffersDeleteModal = () => {
    dispatch(deleteOffer({ id: inputDataForOffersDeleteModal }));
    handleCloseOffersDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <OffersAddModal
          show={showOffersAddModal}
          handleClose={handleCloseOffersAddModal}
          handleAdd={handleAddOffersAddModal}>
        </OffersAddModal>
        <OffersUpdateModal
          show={showOffersUpdateModal}
          handleClose={handleCloseOffersUpdateModal}
          handleUpdate={handleUpdateOffersUpdateModal}
          inputData={inputDataForOffersUpdateModal}>
        </OffersUpdateModal>
        <DeleteModal
          show={showOffersDeleteModal}
          handleClose={handleCloseOffersDeleteModal}
          handleDelete={handleDeleteOffersDeleteModal}
          title="Teklifi Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForOffersDeleteModal} numaralı teklifi silmek istediğinize emin misiniz?`}
          deleteIcon={<IoRemoveCircle className='button-icon' />}
        >
        </DeleteModal>
        <LoadingModal show={loadingPDF} />
      </>
    );
  }
  // HTML
  return (
    <TablePage title='Teklifler' Operations={operations} Table={() => tableContent} Modals={modals} />
  );
}

export default Offers;