// General
import { createContext, useContext } from 'react';
import * as Yup from 'yup';

const ValidationsContext = createContext();

const ValidationsProvider = ({ children }) => {
  // Validations
  // Services
  const validationSchema_ServiceAdd = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir müşteri adı giriniz!')
      .matches(/^[^\s]/, 'Müşteri adı boşluk ile başlayamaz!')
      .min(2, 'Müşteri adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Müşteri adı en fazla 40 karakter uzunluğunda olabilir!'),
    phone1: Yup.string()
      .required('Lütfen bir telefon numarası giriniz!')
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!'),
    phone2: Yup.string()
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!')
      .nullable(),
    city: Yup.string()
      .required('Lütfen bir şehir seçiniz!'),
    district: Yup.string()
      .required('Lütfen bir ilçe seçiniz!'),
    address: Yup.string()
      .required('Lütfen bir adres bilgisi giriniz!')
      .matches(/^[^\s]/, 'Adres boşluk ile başlayamaz!'),
    customerType: Yup.string()
      .required('Lütfen bir müşteri tipi seçiniz!')
      .oneOf(['Bireysel', 'Kurumsal'], 'Geçersiz bir müşteri tipi girdiniz!'),
    identityNumber: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Bireysel'),
        then: (schema) => schema
          .matches(/^[1-9]/, 'T.C. kimlik numarası "0" ile başlayamaz!')
          .matches(/^\d+$/, 'T.C. kimlik numarası yalnızca sayılardan oluşmalıdır!')
          .length(11, 'T.C. kimlik numarası tam olarak 11 haneli olmalıdır!'),
      }
      ),
    taxNumber: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Kurumsal'),
        then: (schema) => schema
          .matches(/^\d+$/, 'Vergi Numarası yalnızca sayılardan oluşmalıdır!')
          .length(10, 'Vergi Numarası tam olarak 10 haneli olmalıdır!'),
      }
      ),
    taxOffice: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Kurumsal'),
        then: (schema) => schema
          .matches(/^[^\s]/, 'Vergi dairesi boşluk ile başlayamaz!')
          .min(2, 'Vergi dairesi en az 2 karakter olmalıdır!'),
      }
      ),
    customerDate: Yup.string()
      .required('Lütfen müşteri için uygun bir tarih seçiniz!'),
    customerTimeStart: Yup.string()
      .required('Lütfen müşteri için uygun bir saat seçiniz!'),
    customerTimeEnd: Yup.string()
      .required('Lütfen müşteri için uygun bir saat seçiniz!'),
    brandName: Yup.string()
      .required('Lütfen bir marka adı giriniz!'),
    deviceName: Yup.string()
      .required('Lütfen bir cihaz adı giriniz!'),
    model: Yup.string()
      .required('Lütfen bir model giriniz!'),
    problem: Yup.string()
      .required('Lütfen bir problem giriniz!'),
    warranty: Yup.number()
      .required('Lütfen bir garanti süresi giriniz!'),
    sourceName: Yup.string()
      .required('Lütfen bir kaynak seçiniz!')
  });
  const validationSchema_ServiceUpdate = Yup.object().shape({
    customerDate: Yup.string()
      .required('Lütfen müşteri için uygun bir tarih seçiniz!'),
    customerTimeStart: Yup.string()
      .required('Lütfen müşteri için uygun bir saat seçiniz!'),
    customerTimeEnd: Yup.string()
      .required('Lütfen müşteri için uygun bir saat seçiniz!'),
    brandName: Yup.string()
      .required('Lütfen bir marka adı giriniz!'),
    deviceName: Yup.string()
      .required('Lütfen bir cihaz adı giriniz!'),
    model: Yup.string()
      .required('Lütfen bir model giriniz!'),
    problem: Yup.string()
      .required('Lütfen bir problem giriniz!'),
    warranty: Yup.number()
      .required('Lütfen bir garanti süresi giriniz!'),
    sourceName: Yup.string()
      .required('Lütfen bir kaynak seçiniz!')
  });
  const validationSchema_ServiceIncomingCalls = Yup.object().shape({
    source: Yup.string().required('Lütfen bir kaynak seçiniz!'),
    brand: Yup.string().required('Lütfen bir marka seçiniz!'),
    desc: Yup.string().required('Lütfen bir açıklama giriniz!'),
  });
  const validationSchema_ServiceNote = Yup.object().shape({
    note: Yup.string().required('Not boş bırakılamaz!'),
  });
  // Customers
  const validationSchema_Customer = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir müşteri adı giriniz!')
      .matches(/^[^\s]/, 'Müşteri adı boşluk ile başlayamaz!')
      .min(2, 'Müşteri adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Müşteri adı en fazla 40 karakter uzunluğunda olabilir!'),
    phone1: Yup.string()
      .required('Lütfen bir telefon numarası giriniz!')
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!'),
    phone2: Yup.string()
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!')
      .nullable(),
    city: Yup.string()
      .required('Lütfen bir şehir seçiniz!'),
    district: Yup.string()
      .required('Lütfen bir ilçe seçiniz!'),
    address: Yup.string()
      .required('Lütfen bir adres bilgisi giriniz!')
      .matches(/^[^\s]/, 'Adres boşluk ile başlayamaz!'),
    customerType: Yup.string()
      .required('Lütfen bir müşteri tipi seçiniz!')
      .oneOf(['Bireysel', 'Kurumsal'], 'Geçersiz bir müşteri tipi girdiniz!'),
    identityNumber: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Bireysel'),
        then: (schema) => schema
          .matches(/^[1-9]/, 'T.C. kimlik numarası "0" ile başlayamaz!')
          .matches(/^\d+$/, 'T.C. kimlik numarası yalnızca sayılardan oluşmalıdır!')
          .length(11, 'T.C. kimlik numarası tam olarak 11 haneli olmalıdır!'),
      }
      ),
    taxNumber: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Kurumsal'),
        then: (schema) => schema
          .matches(/^\d+$/, 'Vergi Numarası yalnızca sayılardan oluşmalıdır!')
          .length(10, 'Vergi Numarası tam olarak 10 haneli olmalıdır!'),
      }
      ),
    taxOffice: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Kurumsal'),
        then: (schema) => schema
          .matches(/^[^\s]/, 'Vergi dairesi boşluk ile başlayamaz!')
          .min(2, 'Vergi dairesi en az 2 karakter olmalıdır!'),
      }
      ),
  });
  // Employees
  const validationSchema_EmployeeAdd = Yup.object().shape({
    date: Yup.date()
      .required('Lütfen bir işe giriş tarihi seçiniz!'),
    roleName: Yup.string()
      .required('Lütfen bir rol seçiniz!'),
    name: Yup.string()
      .required('Lütfen bir personel adı giriniz!')
      .matches(/^[^\s]/, 'Personel adı boşluk ile başlayamaz!')
      .min(2, 'Personel adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Personel adı en fazla 40 karakter uzunluğunda olabilir!'),
    username: Yup.string()
      .required('Lütfen bir personel kulanıcıadı giriniz!')
      .matches(/^[^\s]/, 'Personel kulanıcıadı boşluk ile başlayamaz!')
      .min(2, 'Personel kulanıcıadı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Personel kulanıcıadı en fazla 40 karakter uzunluğunda olabilir!'),
    password: Yup.string()
      .required('Lütfen bir şifre giriniz!')
      .min(4, 'Şifre en az 4 karakterden oluşmalıdır!')
      .max(32, 'Şifre en fazla 32 karakterden oluşabilir!'),
    phone1: Yup.string()
      .required('Lütfen bir telefon numarası giriniz!')
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!'),
    phone2: Yup.string()
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!')
      .nullable(),
    prim: Yup.number()
      .required('Lütfen geçerli bir prim değeri girin!'),
    status: Yup.string()
      .required('Lütfen personele ait bir durum seçiniz!')
      .oneOf(['Aktif', 'Aktif Değil', 'İzinli'], 'Geçersiz bir durum girdiniz!'),
    city: Yup.string()
      .required('Lütfen bir şehir seçiniz!'),
    district: Yup.string()
      .required('Lütfen bir ilçe seçiniz!'),
    address: Yup.string()
      .required('Lütfen bir adres bilgisi giriniz!')
      .matches(/^[^\s]/, 'Adres boşluk ile başlayamaz!'),
  });
  const validationSchema_EmployeeUpdate = Yup.object().shape({
    date: Yup.date()
      .required('Lütfen bir işe giriş tarihi seçiniz!'),
    roleName: Yup.string()
      .required('Lütfen bir rol seçiniz!'),
    name: Yup.string()
      .required('Lütfen bir personel adı giriniz!')
      .matches(/^[^\s]/, 'Personel adı boşluk ile başlayamaz!')
      .min(2, 'Personel adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Personel adı en fazla 40 karakter uzunluğunda olabilir!'),
    username: Yup.string()
      .required('Lütfen bir personel kulanıcıadı giriniz!')
      .matches(/^[^\s]/, 'Personel kulanıcıadı boşluk ile başlayamaz!')
      .min(2, 'Personel kulanıcıadı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Personel kulanıcıadı en fazla 40 karakter uzunluğunda olabilir!'),
    password: Yup.string()
      .min(4, 'Şifre en az 4 karakterden oluşmalıdır!')
      .max(32, 'Şifre en fazla 32 karakterden oluşabilir!')
      .nullable(),
    phone1: Yup.string()
      .required('Lütfen bir telefon numarası giriniz!')
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!'),
    phone2: Yup.string()
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!')
      .nullable(),
    prim: Yup.number()
      .required('Lütfen geçerli bir prim değeri girin!'),
    status: Yup.string()
      .required('Lütfen personele ait bir durum seçiniz!')
      .oneOf(['Aktif', 'Aktif Değil', 'İzinli'], 'Geçersiz bir durum girdiniz!'),
    city: Yup.string()
      .required('Lütfen bir şehir seçiniz!'),
    district: Yup.string()
      .required('Lütfen bir ilçe seçiniz!'),
    address: Yup.string()
      .required('Lütfen bir adres bilgisi giriniz!')
      .matches(/^[^\s]/, 'Adres boşluk ile başlayamaz!'),
  });
  // Stock Transaction
  const validationSchema_StockTransactionAdd = Yup.object().shape({
    operationName: Yup.string()
      .required('Lütfen işlem seçiniz!'),
    supplierName: Yup.string()
      .required('Lütfen tedarikçi seçiniz!'),
    quantity: Yup.number()
      .required('Lütfen adet sayısını giriniz!'),
    purchasePrice: Yup.number()
      .required('Lütfen bir alış fiyatı giriniz!'),
  });
  // Warehouse
  const validationSchema_Stock = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir ürün adı giriniz!')
      .matches(/^[^\s]/, 'Ürün adı boşluk ile başlayamaz!')
      .min(2, 'Ürün adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Ürün adı en fazla 40 karakter uzunluğunda olabilir!'),
    supplierName: Yup.string()
      .required('Lütfen bir tedarikçi seçiniz!'),
    barcode: Yup.string()
      .required('Lütfen barkod numarasını giriniz!'),
    brandName: Yup.string()
      .required('Lütfen bir marka seçiniz!'),
    deviceName: Yup.string()
      .required('Lütfen bir cihaz seçiniz!'),
    warehouseName: Yup.string()
      .required('Lütfen bir depo seçiniz!'),
    warehouseShelfName: Yup.string()
      .required('Lütfen bir raf seçiniz!'),
    quantity: Yup.number()
      .required('Lütfen adet sayısını giriniz!'),
    purchasePrice: Yup.number()
      .required('Lütfen bir alış fiyatı giriniz!'),
    salePrice: Yup.number()
      .required('Lütfen bir satış fiyatı giriniz!'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz!')
      .oneOf(['Aktif', 'Aktif Değil'], 'Geçersiz bir durum girdiniz!'),
  });
  // Offers
  const validationSchema_Offers = Yup.object().shape({
    customerName: Yup.string()
      .required('Lütfen bir müşteri adı giriniz!')
      .matches(/^[^\s]/, 'Müşteri adı boşluk ile başlayamaz!')
      .min(2, 'Müşteri adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Müşteri adı en fazla 40 karakter uzunluğunda olabilir!'),
    customerPhone1: Yup.string()
      .required('Lütfen bir telefon numarası giriniz!')
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!'),
    customerPhone2: Yup.string()
      .matches(/^5/, 'Girdiğiniz telefon numarası formatı hatalı!')
      .length(10, 'Telefon numarası tam olarak 10 haneli olmalıdır!')
      .nullable(),
    city: Yup.string()
      .required('Lütfen bir şehir seçiniz!'),
    district: Yup.string()
      .required('Lütfen bir ilçe seçiniz!'),
    customerAddress: Yup.string()
      .required('Lütfen bir adres bilgisi giriniz!')
      .matches(/^[^\s]/, 'Adres boşluk ile başlayamaz!'),
    customerType: Yup.string()
      .required('Lütfen bir müşteri tipi seçiniz!')
      .oneOf(['Bireysel', 'Kurumsal'], 'Geçersiz bir müşteri tipi girdiniz!'),
    identityNumber: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Bireysel'),
        then: (schema) => schema
          .matches(/^[1-9]/, 'T.C. kimlik numarası "0" ile başlayamaz!')
          .matches(/^\d+$/, 'T.C. kimlik numarası yalnızca sayılardan oluşmalıdır!')
          .length(11, 'T.C. kimlik numarası tam olarak 11 haneli olmalıdır!'),
      }
      ),
    taxNumber: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Kurumsal'),
        then: (schema) => schema
          .matches(/^\d+$/, 'Vergi Numarası yalnızca sayılardan oluşmalıdır!')
          .length(10, 'Vergi Numarası tam olarak 10 haneli olmalıdır!'),
      }
      ),
    taxOffice: Yup.string()
      .when('customerType', {
        is: ((value) => value === 'Kurumsal'),
        then: (schema) => schema
          .matches(/^[^\s]/, 'Vergi dairesi boşluk ile başlayamaz!')
          .min(2, 'Vergi dairesi en az 2 karakter olmalıdır!'),
      }
      ),
    date: Yup.date()
      .required('Lütfen teklif tarihi seçiniz'),
    title: Yup.string()
      .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
      .required('Lütfen teklif başlığı giriniz')
      .min(2, 'başlık en az 2 karakter olmalıdır.')
      .max(25, 'başlık en fazla 25 karakter olmalıdır.'),
    status: Yup.string()
      .required('Bu alan gereklidir'),
    totalText: Yup.string()
      .matches(/^[^0]/, '0 ile başlayamaz.')
      .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ.,\s]*$/, 'Sadece harf, nokta, virgül ve boşluk kullanılabilir.')
      .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ]/, 'Boşluk olmamalı, Başlangıçta harf kullanılmalı.')
      .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ]+([.,][A-Za-zçğıöşüÇĞİÖŞÜ]+|[\s]+[A-Za-zçğıöşüÇĞİÖŞÜ]+)*\s*$/, 'Nokta veya virgülden sonra harf kullanılmalı')
      .matches(/^(?:[A-Za-zçğıöşüÇĞİÖŞÜ]+([.,][A-Za-zçğıöşüÇĞİÖŞÜ]+)*)(?:\s+(?:[A-Za-zçğıöşüÇĞİÖŞÜ]+))*\s*$/, 'Örnek kullanımlar: Kırkbin TL, Onbin TL')
      .min(2, 'tutar en az 2 karakter olmalıdır.')
      .max(25, 'tutar en fazla 25 karakter olmalıdır.')
      .required('Bu alan gereklidir'),
    currency: Yup.string()
      .matches(/^[^0]/, '0 ile başlayamaz.')
      .required('Bu alan gereklidir'),
    kdv: Yup.string()
      .required('Bu alan gereklidir'),
    explanation: Yup.string()
      .matches(/^\S.*$/, 'Açıklama boşluk ile başlayamaz.')
      .min(5, 'Açıklama en az 5 karakter olmalıdır.')
      .max(250, 'Açıklama en fazla 250 karakter olmalıdır.'),
    offerDetails: Yup.array().of(
      Yup.object().shape({
        type: Yup.string()
          .matches(/^[^\s].*$/, 'Başta boşluk olmamalı.')
          .required('Lütfen ürün adı giriniz')
          .matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ0-9\s]+$/, 'Sadece harf ve rakam içermelidir.')
          .min(2, 'ürün en az 2 karakter olmalıdır.')
          .max(25, 'ürün en fazla 25 karakter olmalıdır.'),
        quantity: Yup.number()
          .typeError('Ürün Miktarı sayısal bir değer olmalıdır')
          .min(0, 'Ürün miktarı 0 veya daha büyük olmalıdır')
          .required('Ürün Miktarı gereklidir'),
        price: Yup.number()
          .typeError('Ürün Fiyatı sayısal bir değer olmalıdır')
          .min(0, 'Ürün Fiyatı 0 veya daha büyük olmalıdır')
          .required('Ürün Fiyatı gereklidir'),
      })
    ),
  });
  // Reminders
  const validationSchema_Reminder = Yup.object().shape({
    time: Yup.string()
      .required('Hatırlatma saati zorunludur')
      .matches(/^([01]\d|2[0-3]).([0-5]\d)$/, 'Geçerli bir saat girin (HH:MM)'),
    date: Yup.date()
      .required('Lütfen hatırlatma tarih seçiniz')
      .min(new Date((new Date().setHours(0, 0, 0, 0))), 'Başlangıç tarihi bugünden önce olamaz'),
    performerOfThetransaction: Yup.array()
      .min(1, 'En az bir kişi seçilmelidir') // Minimum of one selection
      .required('Bu alan zorunludur'),
    remainingTime: Yup.string().required('Lütfen hatırlatma zamanı seçiniz.'),
    repeatTime: Yup.string().required('Lütfen tekrar süresi seçiniz.'),
    status: Yup.string().required('Lütfen bir durum seçiniz.'),
    message: Yup.string()
      .required('Lütfen bir adres giriniz.')
      .matches(/^\S.*$/, 'Adres boşluk ile başlayamaz.')
      .min(5, 'Adres en az 5 karakter olmalıdır.')
      .max(250, 'Adres en fazla 250 karakter olmalıdır.'),

  });
  // Checkouts
  const validationSchema_Checkout = Yup.object().shape({
    date: Yup.string()
      .required('Lütfen bir tarih seçiniz!'),
    time: Yup.string()
      .required('Lütfen bir saat seçiniz!'),
    paymentType: Yup.string()
      .required('Lütfen bir ödeme yönü seçiniz!'),
    paymentMethod: Yup.string()
      .required('Lütfen bir ödeme şekli seçiniz!'),
    actionTypeName: Yup.string()
      .required('Lütfen bir ödeme türü seçiniz!'),
    status: Yup.string()
      .required('Lütfen bir ödeme durumu seçiniz!'),
    total: Yup.string()
      .required('Lütfen bir tutar giriniz!'),
    unit: Yup.string()
      .required('Lütfen bir birim seçiniz!')
  });
  // Invoices
  const validationSchema_Invoices = Yup.object().shape({
    serviceId: Yup.string().required('Lütfen Servis id seçiniz'),
    date: Yup.string().required('Lütfen bir tarih seçiniz!'),
    products: Yup.array().of(
      Yup.object().shape({
        product: Yup.string().required('Lütfen ürün adı giriniz').matches(/^[^\s].*$/, 'Başta boşluk olmamalı.').matches(/^[A-Za-zçğıöşüÇĞİÖŞÜ0-9\s]+$/, 'Sadece harf ve rakam içermelidir.').min(2, 'ürün en az 2 karakter olmalıdır.').max(25, 'ürün en fazla 25 karakter olmalıdır.'),
        count: Yup.number().required('Ürün Miktarı gereklidir').typeError('Ürün Miktarı sayısal bir değer olmalıdır').min(0, 'Ürün miktarı 0 veya daha büyük olmalıdır'),
        price: Yup.number().required('Ürün Fiyatı gereklidir').typeError('Ürün Fiyatı sayısal bir değer olmalıdır').min(0, 'Ürün Fiyatı 0 veya daha büyük olmalıdır'),
      })
    ),

  });
  // BulkMessages
  const validationSchema_BulkMessages = Yup.object().shape({
    // startdate: Yup.date(),
    // enddate: Yup.date()
    //   .test('is-greater', 'Bitiş tarihi, başlangıç tarihinden sonra olmalıdır', function (value) {
    //     const { startdate } = this.parent;
    //     return value > startdate;
    //   })
    //   .notOneOf([Yup.ref('startdate')], 'Başlangıç tarihi ve bitiş tarihi aynı olamaz'),
    message: Yup.string()
      .matches(/^\S.*$/, 'Mesaj boşluk ile başlayamaz.')
      .min(5, 'Mesaj en az 5 karakter olmalıdır')
      .max(250, 'Mesaj en fazla 250 karakter olmalıdır')
      .required('Bu alan gerekli'),
  });
  // OperationReports
  const validationSchema_OperationReports = Yup.object().shape({
    serviceId: Yup.object().required("bu alan gerekli"),
    fault: Yup.string()
      .required('Lütfen  problem giriniz.')
      .matches(/^\S.*$/, 'problem boşluk ile başlayamaz.')
      .min(5, 'problem en az 5 karakter olmalıdır.')
      .max(250, 'problem en fazla 250 karakter olmalıdır.'),
    transactions: Yup.string()
      .required('Lütfen yapılanları giriniz.')
      .matches(/^\S.*$/, 'yapılanlar boşluk ile başlayamaz.')
      .min(5, 'yapılanlar en az 5 karakter olmalıdır.')
      .max(250, 'yapılanlar en fazla 250 karakter olmalıdır.'),
    informations: Yup.string()
      .required('Lütfen bilgileri giriniz.')
      .matches(/^\S.*$/, 'bilgiler boşluk ile başlayamaz.')
      .min(5, 'bilgiler en az 5 karakter olmalıdır.')
      .max(250, 'bilgiler en fazla 250 karakter olmalıdır.'),
    total: Yup.string()
      .matches(/^[^0,.]/, '0, , ve . ile başlayamaz.')
      .matches(/^\d+([,.]\d+)*$/, ', ve . sadece rakamlar arasında olabilir.')
      .required('Bu alan gereklidir'),
  });
  // Vehicles
  const validationSchema_Vehicles = Yup.object().shape({
    plate: Yup.string()
      .required('Lütfen bir araç plakası giriniz!')
      .matches(/^(0[1-9]|[1-7][0-9]|8[01])[A-Z]{1,3}\d{1,4}$|^(0[1-9]|[1-7][0-9]|8[01]) [A-Z]{1,3} \d{1,4}$/, 'Hatalı araç plakası girdiniz!'),
    description: Yup.string()
      .required('Lütfen bir açıklama giriniz!')
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .min(2, 'Açıklama en az 3 karakterden oluşmalıdır!')
      .max(200, 'Açıklama en fazla 200 karakterden oluşabilir!'),
  });
  // Suppliers
  const validationSchema_Supplier = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir tedarikçi adı giriniz!')
      .matches(/^[^\s]/, 'Tedarikçi adı boşluk ile başlayamaz!')
      .min(2, 'Tedarikçi adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Tedarikçi adı en fazla 40 karakter uzunluğunda olabilir!'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz')
      .oneOf(['Aktif', 'Aktif Değil'], 'Geçersiz bir durum girdiniz!'),
  });
  // Warehouses
  const validationSchema_Warehouse = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir depo adı giriniz!')
      .matches(/^[^\s]/, 'Depo adı boşluk ile başlayamaz!')
      .min(2, 'Depo adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Depo adı en fazla 40 karakter uzunluğunda olabilir!'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz')
      .oneOf(['Aktif', 'Aktif Değil'], 'Geçersiz bir durum girdiniz!'),
  });
  // Shelves
  const validationSchema_Shelf = Yup.object().shape({
    warehousename: Yup.string()
      .required('Lütfen bir depo seçiniz!'),
    name: Yup.string()
      .required('Lütfen bir raf adı giriniz!')
      .matches(/^[^\s]/, 'Raf adı boşluk ile başlayamaz!')
      .min(2, 'Raf adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Raf adı en fazla 40 karakter uzunluğunda olabilir!'),
    description: Yup.string()
      .required('Lütfen bir açıklama giriniz!')
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .min(2, 'Açıklama en az 3 karakterden oluşmalıdır!')
      .max(200, 'Açıklama en fazla 200 karakterden oluşabilir!'),
  });
  // Roles
  const validationSchema_Roles = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir rol adı giriniz!')
      .matches(/^[^\s]/, 'Rol adı boşluk ile başlayamaz!')
      .min(2, 'Rol adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Rol adı en fazla 40 karakter uzunluğunda olabilir!'),
    description: Yup.string()
      .required('Lütfen bir açıklama giriniz!')
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .min(2, 'Açıklama en az 3 karakterden oluşmalıdır!')
      .max(200, 'Açıklama en fazla 200 karakterden oluşabilir!'),
  });
  // Brands
  const validationSchema_Brand = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir marka adı giriniz!')
      .matches(/^[^\s]/, 'Marka adı boşluk ile başlayamaz!')
      .min(2, 'Marka adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Marka adı en fazla 40 karakter uzunluğunda olabilir!'),
    description: Yup.string()
      .required('Lütfen bir açıklama giriniz!')
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .min(2, 'Açıklama en az 3 karakterden oluşmalıdır!')
      .max(200, 'Açıklama en fazla 200 karakterden oluşabilir!'),
  });
  // Devices
  const validationSchema_Device = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir cihaz adı giriniz!')
      .matches(/^[^\s]/, 'Cihaz adı boşluk ile başlayamaz!')
      .min(2, 'Cihaz adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Cihaz adı en fazla 40 karakter uzunluğunda olabilir!'),
    description: Yup.string()
      .required('Lütfen bir açıklama giriniz!')
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .min(2, 'Açıklama en az 3 karakterden oluşmalıdır!')
      .max(200, 'Açıklama en fazla 200 karakterden oluşabilir!'),
  });
  // Dealers
  const validationSchema_Dealer = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir bayi adı giriniz!')
      .matches(/^[^\s]/, 'Bayi adı boşluk ile başlayamaz!')
      .min(2, 'Bayi adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Bayi adı en fazla 40 karakter uzunluğunda olabilir!'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz')
      .oneOf(['Aktif', 'Aktif Değil'], 'Geçersiz bir durum girdiniz!'),
  });
  // StockActions
  const validationSchema_StockActions = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir aksiyon adı giriniz!')
      .matches(/^[^\s]/, 'Aksiyon adı boşluk ile başlayamaz!')
      .min(2, 'Aksiyon adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Aksiyon adı en fazla 40 karakter uzunluğunda olabilir!'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz')
      .oneOf(['Aktif', 'Aktif Değil'], 'Geçersiz bir durum girdiniz!'),
  });
  // Sources
  const validationSchema_Sources = Yup.object().shape({
    name: Yup.string()
      .required('Lütfen bir kaynak seçiniz'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz')
  });
  // Consigments
  const validationSchema_Consigment = Yup.object().shape({
    model: Yup.string()
      .required('Lütfen bir model adı giriniz!')
      .matches(/^[^\s]/, 'Model adı boşluk ile başlayamaz!')
      .min(2, 'Model adı en az 2 karakter uzunluğunda olmalı!')
      .max(40, 'Model adı en fazla 40 karakter uzunluğunda olabilir!'),
    deviceName: Yup.string()
      .required('Lütfen bir cihaz seçiniz'),
    brandName: Yup.string()
      .required('Lütfen bir marka seçiniz'),
    status: Yup.string()
      .required('Lütfen bir durum seçiniz'),
    quantity: Yup.number()
      .typeError(' Miktar sayısal bir değer olmalıdır')
      .min(0, ' miktar 0 veya daha büyük olmalıdır')
      .required(' Miktar gereklidir'),
    description: Yup.string()
      .required('Lütfen bir açıklama giriniz!')
      .matches(/^[^\s].*$/, 'Açıklama boşluk ile başlayamaz!')
      .min(2, 'Açıklama en az 3 karakterden oluşmalıdır!')
      .max(200, 'Açıklama en fazla 200 karakterden oluşabilir!'),
  });
  // Export Validation Schemas
  const values = { validationSchema_ServiceAdd, validationSchema_ServiceUpdate, validationSchema_ServiceIncomingCalls, validationSchema_ServiceNote, validationSchema_Customer, validationSchema_EmployeeAdd, validationSchema_EmployeeUpdate, validationSchema_Stock, validationSchema_Offers, validationSchema_Reminder, validationSchema_Checkout, validationSchema_Invoices, validationSchema_BulkMessages, validationSchema_OperationReports, validationSchema_Vehicles, validationSchema_Supplier, validationSchema_Warehouse, validationSchema_Shelf, validationSchema_Roles, validationSchema_Brand, validationSchema_Device, validationSchema_Dealer, validationSchema_StockActions, validationSchema_StockTransactionAdd, validationSchema_Sources, validationSchema_Consigment }
  return <ValidationsContext.Provider value={values}>{children}</ValidationsContext.Provider>
}

const useValidations = () => useContext(ValidationsContext);

export { ValidationsProvider, useValidations };