// General
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
// Icons
import { TbFolderPlus, TbPrinter, TbFolderCheck, TbFolderStar, TbFolderCog, TbFolderX } from "react-icons/tb";
import { MdCurrencyLira } from 'react-icons/md';
import { HiOutlineDocumentReport } from "react-icons/hi";
import alarm from '../../../assets/images/gif/alarm.gif';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { addToast } from '../../../storages/slices/ToastSlice';
import { getUserInfo } from '../../../storages/slices/LoginSlice';
import { getAllServicesByDate, getAllServicesByOperator, getAllServicesByTecnician, getAllServicesByWorkshopTaken, getAllServicesByWorkshopMaster, getAllServicesByRepairedInWorkshop, getAllServicesBySendShipping, getAllServicesByInTransit, getAllServicesByDelivered, getAllServicesByWaitingParts, getAllServicesByProductSold, getAllServicesByConsigmentDevice, getAllServicesByDeletedService, getAllServicesByIncomingCalls, getAllServicesByStage, getAllServicesByDealer, getAllServicesByEmergency, getServiceById, addNewService, updateService, deleteService, resetgetByIdStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storages/slices/backend/ServiceSlice';
import { getAllServiceActions, getAllServiceOperations, addNewServiceAction, resetAddStatus as resetAddServiceActionStatus } from '../../../storages/slices/backend/ServiceActionSlice';
import { getAllCustomers } from '../../../storages/slices/backend/CustomerSlice';
import { getAllBrands } from '../../../storages/slices/backend/BrandSlice';
import { getAllDevices } from '../../../storages/slices/backend/DeviceSlice';
import { getAllSources } from '../../../storages/slices/backend/SourceSlice';
import { getAllVehicles } from '../../../storages/slices/backend/VehicleSlice';
import { getAllEmployees } from '../../../storages/slices/backend/EmployeeSlice';
import { getAllStocks } from '../../../storages/slices/backend/StockSlice';
import { getAllDealers } from '../../../storages/slices/backend/DealerSlice';
import { getAllConsigments } from '../../../storages/slices/backend/ConsigmentSlice';
// Contexts
import { useData } from '../../../contexts/DataContext';
import { useFunctions } from '../../../contexts/FunctionsContext';
// Modals
import ServiceAddModal from '../modals/services/ServiceAddModal';
import ServiceDetailsModal from '../modals/services/ServiceDetailsModal';
import ServicePlannerModal from '../modals/services/ServicePlannerModal';
import ServiceIncomingCallsModal from '../modals/services/ServiceIncomingCallsModal';
import BonusCalculatorModal from '../modals/services/BonusCalculatorModal';
import ServiceReportsModal from '../modals/services/ServiceReportsModal';
import DeleteModal from '../modals/DeleteModal';
// Components
import StandartTablePage from '../layouts/TablePage';
import Table from '../components/Table';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';

function Services() {
  // Storage Import
  const dispatch = useDispatch();
  const { services, service, getByIdStatus, getAllStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.service);
  const { addStatus: addServiceActionStatus, error: serviceActionError } = useSelector((state) => state.serviceaction);
  const { userinfo } = useSelector((state) => state.login);
  // Context Import
  const { today, lastWeekDate, myUser } = useData();
  const { stringDate } = useFunctions();
  // Variables
  const [canfetchData, setCanFetchData] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramsReport = queryParams.get('report');
  const paramsSearch = queryParams.get('search');
  const paramsStartDate = queryParams.get('startdate');
  const paramsEndDate = queryParams.get('enddate');
  const paramsDate = queryParams.get('date');
  const [isDate, setIsDate] = useState(true);
  useEffect(() => {
    if (paramsReport) {
      if (queryParams.get('startdate') === null && queryParams.get('enddate') === null && queryParams.get('date') === null) {
        setIsDate(false);
      } else { setIsDate(true); }
    } else { setIsDate(true); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsReport]);
  const paramsName = queryParams.get('employee')?.replace(' ', '+');
  const paramsBrand = queryParams.get('brand')?.replace(' ', '+');
  const paramsDevice = queryParams.get('device')?.replace(' ', '+');
  const paramsVehicle = queryParams.get('vehicle')?.replace(' ', '+');
  const paramsSource = queryParams.get('source')?.replace(' ', '+');
  const paramsStage = queryParams.get('stage')?.replace(' ', '+');
  const paramsDealer = queryParams.get('deaeler')?.replace(' ', '+');
  const paramsHelper = queryParams.get('helperemployee')?.replace(' ', '+');
  // Filtering
  const [startdate, setStartdate] = useState(paramsStartDate ? paramsStartDate : paramsDate ? paramsDate : lastWeekDate);
  const [enddate, setEnddate] = useState(paramsEndDate ? paramsEndDate : paramsDate ? paramsDate : today);
  const [reloadId, setReloadId] = useState(-1);
  // Data & Api
  const [dataToTable, setDataToTable] = useState([]);
  const [dataToPrint, setDataToPrint] = useState([]);
  useEffect(() => {
    if (getAllStatus === "fulfilled") {
      const data = services.map((data) => {
        const id = data.id;
        const date = `${stringDate(data.date.slice(0, 10))} ${data.date.slice(11, data.date.length)}`;
        const emergency = data.isEmergency;
        const name = data.name;
        const phone1 = data.phone1;
        const phone2 = data.phone2;
        const address = data.address;
        const district = data.district;
        const city = data.city;
        const customerString = `${name} - ${phone1}${phone2 ? ` - ${phone2}` : ''}\n${address}\n${district}/${city}`;
        const customerInfo = (
          <div className='d-flex flex-column text-start justify-content-start align-items-start'>
            <p className='m-0 p-0'>{emergency ? (<img src={alarm} alt='Acil' style={{ width: '1.5em', height: '1.5em', marginRight: '0.0em', paddingBottom: '0.2em', transform: 'rotate(180deg)' }} />) : ''}{name}</p>
            <p className='m-0 p-0'>{phone1}{phone2 ? ` - ${phone2}` : ''}</p>
            <p className='m-0 p-0'>{address}</p>
            <p className='m-0 p-0'>{district}/{city}</p>
          </div>
        );
        const brand = data.brandName;
        const device = data.deviceName;
        const deviceText = `${brand}/${device}`
        const statusSearch = data.serviceAction;
        const [statusName, statusDesc] = data.serviceAction.split('\n');
        const status = (
          <div className='text-start'>
            <p className='p-0 m-0'>{statusName}</p>
            <p className='p-0 m-0'>{statusDesc}</p>
          </div>
        )
        return { id, date, customerString, customerInfo, device: deviceText, status, statusSearch };
      });
      setDataToTable(data.map(item => ({ id: item.id, date: item.date, customer: item.customerInfo, customerSearch: item.customerString, device: item.device, status: item.status, statusSearch: item.statusSearch })));
      setDataToPrint(data.map(item => ({ id: item.id, date: item.date, customer: item.customerString, device: item.device, status: item.statusSearch })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus, services]);
  const getAllServices = async (style, values) => {
    if (style === 'report') {
      try {
        if (paramsReport === '1') { await dispatch(getAllServicesByOperator({ signal: values.signal, start: startdate, end: enddate, name: paramsName })); }
        else if (paramsReport === '2') { await dispatch(getAllServicesByTecnician({ signal: values.signal, date: startdate, plate: paramsVehicle, id: paramsName, assistant: paramsHelper })); }
        else if (paramsReport === '3') { await dispatch(getAllServicesByWorkshopTaken({ signal: values.signal, start: startdate, end: enddate, id: paramsName, device: paramsDevice })); }
        else if (paramsReport === '4') { await dispatch(getAllServicesByWorkshopMaster({ signal: values.signal, date: startdate, id: paramsName })); }
        else if (paramsReport === '5') { await dispatch(getAllServicesByRepairedInWorkshop({ signal: values.signal, date: startdate, id: paramsName })); }
        else if (paramsReport === '6') { await dispatch(getAllServicesBySendShipping({ signal: values.signal, date: startdate, id: paramsName })); }
        else if (paramsReport === '7') { await dispatch(getAllServicesByInTransit({ signal: values.signal, date: startdate, id: paramsName })); }
        else if (paramsReport === '8') { await dispatch(getAllServicesByDelivered({ signal: values.signal, date: startdate, id: paramsName })); }
        else if (paramsReport === '9') { await dispatch(getAllServicesByWaitingParts({ signal: values.signal, date: startdate })); }
        else if (paramsReport === '10') { await dispatch(getAllServicesByProductSold({ signal: values.signal, start: startdate, end: enddate })); }
        else if (paramsReport === '11') { await dispatch(getAllServicesByConsigmentDevice({ signal: values.signal, brand: paramsBrand })); }
        else if (paramsReport === '12') { await dispatch(getAllServicesByDeletedService({ signal: values.signal, start: startdate, end: enddate })); }
        // Silinen Para Hareketler: 13 buraya gelecek
        else if (paramsReport === '14') { await dispatch(getAllServicesByIncomingCalls({ signal: values.signal, start: startdate, end: enddate, id: paramsName, source: paramsSource, brand: paramsBrand })); }
        else if (paramsReport === '15') { await dispatch(getAllServicesByStage({ signal: values.signal, start: startdate, end: enddate, stage: paramsStage })); }
        else if (paramsReport === '16') { await dispatch(getAllServicesByDealer({ signal: values.signal, start: startdate, end: enddate, dealer: paramsDealer })); }
        else if (paramsReport === '17') { await dispatch(getAllServicesByEmergency({ signal: values.signal, start: startdate, end: enddate })); }
        else { await dispatch(getAllServicesByDate({ signal: values.signal, start: startdate, end: enddate })); }
      } catch (error) {
        await dispatch(getAllServicesByDate({ signal: values.signal, start: startdate, end: enddate }));
      }
    }
    else { await dispatch(getAllServicesByDate({ signal: values.signal, start: values.start, end: values.end })); }
  }
  const fetchAllDataSequentially = async (dispatch, signal, start, end) => {
    try {
      if (paramsReport) { await getAllServices('report', { signal }); }
      else { await getAllServices('none', { signal, start, end }); }
      await dispatch(getAllCustomers({ signal }));
      await dispatch(getAllBrands({ signal }));
      await dispatch(getAllDevices({ signal }));
      await dispatch(getAllSources({ signal }));
      await dispatch(getAllVehicles({ signal }));
      await dispatch(getAllStocks({ signal }));
      await dispatch(getAllEmployees({ signal }));
      await dispatch(getAllDealers({ signal }));
      await dispatch(getAllConsigments({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) {
      fetchAllDataSequentially(dispatch, signal, startdate, enddate);
    }
    return () => { controller.abort(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canfetchData, dispatch, startdate, enddate]);
  useEffect(() => {
    if (myUser) {
      setCanFetchData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myUser]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseServiceAddModal();
      dispatch(getAllServicesByDate({ signal: null, start: startdate, end: enddate }));
      dispatch(resetAddStatus());
      dispatch(addToast({ background: 'success', icon: 'add', message: 'Teklif Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseServiceDetailsModal();
      dispatch(getAllServicesByDate({ signal: null, start: startdate, end: enddate }));
      dispatch(resetUpdateStatus());
      dispatch(addToast({ background: 'primary', icon: 'update', message: 'Teklif Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseServiceDeleteModal();
      dispatch(getAllServicesByDate({ signal: null, start: startdate, end: enddate }));
      dispatch(resetDeleteStatus());
      dispatch(addToast({ background: 'danger', icon: 'delete', message: 'Teklif Bilgileri Silindi!', delay: 6000 }));
    }
    const handleReload = async (id) => {
      const controller = new AbortController();
      const { signal } = controller;
      try {
        await dispatch(getAllServicesByDate({ signal, start: startdate, end: enddate }));
        await dispatch(getAllServiceActions({ signal, id: id }));
        await dispatch(getAllServiceOperations({ signal, id: id }));
        await dispatch(addToast({ background: 'success', icon: 'add', message: 'Servis Aksiyonu Eklendi!', delay: 6000 }));
        await dispatch(resetAddServiceActionStatus());
      } catch (error) { throw error; } finally { handleShowServiceDetailsModal(id); }
      return () => { controller.abort(); };
    }
    if (addServiceActionStatus === 'fulfilled') {
      const id = reloadId;
      setReloadId(-1);
      handleReload(id);
    } else if (addServiceActionStatus === 'rejected') {
      dispatch(addToast({ background: 'danger', icon: 'error', message: serviceActionError, delay: 6000 }));
      const id = reloadId;
      setReloadId(-1);
      handleShowServiceDetailsModal(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, addServiceActionStatus, dispatch]);
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      setInputDataForServiceDetailsModal(service);
      setShowServiceDetailsModal(true);
      dispatch(resetgetByIdStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus, dispatch]);
  useEffect(() => {
    if (error && error !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'customer', name: 'MÜŞTERİ' }, { value: 'device', name: 'CİHAZ' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'date', 'customerSearch', 'device', 'statusSearch'];
  const select = (id) => { handleShowServiceDetailsModal(id); }
  const dates = (startDate, endDate) => { setStartdate(startDate); setEnddate(endDate); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <TbFolderCog className='button-icon' />,
      onclick: ((rowData) => { handleShowServiceDetailsModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <TbFolderX className='button-icon' />,
      onclick: ((rowData) => { handleShowServiceDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  const rowcolor = (rowData) => {
    return rowData.status === 'Yeni Servisler' ? 'row-new-service' : rowData.status === 'Parça Hazır' ? 'row-part-ready' : rowData.status === 'Teslimata Hazır (Tamamlandı)' && 'row-delivery-ready';
  };
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={dataToTable}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          setDates={isDate ? { start: startdate, end: enddate } : null}
          dates={isDate ? dates : null}
          singledate={paramsDate && isDate ? true : false}
          search={paramsSearch ? paramsSearch : null}
          buttons={buttons}
          bodyAlignBroker={['customerInfo']}
          colors={rowcolor}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus, dataToTable]);
  // Operations
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowServiceAddModal(); }}>
          <TbFolderPlus style={{ fontSize: '1.5em' }} />
          Yeni Servis Ekle
        </button>
        <button className='dropdown-item' onClick={(e) => { e.stopPropagation(); handleShowServicePlannerModal(); }}>
          <TbFolderCheck style={{ fontSize: '1.5em' }} />
          Servis Planla
        </button>
        <button className='dropdown-item' onClick={(e) => { e.stopPropagation(); handleShowServiceIncomingCallsModal(); }}>
          <TbFolderStar style={{ fontSize: '1.5em' }} />
          Gelen Çağrılar
        </button>
        <button className='dropdown-item' onClick={(e) => { e.stopPropagation(); handleShowBonusCalculatorModal(); }}>
          <MdCurrencyLira style={{ fontSize: '1.5em' }} />
          Prim Hesapla
        </button>
        <button className='dropdown-item' onClick={(e) => { e.stopPropagation(); handleShowServiceReportsModal(); }}>
          <HiOutlineDocumentReport style={{ fontSize: '1.5em' }} />
          Raporlar
        </button>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); dispatch(exportPdf({ rowsPerPage: 10, data: dataToPrint, cols: [], pdftitle: "Servisler" })) }}>
          <TbPrinter style={{ fontSize: '1.5em' }} />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Service Add Modal
  const [showServiceAddModal, setShowServiceAddModal] = useState(false);
  const handleCloseServiceAddModal = () => { setShowServiceAddModal(false) };
  const handleShowServiceAddModal = () => { setShowServiceAddModal(true) };
  const handleAddServiceAddModal = (values) => {
    dispatch(addNewService({ newservice: values }));
    handleCloseServiceAddModal();
  };
  // Service Details Modal
  const [showServiceDetailsModal, setShowServiceDetailsModal] = useState(false);
  const [inputDataForServiceDetailsModal, setInputDataForServiceDetailsModal] = useState({ id: -1 });
  const handleCloseServiceDetailsModal = () => {
    setShowServiceDetailsModal(false);
    setInputDataForServiceDetailsModal({ id: -1 });
  }
  const handleShowServiceDetailsModal = async (id) => {
    await dispatch(getAllServiceActions({ signal: null, id: id }));
    await dispatch(getAllServiceOperations({ signal: null, id: id }));
    await dispatch(getServiceById({ id: id }));
  }
  const handleUpdateServiceDetailsModal = (values) => {
    dispatch(updateService({ service: values }));
    handleCloseServiceDetailsModal();
  };
  const handleAddOperationServiceDetailsModal = async (values) => {
    setReloadId(inputDataForServiceDetailsModal.id);
    handleCloseServiceDetailsModal();
    await dispatch(getUserInfo());
    if (myUser && userinfo) {
      await dispatch(addNewServiceAction({ newserviceaction: { ...values, userId: userinfo.id } }));
    }
  }
  // Service Planner Modal
  const [showServicePlannerModal, setShowServicePlannerModal] = useState(false);
  const handleCloseServicePlannerModal = () => { setShowServicePlannerModal(false) }
  const handleShowServicePlannerModal = () => { setShowServicePlannerModal(true) }
  // Service Incoming Calls Modal
  const [showServiceIncomingCallsModal, setShowServiceIncomingCallsModal] = useState(false);
  const handleCloseServiceIncomingCallsModal = () => { setShowServiceIncomingCallsModal(false) };
  const handleShowServiceIncomingCallsModal = () => { setShowServiceIncomingCallsModal(true) };
  const handleSaveServiceIncomingCallsModal = (values) => {
    console.log(values);
    handleCloseServiceIncomingCallsModal();
  }
  // Bonus Calculator Modal
  const [showBonusCalculatorModal, setShowBonusCalculatorModal] = useState(false);
  const handleCloseBonusCalculatorModal = () => { setShowBonusCalculatorModal(false); }
  const handleShowBonusCalculatorModal = () => { setShowBonusCalculatorModal(true); }
  // Service Reports Modal
  const [showServiceReportsModal, setShowServiceReportsModal] = useState(false);
  const handleCloseServiceReportsModal = () => { setShowServiceReportsModal(false); }
  const handleShowServiceReportsModal = () => { setShowServiceReportsModal(true); }
  // Service Delete Modal
  const [showServiceDeleteModal, setShowServiceDeleteModal] = useState(false);
  const [inputDataForServiceDeleteModal, setInputDataForServiceDeleteModal] = useState(-1);
  const handleCloseServiceDeleteModal = () => {
    setShowServiceDeleteModal(false)
    setInputDataForServiceDeleteModal(-1);
  };
  const handleShowServiceDeleteModal = (id) => {
    setInputDataForServiceDeleteModal(id);
    setShowServiceDeleteModal(true);
  };
  const handleDeleteServiceDeleteModal = () => {
    dispatch(deleteService({ id: inputDataForServiceDeleteModal }));
    handleCloseServiceDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <ServiceAddModal
          show={showServiceAddModal}
          handleClose={handleCloseServiceAddModal}
          handleAdd={handleAddServiceAddModal}
        />
        <ServiceDetailsModal
          show={showServiceDetailsModal}
          handleClose={handleCloseServiceDetailsModal}
          handleUpdate={handleUpdateServiceDetailsModal}
          inputData={inputDataForServiceDetailsModal}
          handleAddOperation={handleAddOperationServiceDetailsModal}
        />
        <ServicePlannerModal
          show={showServicePlannerModal}
          handleClose={handleCloseServicePlannerModal}
        />
        <ServiceIncomingCallsModal
          show={showServiceIncomingCallsModal}
          handleClose={handleCloseServiceIncomingCallsModal}
          handleSave={handleSaveServiceIncomingCallsModal}
        />
        <BonusCalculatorModal
          show={showBonusCalculatorModal}
          handleClose={handleCloseBonusCalculatorModal}
        />
        <ServiceReportsModal
          show={showServiceReportsModal}
          handleClose={handleCloseServiceReportsModal}
        />
        <DeleteModal
          show={showServiceDeleteModal}
          handleClose={handleCloseServiceDeleteModal}
          handleDelete={handleDeleteServiceDeleteModal}
          title={"Servisi Silmek İstiyor Musunuz?"}
          message={`${inputDataForServiceDeleteModal} id numaralı servisi silmek istiyor musunuz?`}
          deleteIcon={<TbFolderX className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <StandartTablePage title='Servisler' Operations={operations} Table={() => tableContent} Modals={modals} />
  );
}

export default Services;