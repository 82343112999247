// General
import React, { useEffect, useState } from 'react';
// Styles
import { Formik, Form, Field } from 'formik';
import { TextField, Autocomplete } from '@mui/material';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { getDistricts } from '../../../storages/slices/DistrictSlice';
// Context
import { useData } from '../../../contexts/DataContext';
import { useValidations } from '../../../contexts/ValidationContext';
// Icons
import { TbDatabaseShare } from "react-icons/tb";
// Components
import Table from '../components/Table';

function BulkMessages() {
  // Storage Import
  const dispatch = useDispatch();
  const { districts } = useSelector((state) => state.district);
  // Context Import
  const { cities, sidebar, myUser } = useData();
  const { validationSchema_BulkMessages } = useValidations();
  // Variables
  const [canfetchData, setCanFetchData] = useState(false);
  // Filtering
  const [searchStartDate, setSearchStartDate] = useState(new Date().toLocaleDateString());
  const [searchEndDate, setSearchEndDate] = useState(new Date().toLocaleDateString());
  // Variables
  const devices = ['İphone 16', 'İphone 14',];
  const brands = ['Apple', 'Samsung',];
  const sources = ['Web', 'Çağrı Merkezi',];
  const status = ['Tamamlandı', 'Beklemede',];
  const [city, setCity] = useState('İstanbul');
  const [district, setDistrict] = useState('');
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getDistricts({ signal, city: city, }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser && city) { setCanFetchData(true); } }, [myUser, city]);
  // Formik Variables
  const initialValues = {
    startdate: '',
    enddate: '',
    city: 'İstanbul',
    district: '',
    brand: '',
    device: '',
    source: '',
    status: '',
    message: ''
  };
  //  Data & Api
  const data = [
    { id: 1001, customerName: 'Emir ', City: 'İstanbul', District: "Beylikdüzü", Phone: '05555555555', Device: 'Mobil' }, { id: 1002, customerName: 'Furkan ', City: 'İstanbul', District: "Beylikdüzü", Phone: '05555555555', Device: 'Mobil' }, { id: 1003, customerName: 'İsa ', City: 'İstanbul', District: "Beylikdüzü", Phone: '05555555555', Device: 'Mobil' }];
  // Table Content
  const cols = [{ value: 'customerName', name: 'MÜŞTERİ ADI' }, { value: 'City', name: 'İL' }, { value: 'District', name: 'İLÇE' }, { value: 'Phone', name: 'TELEFON' }, { value: 'Device', name: 'CİHAZ' }];
  // Local Functions
  const handleSendMessage = (values) => {
    console.log("Mesas içeriği ", values);
    // addToast({ background: 'success', message: 'Toplu Mesaj Gönderildi!', delay: 6000 });
  }
  // HTML
  return (
    <div>
      <div className='row'>
        <div className='col-12 col-md-12'>
          <div className='card shadow mt-3'>
            <div className='card-header'>
              <h5 className='card-title mb-0'>Toplu SMS</h5>
            </div>
            <div className='card-body'>
              <Formik validationSchema={validationSchema_BulkMessages} initialValues={initialValues} onSubmit={(values) => { handleSendMessage(values); }}>
                {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2 g-sm-1 g-lg-1 g-xl-1'>
                      <h5 className='mb-0 p-1'> Filtreler</h5>
                      <div className={`col-12 col-sm-${sidebar ? '4' : '3'} col-lg-3 col-xl-3 `}>
                        <Field
                          as={TextField}
                          id='BulkMessages_startDate'
                          name='startdate'
                          label="Başlangıç tarihi"
                          type="date"
                          // value={StringToDate(searchStartDate)}
                          // onChange={(e) => setSearchStartDate(DateToString(e.target.value))}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                          error={touched.startdate && !!errors.startdate}
                          helperText={touched.startdate && errors.startdate}
                        />
                      </div>
                      <div className={`col-12 col-sm-${sidebar ? '4' : '3'} col-lg-3 col-xl-3 `}>
                        <Field
                          as={TextField}
                          id='BulkMessages_endDate'
                          name='enddate'
                          label="Bitiş tarihi"
                          type="date"
                          // value={StringToDate(searchEndDate)}
                          // onChange={(e) => setSearchEndDate(DateToString(e.target.value))}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                          error={touched.enddate && !!errors.enddate}
                          helperText={touched.enddate && errors.enddate}
                        />
                      </div>
                      <div className={`col-12 col-sm-${sidebar ? '4' : '3'} col-lg-3 col-xl-3 `}>
                        <Field
                          as={Autocomplete}
                          id="BulkMessages_city"
                          name="city"
                          options={cities}
                          value={values.city}
                          onChange={(event, newValue) => {
                            setCity(newValue || '');
                            setFieldValue('city', newValue || '');
                            setDistrict('');
                          }}
                          onBlur={() => { setFieldTouched('city', true); }}
                          variant='outlined'
                          size='small'
                          fullWidth
                          renderInput={(params) => <TextField {...params} label="Şehir" />}
                        />
                      </div>
                      <div className={`col-12 col-sm-${sidebar ? '4' : '3'} col-lg-3 col-xl-3 `}>
                        <Field
                          as={Autocomplete}
                          id="BulkMessages_district"
                          name="district"
                          options={districts}
                          value={district || ''}
                          onChange={(event, newValue) => {
                            if (city !== '') {
                              setDistrict(newValue);
                              setFieldValue("district", newValue);
                            }
                          }}
                          onBlur={() => { setFieldTouched('district', true); }}
                          disabled={city === ''}
                          variant='outlined'
                          size='small'
                          fullWidth
                          renderInput={(params) => <TextField {...params} label="İlçe" />}
                        />
                      </div>
                      <div className={`col-12 col-sm-${sidebar ? '4' : '3'} col-lg-3 col-xl-3 `}>
                        <Field
                          as={Autocomplete}
                          id="BulkMessages_brand"
                          name="brand"
                          options={brands.map((option) => option)}
                          value={values.brand}
                          onChange={(event, newValue) => { setFieldValue('brand', newValue); }}
                          onBlur={() => { setFieldTouched('brand', true); }}
                          variant='outlined'
                          size='small'
                          fullWidth
                          renderInput={(params) => <TextField {...params} label="Marka" />}
                        />
                      </div>
                      <div className={`col-12 col-sm-${sidebar ? '4' : '3'} col-lg-3 col-xl-3 `}>
                        <Field
                          as={Autocomplete}
                          id="BulkMessages_device"
                          name="device"
                          options={devices.map((option) => option)}
                          value={values.device}
                          onChange={(event, newValue) => { setFieldValue('device', newValue); }}
                          onBlur={() => { setFieldTouched('device', true); }}
                          variant='outlined'
                          size='small'
                          fullWidth
                          renderInput={(params) => <TextField {...params} label="Cihaz" />}
                        />
                      </div>
                      <div className={`col-12 col-sm-${sidebar ? '6' : '3'} col-lg-3 col-xl-3 `}>
                        <Field
                          as={Autocomplete}
                          id="BulkMessages_source"
                          name="source"
                          options={sources.map((option) => option)}
                          value={values.source}
                          onChange={(event, newValue) => { setFieldValue('source', newValue); }}
                          onBlur={() => { setFieldTouched('source', true); }}
                          variant='outlined'
                          size='small'
                          fullWidth
                          renderInput={(params) => <TextField {...params} label="Kaynak" />}
                        />
                      </div>
                      <div className={`col-12 col-sm-${sidebar ? '6' : '3'} col-lg-3 col-xl-3 `}>
                        <Field
                          as={Autocomplete}
                          id="BulkMessages_status"
                          name="status"
                          options={status.map((option) => option)}
                          value={values.status}
                          onChange={(event, newValue) => { setFieldValue('status', newValue); }}
                          onBlur={() => { setFieldTouched('status', true); }}
                          variant='outlined'
                          size='small'
                          fullWidth
                          renderInput={(params) => <TextField {...params} label="Durum" />}
                        />
                      </div>
                      <div className='row justify-content-end m-0 p-1 '>
                        <div className={`col-12 col-sm-${sidebar ? '6' : '3'} col-lg-3 col-xl-3  m-0 p-0  d-flex justify-content-end mt-1`}>
                          <button type='button' className='button-with-icon btn btn-primary w-100 h-100'>
                            <TbDatabaseShare className='button-icon' />Veri Getir
                          </button>
                        </div>
                      </div>
                      <h5 className='mb-0 pt-0 pe-1 px-1 pb-1 mt-3 mt-sm-1 mt-lg-1 mt-xl-1'> Mesaj İçeriği</h5>
                      <div className="col-12 col-md-12">
                        <Field
                          as={TextField}
                          id='BulkMessages_message'
                          name='message'
                          label='Mesaj'
                          slotProps={{ input: { resize: "none" } }}
                          variant="outlined"
                          size="small"
                          fullWidth
                          multiline
                          minRows={4}
                          error={touched.message && !!errors.message}
                          helperText={touched.message && errors.message}
                          onFocus={() => { setFieldTouched('message', true); }}
                        />
                      </div>
                      <h5 className='mb-0 p-1 mt-3'> Filtrelenen Tablo</h5>
                      <Table
                        data={data}
                        rows={5}
                        columns={cols}
                        headerAlign='center'
                      />
                      <div className="  d-flex justify-content-end align-items-center w-100">
                        <div className={`col-12 col-sm-${sidebar ? '6' : '3'} col-lg-3 col-xl-3 mt-0 me-sm-2 me-lg-2 me-xl-2`}>
                          <button type='submit' className="btn btn-success w-100">Mesajı Gönder</button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BulkMessages;