// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { Autocomplete, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
// Storages
import { useSelector } from 'react-redux';
// Contexts
import { useData } from '../../../../contexts/DataContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
// Icons
import { TbDatabaseShare } from "react-icons/tb";

function ServiceReportsModal({ show, handleClose }) {
  // Storage Import
  const { employees } = useSelector((state) => state.employee);
  const { dealers } = useSelector((state) => state.dealer);
  const { vehicles } = useSelector((state) => state.vehicle);
  const { devices } = useSelector((state) => state.device);
  const { sources } = useSelector((state) => state.source);
  const { brands } = useSelector((state) => state.brand);
  // Context Import
  const { today, serviceOperations } = useData();
  const { openNewPage } = useFunctions();
  // Nullable Validations
  const validationSchema_1 = Yup.object().shape({ employee: Yup.string().required("Lütfen bir geçerli bir operatör seçiniz") });
  const validationSchema_2 = Yup.object().shape({ employee: Yup.object().required("Lütfen bir geçerli bir teknisyen seçiniz") });
  // HTML
  return (
    <Modal show={show} onHide={handleClose} className='modal'>
      <Modal.Header className='py-1' closeButton>
        <Modal.Title> Servis Raporları</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Operatör Arama</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={0} initialValues={{ report: 1, startdate: today, enddate: today, employee: '' }} validationSchema={validationSchema_1} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-12">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue)}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={employees.map(item => item.name)}
                          renderInput={(params) => <TextField {...params} label="Operatör" className="custom-textfield-autocomplete" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Teknisyen Arama</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={1} initialValues={{ report: 2, date: today, vehicle: '', employee: null, helperemployee: '' }} validationSchema={validationSchema_2} onSubmit={(values) => openNewPage("servisler", { ...values, employee: values.employee.id })}>
                {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="date"
                          name="date"
                          type='date'
                          className="custom-textfield"
                          label="Tarih"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='vehicle'
                          name='vehicle'
                          value={values.vehicle}
                          onChange={(event, newValue) => setFieldValue('vehicle', newValue)}
                          onBlur={() => { setFieldTouched('vehicle', true); }}
                          variant='outlined'
                          size='small'
                          options={vehicles.map(item => item.plate)}
                          renderInput={(params) => <TextField {...params} label="Araç" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue)}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={employees}
                          getOptionLabel={(option) => option && option.id ? `${option.id} - ${option.name}` : ''}
                          renderOption={(props, option) => option ? (
                            <li {...props} key={option.id}>
                              {`${option.id} - ${option.name}`}
                            </li>
                          ) : ''}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => <TextField {...params} label="Teknisyen" className="custom-textfield-autocomplete" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='helperemployee'
                          name='helperemployee'
                          value={values.helperemployee}
                          onChange={(event, newValue) => setFieldValue('helperemployee', newValue)}
                          onBlur={() => { setFieldTouched('helperemployee', true); }}
                          variant='outlined'
                          size='small'
                          options={employees.map(item => item.plate)}
                          renderInput={(params) => <TextField {...params} label="Yardımcı Teknisyen" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Atölyeye Alınanlar</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={2} initialValues={{ report: 3, startdate: today, enddate: today, employee: null, device: '' }} validationSchema={validationSchema_2} onSubmit={(values) => openNewPage("servisler", { ...values, employee: values.employee.id })}>
                {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue)}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={employees}
                          getOptionLabel={(option) => option && option.id ? `${option.id} - ${option.name}` : ''}
                          renderOption={(props, option) => option ? (
                            <li {...props} key={option.id}>
                              {`${option.id} - ${option.name}`}
                            </li>
                          ) : ''}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => <TextField {...params} label="Personel" className="custom-textfield-autocomplete" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='device'
                          name='device'
                          value={values.device}
                          onChange={(event, newValue) => setFieldValue('device', newValue)}
                          onBlur={() => { setFieldTouched('device', true); }}
                          variant='outlined'
                          size='small'
                          options={devices.map(item => item.name)}
                          renderInput={(params) => <TextField {...params} label="Cihaz" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Atölye Ustası Arama</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={3} initialValues={{ report: 4, date: today, employee: null }} validationSchema={validationSchema_2} onSubmit={(values) => openNewPage("servisler", { ...values, employee: values.employee.id })}>
                {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="date"
                          name="date"
                          type='date'
                          className="custom-textfield"
                          label="Tarih"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue)}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={employees}
                          getOptionLabel={(option) => option && option.id ? `${option.id} - ${option.name}` : ''}
                          renderOption={(props, option) => option ? (
                            <li {...props} key={option.id}>
                              {`${option.id} - ${option.name}`}
                            </li>
                          ) : ''}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => <TextField {...params} label="Atölye Ustası" className="custom-textfield-autocomplete" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Atölyede Tamir Edilen Servisler</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={4} initialValues={{ report: 5, date: today, employee: null }} validationSchema={validationSchema_2} onSubmit={(values) => openNewPage("servisler", { ...values, employee: values.employee.id })}>
                {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="date"
                          name="date"
                          type='date'
                          className="custom-textfield"
                          label="Tarih"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue)}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={employees}
                          getOptionLabel={(option) => option && option.id ? `${option.id} - ${option.name}` : ''}
                          renderOption={(props, option) => option ? (
                            <li {...props} key={option.id}>
                              {`${option.id} - ${option.name}`}
                            </li>
                          ) : ''}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => <TextField {...params} label="Atölye Ustası" className="custom-textfield-autocomplete" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Nakliye Gönder</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={5} initialValues={{ report: 6, date: today, employee: null }} validationSchema={validationSchema_2} onSubmit={(values) => openNewPage("servisler", { ...values, employee: values.employee.id })}>
                {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="date"
                          name="date"
                          type='date'
                          className="custom-textfield"
                          label="Tarih"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue)}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={employees}
                          getOptionLabel={(option) => option && option.id ? `${option.id} - ${option.name}` : ''}
                          renderOption={(props, option) => option ? (
                            <li {...props} key={option.id}>
                              {`${option.id} - ${option.name}`}
                            </li>
                          ) : ''}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => <TextField {...params} label="Personel" className="custom-textfield-autocomplete" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header> Nakliyede (Teslim Edilecek)</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={6} initialValues={{ report: 7, date: today, employee: null }} validationSchema={validationSchema_2} onSubmit={(values) => openNewPage("servisler", { ...values, employee: values.employee.id })}>
                {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="date"
                          name="date"
                          type='date'
                          className="custom-textfield"
                          label="Tarih"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue)}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={employees}
                          getOptionLabel={(option) => option && option.id ? `${option.id} - ${option.name}` : ''}
                          renderOption={(props, option) => option ? (
                            <li {...props} key={option.id}>
                              {`${option.id} - ${option.name}`}
                            </li>
                          ) : ''}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => <TextField {...params} label="Personel" className="custom-textfield-autocomplete" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header> Cihaz Teslim Edildi</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={7} initialValues={{ report: 8, date: today, employee: null }} validationSchema={validationSchema_2} onSubmit={(values) => openNewPage("servisler", { ...values, employee: values.employee.id })}>
                {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="date"
                          name="date"
                          type='date'
                          className="custom-textfield"
                          label="Tarih"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue)}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={employees}
                          getOptionLabel={(option) => option && option.id ? `${option.id} - ${option.name}` : ''}
                          renderOption={(props, option) => option ? (
                            <li {...props} key={option.id}>
                              {`${option.id} - ${option.name}`}
                            </li>
                          ) : ''}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => <TextField {...params} label="Personel" className="custom-textfield-autocomplete" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header> Parça Bekleyen Servisler</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={8} initialValues={{ report: 9, date: today }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12">
                        <Field
                          as={TextField}
                          id="date"
                          name="date"
                          type='date'
                          className="custom-textfield"
                          label="Tarihten Beri Parça Bekleyen"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header> Ürün Satışı Yapıldı</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={9} initialValues={{ report: 10, startdate: today, enddate: today }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>Konsinye Cihazlar</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={10} initialValues={{ report: 11, brand: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12">
                        <Field
                          as={Autocomplete}
                          id='brand'
                          name='brand'
                          value={values.brand}
                          onChange={(event, newValue) => setFieldValue('brand', newValue)}
                          onBlur={() => { setFieldTouched('brand', true); }}
                          variant='outlined'
                          size='small'
                          options={brands.map(item => item.name)}
                          renderInput={(params) => <TextField {...params} label="Marka" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="11">
            <Accordion.Header>Silinen Servisler</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={11} initialValues={{ report: 12, startdate: today, enddate: today }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="12">
            <Accordion.Header>Silinen Para Hareketleri</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={12} initialValues={{ report: 13, startdate: today, enddate: today }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="13">
            <Accordion.Header>Gelen Çağrılar</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={13} initialValues={{ report: 14, startdate: today, enddate: today, employee: null, source: '', brand: '' }} validationSchema={validationSchema_2} onSubmit={(values) => openNewPage("servisler", { ...values, employee: values.employee.id })}>
                {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-12">
                        <Field
                          as={Autocomplete}
                          id='employee'
                          name='employee'
                          value={values.employee}
                          onChange={(event, newValue) => setFieldValue('employee', newValue)}
                          onBlur={() => { setFieldTouched('employee', true); }}
                          variant='outlined'
                          size='small'
                          options={employees}
                          getOptionLabel={(option) => option && option.id ? `${option.id} - ${option.name}` : ''}
                          renderOption={(props, option) => option ? (
                            <li {...props} key={option.id}>
                              {`${option.id} - ${option.name}`}
                            </li>
                          ) : ''}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => <TextField {...params} label="Personel" className="custom-textfield-autocomplete" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='source'
                          name='source'
                          value={values.source === '' ? 'Tüm Kaynaklar' : values.source}
                          onChange={(event, newValue) => setFieldValue('source', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('source', true); }}
                          variant='outlined'
                          size='small'
                          options={sources.map(item => item.name)}
                          renderInput={(params) => <TextField {...params} label="Kaynak" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={Autocomplete}
                          id='brand'
                          name='brand'
                          value={values.brand === '' ? 'Tüm Markalar' : values.brand}
                          onChange={(event, newValue) => setFieldValue('brand', newValue ? newValue : '')}
                          onBlur={() => { setFieldTouched('brand', true); }}
                          variant='outlined'
                          size='small'
                          options={brands.map(item => item.name)}
                          renderInput={(params) => <TextField {...params} label="Marka" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="14">
            <Accordion.Header>Aşama Arama</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={14} initialValues={{ report: 15, startdate: today, enddate: today, stage: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-12">
                        <Field
                          as={Autocomplete}
                          id='stage'
                          name='stage'
                          value={values.stage}
                          onChange={(event, newValue) => setFieldValue('stage', newValue)}
                          onBlur={() => { setFieldTouched('stage', true); }}
                          variant='outlined'
                          size='small'
                          options={serviceOperations}
                          renderInput={(params) => <TextField {...params} label="Aşama" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="15">
            <Accordion.Header>Bayi Arama</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={15} initialValues={{ report: 16, startdate: today, enddate: today, dealer: '' }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-12">
                        <Field
                          as={Autocomplete}
                          id='dealer'
                          name='dealer'
                          value={values.dealer}
                          onChange={(event, newValue) => setFieldValue('dealer', newValue)}
                          onBlur={() => { setFieldTouched('dealer', true); }}
                          variant='outlined'
                          size='small'
                          options={dealers.map(item => item.name)}
                          renderInput={(params) => <TextField {...params} label="Bayi" className="custom-textfield-autocomplete" />}
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="16">
            <Accordion.Header>Acil Arama</Accordion.Header>
            <Accordion.Body className='pb-0'>
              <Formik key={16} initialValues={{ report: 17, startdate: today, enddate: today }} onSubmit={(values) => openNewPage("servisler", values)}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className='row g-2'>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="startdate"
                          name="startdate"
                          type='date'
                          className="custom-textfield"
                          label="Başlangıç Tarihi"
                          value={values.startdate}
                          onChange={(e) => setFieldValue('startdate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <Field
                          as={TextField}
                          id="enddate"
                          name="enddate"
                          type='date'
                          className="custom-textfield"
                          label="Bitiş Tarihi"
                          value={values.enddate}
                          onChange={(e) => setFieldValue('enddate', e.target.value)}
                          InputLabelProps={{ shrink: true, }}
                          variant='outlined'
                          size='small'
                          fullWidth
                        />
                      </div>
                      <div className='col-12'>
                        <button type='submit' className='button-with-icon btn btn-primary w-100 py-1'><TbDatabaseShare className='button-icon' />Filtrele</button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
    </Modal >
  );
}

export default ServiceReportsModal;