// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { getAllDealers, getDealerById, addNewDealer, updateDealer, deleteDealer, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetGetByIdStatus, resetError } from '../../../storages/slices/backend/DealerSlice';
// Context
import { useData } from '../../../contexts/DataContext';
// Icons
import { BsFillHouseAddFill, BsFillHouseGearFill, BsFillHouseDashFill } from "react-icons/bs";
import { TbPrinter } from "react-icons/tb";
// Modals
import DealerAddModal from "../modals/dealers/DealerAddModal";
import DealerUpdateModal from "../modals/dealers/DealerUpdateModal";
import DealerDetailModal from '../modals/dealers/DealerDetailModal';
import DeleteModal from "../modals/DeleteModal";
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';

function Dealers() {
  // Storage Import
  const dispatch = useDispatch();
  const { dealers, dealer, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.dealer);
  // Context Import
  const { myUser, statusTranslator } = useData();
  // Variables
  const [canfetchData, setCanFetchData] = useState(false);
  // Data & Api
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllDealers({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseDealerAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllDealers({}));
      dispatch(addToast({ background: 'success', icon: 'houseAdd', message: 'Yeni Bayi Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseDealerUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllDealers({}));
      dispatch(addToast({ background: 'primary', icon: 'houseUpdate', message: 'Bayi Bilgileri Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleDeleteDealerDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllDealers({}));
      dispatch(addToast({ background: 'danger', icon: 'houseDelete', message: 'Bayi Silindi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataForDealerUpdateModal(dealer);
        setShowDealerUpdateModal(true);
        setDataRequester('');
        dispatch(resetGetByIdStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataForDealerDeleteModal({ id: dealer.id, name: dealer.name });
        setShowDealerDeleteModal(true);
        setDataRequester('');
        dispatch(resetGetByIdStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus, dispatch]);
  useEffect(() => {
    if (error && error !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'name', name: 'BAYİ ADI' }, { value: 'status', name: 'DURUM' }];
  const filters = ['id', 'name', 'status'];
  const select = (id) => {
    handleShowDealerUpdateModal(id);
  }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <BsFillHouseGearFill className='button-icon' />,
      onclick: ((rowData) => { handleShowDealerUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Detay',
      icon: <BsFillHouseGearFill className='button-icon' />,
      onclick: ((rowData) => { }),
      variant: 'warning'
    },
    {
      text: 'Sil',
      icon: <BsFillHouseDashFill className='button-icon' />,
      onclick: ((rowData) => { handleShowDealerDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={dealers}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowDealerAddModal(); }}><BsFillHouseAddFill className='button-icon' /> Yeni Bayi Ekle</button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: dealers, cols: cols, pdftitle: "Bayiler" })) }}>
          <TbPrinter className='button-icon' />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Dealer Add Modal
  const [showDealerAddModal, setShowDealerAddModal] = useState(false);
  const handleCloseDealerAddModal = () => { setShowDealerAddModal(false) };
  const handleShowDealerAddModal = () => { setShowDealerAddModal(true) };
  const handleAddDealerAddModal = (values) => {
    dispatch(addNewDealer({ newdealer: { ...values, status: statusTranslator[values.status] } }));
    handleCloseDealerAddModal();
  };
  // Dealer Update Modal
  const [showDealerUpdateModal, setShowDealerUpdateModal] = useState(false);
  const [inputDataForDealerUpdateModal, setInputDataForDealerUpdateModal] = useState({});
  const handleCloseDealerUpdateModal = () => {
    setShowDealerUpdateModal(false)
    setInputDataForDealerUpdateModal({});
  };
  const handleShowDealerUpdateModal = (id) => {
    dispatch(getDealerById({ id: id }));
    setDataRequester('update');
  }
  const handleUpdateDealerUpdateModal = (values) => {
    dispatch(updateDealer({ dealer: { ...values, status: statusTranslator[values.status] } }));
    handleCloseDealerUpdateModal();
  };
  // Dealer Details Modal
  const [showDealerDetailModal, setShowDealerDetailModal] = useState(false);
  const [inputDataForDetailUpdateModal, setInputDataForDetailUpdateModal] = useState({});
  const handleCloseDealerDetailModal = () => {
    setShowDealerDetailModal(false)
    setInputDataForDetailUpdateModal({});
  };
  // Dealer Delete Modal
  const [showDealerDeleteModal, setShowDealerDeleteModal] = useState(false);
  const [inputDataForDealerDeleteModal, setInputDataForDealerDeleteModal] = useState(-1);
  const handleCloseDealerDeleteModal = () => {
    setShowDealerDeleteModal(false)
    setInputDataForDealerDeleteModal(-1);
  };
  const handleShowDealerDeleteModal = (id) => {
    dispatch(getDealerById({ id: id }));
    setDataRequester('delete');
  };
  const handleDeleteDealerDeleteModal = () => {
    dispatch(deleteDealer({ id: inputDataForDealerDeleteModal.id }));
    handleCloseDealerDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <DealerAddModal
          show={showDealerAddModal}
          handleClose={handleCloseDealerAddModal}
          handleAdd={handleAddDealerAddModal}
        />
        <DealerUpdateModal
          show={showDealerUpdateModal}
          handleClose={handleCloseDealerUpdateModal}
          handleUpdate={handleUpdateDealerUpdateModal}
          inputData={inputDataForDealerUpdateModal}
        />
        <DealerDetailModal
          show={showDealerDetailModal}
          handleClose={handleCloseDealerDetailModal}
          inputData={inputDataForDetailUpdateModal}
        />
        <DeleteModal
          show={showDealerDeleteModal}
          handleClose={handleCloseDealerDeleteModal}
          handleDelete={handleDeleteDealerDeleteModal}
          title="Bayiyi Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForDealerDeleteModal.name} adlı bayiyi silmek istediğinize emin misiniz?`}
          deleteIcon={<BsFillHouseDashFill className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage title='Bayiler' Operations={operations} Table={() => tableContent} Modals={modals} />
  );
}

export default Dealers;