// General
import React, { useEffect, useRef, useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { getServiceById } from '../../../../storages/slices/backend/ServiceSlice';

// Contexts
import { useValidations } from '../../../../contexts/ValidationContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
import { useData } from '../../../../contexts/DataContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { RiFolderSettingsLine } from "react-icons/ri";

function OperationReportsAddModal({ show, handleClose, handleAdd }) {
  // Storage Import
  const dispatch = useDispatch();
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleInputChange = async (event, newInputValue) => {
    if (!newInputValue) { setFilteredOptions([]); return; }
    const id = /^\d+$/.test(newInputValue);
    if (id) {
      try {
        const response = await dispatch(getServiceById({ id: parseInt(newInputValue) }));
        if (response.payload) {
          setFilteredOptions([response.payload]);
        } else { setFilteredOptions([]); }
      } catch (error) { throw error; }
    }
    else { setFilteredOptions([]); }
  };
  // Context Import
  const { validationSchema_OperationReports } = useValidations();
  const { stringOnlyNumbers } = useFunctions();
  const { today } = useData();
  // Formik Variables
  const initialValues = {
    serviceId: null,
    date: today,
    fault: '',
    transactions: '',
    informations: '',
    total: 0
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header closeButton>
          <Modal.Title>Yeni İşlem Raporu Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik validationSchema={validationSchema_OperationReports} initialValues={initialValues} onSubmit={(values) => handleAdd(values)}>
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-12">
                    <Field
                      as={Autocomplete}
                      id="serviceId"
                      name="serviceId"
                      options={filteredOptions || []}
                      value={values.serviceId}
                      onChange={(event, newValue) => { setFieldValue('serviceId', newValue || ''); }}
                      onInputChange={handleInputChange}
                      onBlur={() => { setFieldTouched('serviceId', true); }}
                      variant='outlined'
                      size='small'
                      getOptionLabel={(option) =>
                        option && option.id
                          ? `${option.id} - ${option.name} (${option.phone1}) - ${option.brandName}/${option.deviceName}`
                          : ''
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {`${option.id} - ${option.name} (${option.phone1}) - ${option.brandName}/${option.deviceName}`}
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) => option.id === value}
                      renderInput={(params) => (
                        <TextField {...params} label="Servis" error={touched.serviceId && !!errors.serviceId} helperText={touched.serviceId && errors.serviceId} />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='OperationReportsAddModal_fault'
                      name='fault'
                      label="Arıza"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={4}
                      error={touched.fault && !!errors.fault}
                      helperText={touched.fault && errors.fault}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='OperationReportsAddModal_transactions'
                      name='transactions'
                      label="Yapılanlar"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={4}
                      error={touched.transactions && !!errors.transactions}
                      helperText={touched.transactions && errors.transactions}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='OperationReportsAddModal_informations'
                      name='informations'
                      label="Bilgiler"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                      variant="outlined"
                      size="small"
                      fullWidth
                      multiline
                      rows={4}
                      error={touched.informations && !!errors.informations}
                      helperText={touched.informations && errors.informations}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='OperationReportsAddModal_total'
                      name='total'
                      type='number'
                      label='Maliyet'
                      value={values.total}
                      onChange={(e) => { setFieldValue('total', parseFloat(stringOnlyNumbers(e.target.value))); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.total && !!errors.total}
                      helperText={touched.total && errors.total}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-success w-100' ><RiFolderSettingsLine className='button-icon' />Ekle</button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div >
  )
}

export default OperationReportsAddModal