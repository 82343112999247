import React from 'react';
// Context
import { useFunctions } from '../../../contexts/FunctionsContext';
// Style
import { TextField } from '@mui/material';
// Icons
import { MdDeleteOutline } from 'react-icons/md';

function ProductDynamicRow({ values, listtitle, setFieldValue, productName, productCount, productPrice, productTotalCount, errors, touched }) {
  const { listDeleteItem } = useFunctions();
  return (
    <div>
      {values[listtitle].map((product, index) => (
        <div key={index} className="row g-2 my-0">
          <div className='col-12 col-md-5'>
            <TextField
              id={productName}
              name={productName}
              label='Ürün Adı'
              value={product[productName]}
              onChange={(e) => setFieldValue(`${listtitle}[${index}].${productName}`, e.target.value)}
              variant='outlined'
              size='small'
              fullWidth
              error={touched[listtitle]?.[index]?.[productName] && !!errors[listtitle]?.[index]?.[productName]}
              helperText={touched[listtitle]?.[index]?.[productName] && errors[listtitle]?.[index]?.[productName]}

            />
          </div>
          <div className='col-12 col-md-2'>
            <TextField
              id={productCount}
              name={productCount}
              label='Ürün Miktarı'
              type='number'
              value={parseFloat(product[productCount])}
              onChange={(e) => { const value = parseFloat(e.target.value); if (value >= 0) { setFieldValue(`${listtitle}[${index}].${productCount}`, value); } }}
              variant='outlined'
              size='small'
              fullWidth
              error={touched[listtitle]?.[index]?.[productCount] && !!errors[listtitle]?.[index]?.[productCount]}
              helperText={touched[listtitle]?.[index]?.[productCount] && errors[listtitle]?.[index]?.[productCount]}
            />
          </div>
          <div className='col-12 col-md-2'>
            <TextField
              id={productPrice}
              name={productPrice}
              label='Ürün Fiyatı'
              type='number'
              value={parseFloat(product[productPrice])}
              onChange={(e) => { const value = parseFloat(e.target.value); if (value >= 0) { setFieldValue(`${listtitle}[${index}].${productPrice}`, value); } }}
              variant='outlined'
              size='small'
              fullWidth
              error={touched[listtitle]?.[index]?.[productPrice] && !!errors[listtitle]?.[index]?.[productPrice]}
              helperText={touched[listtitle]?.[index]?.[productPrice] && errors[listtitle]?.[index]?.[productPrice]}
            />
          </div>
          <div className='col-12 col-md-2'>
            <TextField
              id={productTotalCount}
              name={productTotalCount}
              label='Toplam Fiyat'
              value={product[productTotalCount] = (product[productCount] * product[productPrice])}
              slotProps={{ input: { readOnly: true } }}
              variant='outlined'
              size='small'
              fullWidth
            />
          </div>
          <div className='col-12 col-md-1'>
            <button type='button' className='button-with-icon btn btn-danger w-100' onClick={() => {
              setFieldValue(listtitle, listDeleteItem(values[listtitle], values[listtitle][index].id))
            }}><MdDeleteOutline className='button-icon' />Sil</button>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ProductDynamicRow