// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Storages
import { useSelector } from 'react-redux';
// Contexts
import { useValidations } from '../../../../contexts/ValidationContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
import { useData } from '../../../../contexts/DataContext';
// Icons
import { TbArrowBackUpDouble, TbTruckDelivery } from 'react-icons/tb';

function ConsigmentUpdateModal({ show, handleClose, handleUpdate, inputData }) {
  // Storage Import
  const { devices } = useSelector((state) => state.device);
  const { brands } = useSelector((state) => state.brand);
  // Context Import
  const { validationSchema_Consigment } = useValidations();
  const { stringOnlyNumbers } = useFunctions();
  const { ConsigmentStatus } = useData();
  // Formik Variables
  const initialValues = {
    id: inputData.id || 0,
    model: inputData.model || "",
    deviceName: inputData.deviceName || "",
    brandName: inputData.brandName || "",
    status: inputData.status || "",
    quantity: inputData.quantity || 0,
    description: inputData.description || "",
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header>
          <Modal.Title>Konsinye Cihaz Bilgilerini Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik validationSchema={validationSchema_Consigment} initialValues={initialValues} onSubmit={(values) => handleUpdate(values)} >
            {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='ConsigmentUpdateModal_model'
                      name='model'
                      label='Model Adı '
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.model && !!errors.model}
                      helperText={touched.model && errors.model}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={Autocomplete}
                      id="ConsigmentUpdateModal_deviceName"
                      name="deviceName"
                      options={devices.map(item => item.name)}
                      value={values.deviceName}
                      onChange={(event, newValue) => { setFieldValue('deviceName', newValue); }}
                      onBlur={() => { setFieldTouched('deviceName', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params}
                        label="Cihaz Adı " error={touched.deviceName && !!errors.deviceName}
                        helperText={touched.deviceName && errors.deviceName} />}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={Autocomplete}
                      id="ConsigmentUpdateModal_brandName"
                      name="brandName"
                      options={brands.map(item => item.name)}
                      value={values.brandName}
                      onChange={(event, newValue) => { setFieldValue('brandName', newValue); }}
                      onBlur={() => { setFieldTouched('brandName', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params}
                        label="Marka Adı " error={touched.brandName && !!errors.brandName}
                        helperText={touched.brandName && errors.brandName} />}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={Autocomplete}
                      id="ConsigmentUpdateModal_status"
                      name="status"
                      options={ConsigmentStatus}
                      value={values.status}
                      onChange={(event, newValue) => { setFieldValue('status', newValue); }}
                      onBlur={() => { setFieldTouched('status', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params}
                        label="Durum" error={touched.status && !!errors.status}
                        helperText={touched.status && errors.status} />}
                    />
                  </div>
                  <div className='col-12 col-md-12'>
                    <Field
                      as={TextField}
                      id='ConsigmentUpdateModal_quantity'
                      name='quantity'
                      label='Miktar'
                      type='number'
                      value={parseFloat(values.quantity)}
                      onChange={(e) => { const quantity = stringOnlyNumbers(e.target.value); setFieldValue("quantity", quantity); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.quantity && !!errors.quantity}
                      helperText={touched.quantity && errors.quantity}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='ConsigmentUpdateModal_description'
                      name='description'
                      label="Açıklama"
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, } }}
                      multiline
                      rows={4}
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={touched.description && !!errors.description}
                      helperText={touched.description && errors.description}
                    />
                  </div>

                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                        <TbArrowBackUpDouble className='button-icon' />
                        Geri
                      </button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-success w-100'>
                        <TbTruckDelivery className='button-icon' />
                        Konsinye Cihazı Güncelle
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ConsigmentUpdateModal;