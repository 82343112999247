// General
import React from 'react';
// Styles
import { Autocomplete, TextField } from '@mui/material';
import { Field, Form, Formik } from 'formik';
// Contexts
import { useData } from '../../../contexts/DataContext';
// Icons
import { FaSave } from 'react-icons/fa';

function ServiceOperations({ type, handleAdd, users, dealers, products, vehicles, serviceId }) {
  // Context Import
  const { today } = useData();
  // HTML
  return (
    <>
      {type === '' ? (
        <></>
      ) : type === 'Atölyeye Aldır' ? (
        <Formik key={1} initialValues={{ date: today, starttime: '09:00', endtime: '12:00', desciption: '', offer: 0 }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='date'
                    name='date'
                    label='Alınacak Tarih'
                    type="date"
                    variant='outlined'
                    size='small'
                    value={values.date}
                    onChange={(e) => setFieldValue('date', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                  />
                </div>
                <div className='col-12 col-md-3'>
                  <Field
                    as={TextField}
                    id='starttime'
                    name='starttime'
                    label='Başlangıç'
                    type="time"
                    variant='outlined'
                    size='small'
                    value={values.starttime}
                    onChange={(e) => setFieldValue('starttime', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.starttime && !!errors.starttime}
                    helperText={touched.starttime && errors.starttime}
                  />
                </div>
                <div className='col-12 col-md-3'>
                  <Field
                    as={TextField}
                    id='endtime'
                    name='endtime'
                    label='Bitiş'
                    type="time"
                    variant='outlined'
                    size='small'
                    value={values.endtime}
                    onChange={(e) => setFieldValue('endtime', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.endtime && !!errors.endtime}
                    helperText={touched.endtime && errors.endtime}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='offer'
                    name='offer'
                    label="Verilen Teklif"
                    value={values.offer}
                    type='number'
                    onChange={(e) => setFieldValue('offer', e.target.value >= 0 ? e.target.value : 0)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.offer && !!errors.offer}
                    helperText={touched.offer && errors.offer}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Bayiye Gönder' ? (
        <Formik key={2} initialValues={{ dealer: '', serviceId: serviceId }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={Autocomplete}
                    id='dealer'
                    name='dealer'
                    value={values.dealer}
                    onChange={(event, newValue) => setFieldValue('dealer', newValue)}
                    onBlur={() => { setFieldTouched('dealer', true); }}
                    variant='outlined'
                    size='small'
                    options={dealers}
                    renderInput={(params) => <TextField {...params} label="Bayi" error={touched.dealer && !!errors.dealer} helperText={touched.dealer && errors.dealer} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='serviceId'
                    name='serviceId'
                    label="Servis Id"
                    value={values.serviceId}
                    slotProps={{ input: { readOnly: true } }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.serviceId && !!errors.serviceId}
                    helperText={touched.serviceId && errors.serviceId}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Müşteri Cihazı Atölyeye Getirdi' ? (
        <Formik key={3} initialValues={{ operations: '', offer: 0 }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='operations'
                    name='operations'
                    label="Yapılacak İşlemler"
                    value={values.operations}
                    onChange={(e) => setFieldValue('operations', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.operations && !!errors.operations}
                    helperText={touched.operations && errors.operations}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='offer'
                    name='offer'
                    label="Verilen Teklif"
                    value={values.offer}
                    type='number'
                    onChange={(e) => setFieldValue('offer', e.target.value >= 0 ? e.target.value : 0)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.offer && !!errors.offer}
                    helperText={touched.offer && errors.offer}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Müşteri İptal Etti' ? (
        <Formik key={4} initialValues={{ desciption: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Teknisyen Yönlendir' ? (
        <Formik key={5} initialValues={{ employee: '', helperemployee: '', vehicle: '', date: today, starttime: '09:00', endtime: '12:00' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={Autocomplete}
                    id='employee'
                    name='employee'
                    value={values.employee}
                    onChange={(event, newValue) => setFieldValue('employee', newValue)}
                    onBlur={() => { setFieldTouched('employee', true); }}
                    variant='outlined'
                    size='small'
                    options={users}
                    renderInput={(params) => <TextField {...params} label="Teknisyen" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={Autocomplete}
                    id='helperemployee'
                    name='helperemployee'
                    value={values.helperemployee}
                    onChange={(event, newValue) => setFieldValue('helperemployee', newValue)}
                    onBlur={() => { setFieldTouched('helperemployee', true); }}
                    variant='outlined'
                    size='small'
                    options={users}
                    renderInput={(params) => <TextField {...params} label="Yardımcı Teknisyen" error={touched.helperemployee && !!errors.helperemployee} helperText={touched.helperemployee && errors.helperemployee} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <Field
                    as={Autocomplete}
                    id='vehicle'
                    name='vehicle'
                    value={values.vehicle}
                    onChange={(event, newValue) => setFieldValue('vehicle', newValue)}
                    onBlur={() => { setFieldTouched('vehicle', true); }}
                    variant='outlined'
                    size='small'
                    options={vehicles}
                    renderInput={(params) => <TextField {...params} label="Araç" error={touched.vehicle && !!errors.vehicle} helperText={touched.vehicle && errors.vehicle} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <Field
                    as={TextField}
                    id='date'
                    name='date'
                    label='Gidiş Tarihi'
                    type="date"
                    variant='outlined'
                    size='small'
                    value={values.date}
                    onChange={(e) => setFieldValue('date', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                  />
                </div>
                <div className='col-12 col-md-2'>
                  <Field
                    as={TextField}
                    id='starttime'
                    name='starttime'
                    label='Başlangıç'
                    type="time"
                    variant='outlined'
                    size='small'
                    value={values.starttime}
                    onChange={(e) => setFieldValue('starttime', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.starttime && !!errors.starttime}
                    helperText={touched.starttime && errors.starttime}
                  />
                </div>
                <div className='col-12 col-md-2'>
                  <Field
                    as={TextField}
                    id='endtime'
                    name='endtime'
                    label='Bitiş'
                    type="time"
                    variant='outlined'
                    size='small'
                    value={values.endtime}
                    onChange={(e) => setFieldValue('endtime', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.endtime && !!errors.endtime}
                    helperText={touched.endtime && errors.endtime}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Ürün Satışı Yapıldı' ? (
        <Formik key={6} initialValues={{ desciption: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Cihaz Atölyeye Alındı' ? (
        <Formik key={7} initialValues={{ desciption: '', offer: 0 }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='offer'
                    name='offer'
                    label="Verilen Teklif"
                    value={values.offer}
                    type='number'
                    onChange={(e) => setFieldValue('offer', e.target.value >= 0 ? e.target.value : 0)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.offer && !!errors.offer}
                    helperText={touched.offer && errors.offer}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Cihaz Tamir Edilemiyor' ? (
        <Formik key={8} initialValues={{ cause: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='cause'
                    name='cause'
                    label="Neden"
                    value={values.cause}
                    onChange={(e) => setFieldValue('cause', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.cause && !!errors.cause}
                    helperText={touched.cause && errors.cause}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Fiyatta Anlaşılamadı' ? (
        <Formik key={9} initialValues={{ desciption: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Haber Verecek' ? (
        <Formik key={10} initialValues={{ date: today, starttime: '09:00', endtime: '12:00', desciption: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='date'
                    name='date'
                    label='Aranacak Tarih'
                    type="date"
                    variant='outlined'
                    size='small'
                    value={values.date}
                    onChange={(e) => setFieldValue('date', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                  />
                </div>
                <div className='col-12 col-md-3'>
                  <Field
                    as={TextField}
                    id='starttime'
                    name='starttime'
                    label='Başlangıç'
                    type="time"
                    variant='outlined'
                    size='small'
                    value={values.starttime}
                    onChange={(e) => setFieldValue('starttime', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.starttime && !!errors.starttime}
                    helperText={touched.starttime && errors.starttime}
                  />
                </div>
                <div className='col-12 col-md-3'>
                  <Field
                    as={TextField}
                    id='endtime'
                    name='endtime'
                    label='Bitiş'
                    type="time"
                    variant='outlined'
                    size='small'
                    value={values.endtime}
                    onChange={(e) => setFieldValue('endtime', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.endtime && !!errors.endtime}
                    helperText={touched.endtime && errors.endtime}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Müşteriye Ulaşılamadı' ? (
        <Formik key={11} initialValues={{ desciption: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Parça Takmak İçin Teknisyene Yönlendir' ? (
        <Formik key={12} initialValues={{ warehouseman: '', date: today, desciption: '', offer: 0 }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={Autocomplete}
                    id='warehouseman'
                    name='warehouseman'
                    value={values.warehouseman}
                    onChange={(event, newValue) => setFieldValue('warehouseman', newValue)}
                    onBlur={() => { setFieldTouched('warehouseman', true); }}
                    variant='outlined'
                    size='small'
                    options={users}
                    renderInput={(params) => <TextField {...params} label="Depocu" error={touched.warehouseman && !!errors.warehouseman} helperText={touched.warehouseman && errors.warehouseman} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='date'
                    name='date'
                    label='Gidilecek Tarih'
                    type="date"
                    variant='outlined'
                    size='small'
                    value={values.date}
                    onChange={(e) => setFieldValue('date', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='offer'
                    name='offer'
                    label="Verilen Teklif"
                    value={values.offer}
                    type='number'
                    onChange={(e) => setFieldValue('offer', e.target.value >= 0 ? e.target.value : 0)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.offer && !!errors.offer}
                    helperText={touched.offer && errors.offer}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Parça Talep Et' ? (
        <Formik key={13} initialValues={{ warehouseman: '', desciption: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={Autocomplete}
                    id='warehouseman'
                    name='warehouseman'
                    value={values.warehouseman}
                    onChange={(event, newValue) => setFieldValue('warehouseman', newValue)}
                    onBlur={() => { setFieldTouched('warehouseman', true); }}
                    variant='outlined'
                    size='small'
                    options={users}
                    renderInput={(params) => <TextField {...params} label="Depocu" error={touched.warehouseman && !!errors.warehouseman} helperText={touched.warehouseman && errors.warehouseman} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Parçası Atölyeye Alındı' ? (
        <Formik key={14} initialValues={{ warehouseman: '', operations: '', offer: 0 }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-4'>
                  <Field
                    as={Autocomplete}
                    id='warehouseman'
                    name='warehouseman'
                    value={values.warehouseman}
                    onChange={(event, newValue) => setFieldValue('warehouseman', newValue)}
                    onBlur={() => { setFieldTouched('warehouseman', true); }}
                    variant='outlined'
                    size='small'
                    options={users}
                    renderInput={(params) => <TextField {...params} label="Depocu" error={touched.warehouseman && !!errors.warehouseman} helperText={touched.warehouseman && errors.warehouseman} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <Field
                    as={TextField}
                    id='operations'
                    name='operations'
                    label="Yapılacak İşlemler"
                    value={values.operations}
                    onChange={(e) => setFieldValue('operations', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.operations && !!errors.operations}
                    helperText={touched.operations && errors.operations}
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <Field
                    as={TextField}
                    id='offer'
                    name='offer'
                    label="Verilen Teklif"
                    value={values.offer}
                    type='number'
                    onChange={(e) => setFieldValue('offer', e.target.value >= 0 ? e.target.value : 0)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.offer && !!errors.offer}
                    helperText={touched.offer && errors.offer}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Ürün Garantili Çıktı' ? (
        <Formik key={15} initialValues={{ desciption: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Yeniden Teknisyen Yönlendir' ? (
        <Formik key={16} initialValues={{ date: today, desciption: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='date'
                    name='date'
                    label='Gidilecek Tarih'
                    type="date"
                    variant='outlined'
                    size='small'
                    value={values.date}
                    onChange={(e) => setFieldValue('date', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Yerinde Bakım Yapıldı' ? (
        <Formik key={17} initialValues={{ desciption: '', offer: 0, product: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-4'>
                  <Field
                    as={TextField}
                    id='operations'
                    name='operations'
                    label="Yapılan İşlemler"
                    value={values.operations}
                    onChange={(e) => setFieldValue('operations', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.operations && !!errors.operations}
                    helperText={touched.operations && errors.operations}
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <Field
                    as={TextField}
                    id='offer'
                    name='offer'
                    label="Verilen Teklif"
                    value={values.offer}
                    type='number'
                    onChange={(e) => setFieldValue('offer', e.target.value >= 0 ? e.target.value : 0)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.offer && !!errors.offer}
                    helperText={touched.offer && errors.offer}
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <Field
                    as={Autocomplete}
                    id='product'
                    name='product'
                    value={values.product}
                    onChange={(event, newValue) => setFieldValue('product', newValue)}
                    onBlur={() => { setFieldTouched('product', true); }}
                    variant='outlined'
                    size='small'
                    options={products}
                    renderInput={(params) => <TextField {...params} label="Ürün" error={touched.product && !!errors.product} helperText={touched.product && errors.product} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Fiyat Yükseltildi' ? (
        <Formik key={18} initialValues={{ desciption: '', offer: 0 }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='offer'
                    name='offer'
                    label="Alınacak Ücret"
                    value={values.offer}
                    type='number'
                    onChange={(e) => setFieldValue('offer', e.target.value >= 0 ? e.target.value : 0)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.offer && !!errors.offer}
                    helperText={touched.offer && errors.offer}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Nakliye Gönder' ? (
        <Formik key={19} initialValues={{ employee: '', helperemployee: '', vehicle: '', date: today, starttime: '09:00', endtime: '12:00' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={Autocomplete}
                    id='employee'
                    name='employee'
                    value={values.employee}
                    onChange={(event, newValue) => setFieldValue('employee', newValue)}
                    onBlur={() => { setFieldTouched('employee', true); }}
                    variant='outlined'
                    size='small'
                    options={users}
                    renderInput={(params) => <TextField {...params} label="Teknisyen" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={Autocomplete}
                    id='helperemployee'
                    name='helperemployee'
                    value={values.helperemployee}
                    onChange={(event, newValue) => setFieldValue('helperemployee', newValue)}
                    onBlur={() => { setFieldTouched('helperemployee', true); }}
                    variant='outlined'
                    size='small'
                    options={users}
                    renderInput={(params) => <TextField {...params} label="Yardımcı Teknisyen" error={touched.helperemployee && !!errors.helperemployee} helperText={touched.helperemployee && errors.helperemployee} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <Field
                    as={Autocomplete}
                    id='vehicle'
                    name='vehicle'
                    value={values.vehicle}
                    onChange={(event, newValue) => setFieldValue('vehicle', newValue)}
                    onBlur={() => { setFieldTouched('vehicle', true); }}
                    variant='outlined'
                    size='small'
                    options={vehicles}
                    renderInput={(params) => <TextField {...params} label="Araç" error={touched.vehicle && !!errors.vehicle} helperText={touched.vehicle && errors.vehicle} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <Field
                    as={TextField}
                    id='date'
                    name='date'
                    label='Gidiş Tarihi'
                    type="date"
                    variant='outlined'
                    size='small'
                    value={values.date}
                    onChange={(e) => setFieldValue('date', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                  />
                </div>
                <div className='col-12 col-md-2'>
                  <Field
                    as={TextField}
                    id='starttime'
                    name='starttime'
                    label='Başlangıç'
                    type="time"
                    variant='outlined'
                    size='small'
                    value={values.starttime}
                    onChange={(e) => setFieldValue('starttime', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.starttime && !!errors.starttime}
                    helperText={touched.starttime && errors.starttime}
                  />
                </div>
                <div className='col-12 col-md-2'>
                  <Field
                    as={TextField}
                    id='endtime'
                    name='endtime'
                    label='Bitiş'
                    type="time"
                    variant='outlined'
                    size='small'
                    value={values.endtime}
                    onChange={(e) => setFieldValue('endtime', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.endtime && !!errors.endtime}
                    helperText={touched.endtime && errors.endtime}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Tahsilata Gönder' ? (
        <Formik key={20} initialValues={{ date: today, employee: '', offer: 0, desciption: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='date'
                    name='date'
                    label='Gidilecek Tarih'
                    type="date"
                    variant='outlined'
                    size='small'
                    value={values.date}
                    onChange={(e) => setFieldValue('date', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={Autocomplete}
                    id='employee'
                    name='employee'
                    value={values.employee}
                    onChange={(event, newValue) => setFieldValue('employee', newValue)}
                    onBlur={() => { setFieldTouched('employee', true); }}
                    variant='outlined'
                    size='small'
                    options={users}
                    renderInput={(params) => <TextField {...params} label="Personel" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='offer'
                    name='offer'
                    label="Fiyat"
                    value={values.offer}
                    type='number'
                    onChange={(e) => setFieldValue('offer', e.target.value >= 0 ? e.target.value : 0)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.offer && !!errors.offer}
                    helperText={touched.offer && errors.offer}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Atölyede Tamir Ediliyor' ? (
        <Formik key={21} initialValues={{ desciption: '', employee: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={Autocomplete}
                    id='employee'
                    name='employee'
                    value={values.employee}
                    onChange={(event, newValue) => setFieldValue('employee', newValue)}
                    onBlur={() => { setFieldTouched('employee', true); }}
                    variant='outlined'
                    size='small'
                    options={users}
                    renderInput={(params) => <TextField {...params} label="Tamir Eden Usta" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Nakliyede (Teslim Edilecek)' ? (
        <Formik key={22} initialValues={{ employee: '', date: today, desciption: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={Autocomplete}
                    id='employee'
                    name='employee'
                    value={values.employee}
                    onChange={(event, newValue) => setFieldValue('employee', newValue)}
                    onBlur={() => { setFieldTouched('employee', true); }}
                    variant='outlined'
                    size='small'
                    options={users}
                    renderInput={(params) => <TextField {...params} label="Nakil Eden Personel" error={touched.employee && !!errors.employee} helperText={touched.employee && errors.employee} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='date'
                    name='date'
                    label='Nakil Tarihi'
                    type="date"
                    variant='outlined'
                    size='small'
                    value={values.date}
                    onChange={(e) => setFieldValue('date', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Cihaz Teslim Edilemedi (Arızalı)' ? (
        <Formik key={23} initialValues={{ desciption: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Cihaz Teslim Edildi (Parça Takıldı)' ? (
        <Formik key={24} initialValues={{ desciption: '', offer: 0, product: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-4'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <Field
                    as={TextField}
                    id='offer'
                    name='offer'
                    label="Verilen Teklif"
                    value={values.offer}
                    type='number'
                    onChange={(e) => setFieldValue('offer', e.target.value >= 0 ? e.target.value : 0)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.offer && !!errors.offer}
                    helperText={touched.offer && errors.offer}
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <Field
                    as={Autocomplete}
                    id='product'
                    name='product'
                    value={values.product}
                    onChange={(event, newValue) => setFieldValue('product', newValue)}
                    onBlur={() => { setFieldTouched('product', true); }}
                    variant='outlined'
                    size='small'
                    options={products}
                    renderInput={(params) => <TextField {...params} label="Ürün" error={touched.product && !!errors.product} helperText={touched.product && errors.product} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Cihaz Teslim Edildi' ? (
        <Formik key={25} initialValues={{ desciption: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Parça Teslim Et' ? (
        <Formik key={26} initialValues={{ product: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <Field
                    as={Autocomplete}
                    id='product'
                    name='product'
                    value={values.product}
                    onChange={(event, newValue) => setFieldValue('product', newValue)}
                    onBlur={() => { setFieldTouched('product', true); }}
                    variant='outlined'
                    size='small'
                    options={products}
                    renderInput={(params) => <TextField {...params} label="Ürün" error={touched.product && !!errors.product} helperText={touched.product && errors.product} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Parça Hazır' ? (
        <Formik key={27} initialValues={{ desciption: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Parça Siparişte' ? (
        <Formik key={28} initialValues={{ desciption: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Teslimata Hazır (Tamamlandı)' ? (
        <Formik key={29} initialValues={{ desciption: '', product: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={Autocomplete}
                    id='product'
                    name='product'
                    value={values.product}
                    onChange={(event, newValue) => setFieldValue('product', newValue)}
                    onBlur={() => { setFieldTouched('product', true); }}
                    variant='outlined'
                    size='small'
                    options={products}
                    renderInput={(params) => <TextField {...params} label="Ürün" error={touched.product && !!errors.product} helperText={touched.product && errors.product} />}
                    fullWidth
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Şikayetçi' ? (
        <Formik key={30} initialValues={{ desciption: '', date: today }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='date'
                    name='date'
                    label='Gidilecek Tarih'
                    type="date"
                    variant='outlined'
                    size='small'
                    value={values.date}
                    onChange={(e) => setFieldValue('date', e.target.value)}
                    slotProps={{ input: { shrink: "true" } }}
                    fullWidth
                    error={touched.date && !!errors.date}
                    helperText={touched.date && errors.date}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Müşteri Para İade Edilecek' ? (
        <Formik key={31} initialValues={{ desciption: '', offer: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='offer'
                    name='offer'
                    label="Tutar"
                    value={values.offer}
                    type='number'
                    onChange={(e) => setFieldValue('offer', e.target.value >= 0 ? e.target.value : 0)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.offer && !!errors.offer}
                    helperText={touched.offer && errors.offer}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Müşteri Para İade Edildi' ? (
        <Formik key={32} initialValues={{ desciption: '', offer: '' }} onSubmit={(values) => handleAdd(values)}>
          {({ errors, touched, values, setFieldValue }) => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='desciption'
                    name='desciption'
                    label="Açıklama"
                    value={values.desciption}
                    onChange={(e) => setFieldValue('desciption', e.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.desciption && !!errors.desciption}
                    helperText={touched.desciption && errors.desciption}
                  />
                </div>
                <div className='col-12 col-md-6'>
                  <Field
                    as={TextField}
                    id='offer'
                    name='offer'
                    label="Tutar"
                    value={values.offer}
                    type='number'
                    onChange={(e) => setFieldValue('offer', e.target.value >= 0 ? e.target.value : 0)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={touched.offer && !!errors.offer}
                    helperText={touched.offer && errors.offer}
                  />
                </div>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : type === 'Servisi Sonlandır' ? (
        <Formik key={32} initialValues={{ ended: true }} onSubmit={(values) => handleAdd(values)}>
          {() => (
            <Form className='m-0 mt-1 '>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <button type='submit' className='button-with-icon btn btn-primary w-100'><FaSave className='button-icon' />Kaydet</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className='d-flex justify-content-center align-items-center m-0 '>
          <b>Bu Sisteme İşlem Henüz Eklenmedi</b>
        </div>
      )
      }
    </>
  );
}

export default ServiceOperations;