// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { getAllStocks, getStockById, addNewStock, updateStock, deleteStock, resetgetByIdStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } from '../../../storages/slices/backend/StockSlice';
import { getAllSuppliers, addNewSupplier, resetAddStatus as resetAddSupplierStatus, resetError as resetSupplierError } from '../../../storages/slices/backend/SupplierSlice';
import { getAllWarehouses, addNewWarehouse, resetAddStatus as resetAddWarehouseStatus, resetError as resetWarehouseError } from '../../../storages/slices/backend/WarehousesSlice';
import { getAllShelfs, addNewShelf, resetAddStatus as resetAddShelfStatus, resetError as resetShelfError } from '../../../storages/slices/backend/ShelfSlice';
import { getAllBrands, addNewBrand, resetAddStatus as resetAddBrandStatus, resetError as resetBrandError } from '../../../storages/slices/backend/BrandSlice';
import { getAllDevices, addNewDevice, resetAddStatus as resetAddDeviceStatus, resetError as resetDeviceError } from '../../../storages/slices/backend/DeviceSlice';
// Contexts
import { useData } from '../../../contexts/DataContext';
import { useFunctions } from '../../../contexts/FunctionsContext';
// Icons
import { LuPackagePlus, LuPackageCheck, LuPackage, LuPackageMinus } from "react-icons/lu";
import { RiUserAddLine } from "react-icons/ri";
import { BsFillHouseAddFill, BsFillPatchPlusFill } from "react-icons/bs";
import { TbDatabasePlus } from "react-icons/tb";
import { TbPrinter } from "react-icons/tb";
import { FaPlugCirclePlus } from 'react-icons/fa6';
//Modal
import StockAddModal from '../modals/warehouse/StockAddModal';
import StockUpdateModal from '../modals/warehouse/StockUpdateModal';
import StockDetailModal from '../modals/warehouse/StockDetailModal';
import DeleteModal from '../modals/DeleteModal';
import SupplierAddModal from '../modals/suppliers/SupplierAddModal';
import WarehouseAddModal from '../modals/warehouses/WarehousesAddModal';
import ShelfAddModal from '../modals/shelves/ShelfAddModal';
import BrandAddModal from '../modals/brands/BrandAddModal';
import DeviceAddModal from '../modals/devices/DeviceAddModal';
// Component
import StandartTablePage from '../layouts/TablePage';
import Table from '../components/Table';
import DataErrorPage from '../layouts/DataErrorPage';
import LoadingPage from '../layouts/LoadingPage';

function Warehouse() {
  // Storage Import
  const dispatch = useDispatch();
  const { stocks, stock, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.stock);
  const { addStatus: addSupplierStatus, error: supplierError } = useSelector((state) => state.supplier);
  const { addStatus: addWarehouseStatus, error: warehouseError } = useSelector((state) => state.warehouses);
  const { addStatus: addShelfStatus, error: shelfError } = useSelector((state) => state.shelf);
  const { addStatus: addBrandStatus, error: brandError } = useSelector((state) => state.brand);
  const { addStatus: addDeviceStatus, error: deviceError } = useSelector((state) => state.device);
  // Context Import
  const { myUser, statusTranslator } = useData();
  const { stringMoney, stringDate } = useFunctions();
  // Data & Api
  const [canfetchData, setCanFetchData] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (getAllStatus === 'fulfilled') {
      const combined = stocks.map(data => {
        const id = data.id;
        const date = stringDate(data.updatedDate);
        const name = data.name;
        const price = stringMoney(data.salePrice, 'TL');
        const quantity = `${data.quantity} Adet`;
        const shelf = data.warehouseShelfName;
        const device = `${data.brandName}/${data.deviceName}`;
        return { id: id, date: date, name: name, price: price, quantity: quantity, shelf: shelf, device: device }
      });
      setData(combined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus, stocks]);
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllStocks({ signal }));
      await dispatch(getAllSuppliers({ signal }));
      await dispatch(getAllBrands({ signal }));
      await dispatch(getAllDevices({ signal }));
      await dispatch(getAllWarehouses({ signal }));
      await dispatch(getAllShelfs({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseStockAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllStocks({}));
      dispatch(addToast({ background: 'success', icon: 'packageAdd', message: 'Yeni Stok Eklendi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);
  useEffect(() => {
    if (addSupplierStatus === "fulfilled") {
      handleCloseSupplierAddModal();
      dispatch(resetAddSupplierStatus());
      dispatch(getAllSuppliers({}));
      dispatch(addToast({ background: 'success', icon: 'userAdd', message: 'Yeni Tedarikçi Eklendi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addSupplierStatus]);
  useEffect(() => {
    if (addWarehouseStatus === "fulfilled") {
      handleCloseWarehouseAddModal();
      dispatch(resetAddWarehouseStatus());
      dispatch(getAllWarehouses({}));
      dispatch(addToast({ background: 'success', icon: 'houseAdd', message: 'Yeni Depo Eklendi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addWarehouseStatus]);
  useEffect(() => {
    if (addShelfStatus === "fulfilled") {
      handleCloseShelfAddModal();
      dispatch(resetAddShelfStatus());
      dispatch(getAllShelfs({}));
      dispatch(addToast({ background: 'success', icon: 'dataAdd', message: 'Yeni Raf Eklendi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addShelfStatus]);
  useEffect(() => {
    if (addBrandStatus === "fulfilled") {
      handleCloseBrandAddModal();
      dispatch(resetAddBrandStatus());
      dispatch(getAllBrands({}));
      dispatch(addToast({ background: 'success', icon: 'patchAdd', message: 'Yeni Marka Eklendi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addBrandStatus]);
  useEffect(() => {
    if (addDeviceStatus === "fulfilled") {
      handleCloseDeviceAddModal();
      dispatch(resetAddDeviceStatus());
      dispatch(getAllDevices({}));
      dispatch(addToast({ background: 'success', icon: 'plugAdd', message: 'Yeni Cihaz Eklendi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDeviceStatus]);
  useEffect(() => {
    if (updateStatus === "fulfilled") {
      handleCloseStockUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllStocks({}));
      dispatch(addToast({ background: 'primary', icon: 'patchUpdate', message: 'Stok Bilgileri Güncellendi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);
  useEffect(() => {
    if (deleteStatus === "fulfilled") {
      handleCloseStockDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllStocks({}));
      dispatch(addToast({ background: 'danger', icon: 'patchDelete', message: 'Stok Silindi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataForStockUpdateModal({ ...stock, status: statusTranslator[stock.status] });
        setShowStockUpdateModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
      else if (dataRequester === 'detail') {
        setInputDataForStockDetailsModal({ ...stock, status: statusTranslator[stock.status] });
        setShowStockDetailsModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataForStockDeleteModal({ id: stock.id, name: stock.name });
        setShowStockDeleteModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus]);
  useEffect(() => {
    if (error && error !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    if (supplierError && supplierError !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: supplierError, delay: 2000 }));
      dispatch(resetSupplierError());
    }
    if (warehouseError && warehouseError !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: warehouseError, delay: 2000 }));
      dispatch(resetWarehouseError());
    }
    if (shelfError && shelfError !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: shelfError, delay: 2000 }));
      dispatch(resetShelfError());
    }
    if (brandError && brandError !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: brandError, delay: 2000 }));
      dispatch(resetBrandError());
    }
    if (deviceError && deviceError !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: deviceError, delay: 2000 }));
      dispatch(resetDeviceError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, supplierError, warehouseError, shelfError, brandError, deviceError]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'date', name: 'TARİH' }, { value: 'name', name: 'ÜRÜN ADI' }, { value: 'price', name: 'FİYAT' }, { value: 'quantity', name: 'ADET' }, { value: 'shelf', name: 'RAF ADI' }, { value: 'device', name: 'CİHAZ' }];
  const filters = ['id', 'date', 'name', 'price', 'quantity', 'shelf', 'device'];
  const select = (id) => { handleShowStockUpdateModal(id); }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <LuPackageCheck className='button-icon' />,
      onclick: ((rowData) => { handleShowStockUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Detay',
      icon: <LuPackage className='button-icon' />,
      onclick: ((rowData) => { handleShowStockDetailsModal(rowData.id) }),
      variant: 'warning'
    },
    {
      text: 'Sil',
      icon: <LuPackageMinus className='button-icon' />,
      onclick: ((rowData) => { handleShowStockDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={data}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus, data]);
  const operations = () => {
    return (
      <>
        <button onClick={(e) => { e.stopPropagation(); handleShowStockAddModal(); }} className="dropdown-item"><LuPackagePlus style={{ fontSize: '1.5em' }} /> Ürün Ekle</button>
        <button onClick={(e) => { e.stopPropagation(); handleShowSupplierAddModal(); }} className="dropdown-item"><RiUserAddLine style={{ fontSize: '1.5em' }} /> Tedarikçi Ekle</button >
        <button onClick={(e) => { e.stopPropagation(); handleShowWarehouseAddModal(); }} className="dropdown-item"><BsFillHouseAddFill style={{ fontSize: '1.5em' }} /> Depo Ekle</button >
        <button onClick={(e) => { e.stopPropagation(); handleShowShelfAddModal(); }} className="dropdown-item"><TbDatabasePlus style={{ fontSize: '1.5em' }} /> Raf Ekle</button>
        <button onClick={(e) => { e.stopPropagation(); handleShowBrandAddModal(); }} className="dropdown-item"><BsFillPatchPlusFill style={{ fontSize: '1.5em' }} /> Marka Ekle</button>
        <button onClick={(e) => { e.stopPropagation(); handleShowDeviceAddModal(); }} className="dropdown-item"><FaPlugCirclePlus style={{ fontSize: '1.5em' }} /> Cihaz Ekle</button>
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: data, cols: cols, pdftitle: "Depolar" })) }}>
          <TbPrinter style={{ fontSize: '1.5em' }} />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Stock Add Modal
  const [showStockAddModal, setShowStockAddModal] = useState(false);
  const handleCloseStockAddModal = () => { setShowStockAddModal(false) };
  const handleShowStockAddModal = () => { setShowStockAddModal(true) };
  const handleAddStockAddModal = (values) => {
    dispatch(addNewStock({ newstock: { ...values, status: statusTranslator[values.status] } }));
    handleCloseStockAddModal();
  };
  // Supplier Add Modal
  const [showSupplierAddModal, setShowSupplierAddModal] = useState(false);
  const handleCloseSupplierAddModal = () => { setShowSupplierAddModal(false) };
  const handleShowSupplierAddModal = () => { setShowSupplierAddModal(true) };
  const handleAddSupplierAddModal = (values) => {
    dispatch(addNewSupplier({ newsupplier: values }));
    handleCloseSupplierAddModal();
  };
  // Warehouse Add Modal
  const [showWarehouseAddModal, setShowWarehouseAddModal] = useState(false);
  const handleCloseWarehouseAddModal = () => { setShowWarehouseAddModal(false) };
  const handleShowWarehouseAddModal = () => { setShowWarehouseAddModal(true) };
  const handleAddWarehouseAddModal = (values) => {
    dispatch(addNewWarehouse({ newwarehouse: values }));
    handleCloseWarehouseAddModal();
  };
  // Shelf Add Modal
  const [showShelfAddModal, setShowShelfAddModal] = useState(false);
  const handleCloseShelfAddModal = () => { setShowShelfAddModal(false) };
  const handleShowShelfAddModal = () => { setShowShelfAddModal(true) };
  const handleAddShelfAddModal = (values) => {
    dispatch(addNewShelf({ newshelf: values }));
    handleCloseShelfAddModal();
  };
  // Brand Add Modal
  const [showBrandAddModal, setShowBrandAddModal] = useState(false);
  const handleCloseBrandAddModal = () => { setShowBrandAddModal(false) };
  const handleShowBrandAddModal = () => { setShowBrandAddModal(true) };
  const handleAddBrandAddModal = (values) => {
    dispatch(addNewBrand({ newbrand: values }));
    handleCloseBrandAddModal();
  };
  // Device Add Modal
  const [showDeviceAddModal, setShowDeviceAddModal] = useState(false);
  const handleCloseDeviceAddModal = () => { setShowDeviceAddModal(false) };
  const handleShowDeviceAddModal = () => { setShowDeviceAddModal(true) };
  const handleAddDeviceAddModal = (values) => {
    dispatch(addNewDevice({ newdevice: values }));
    handleCloseDeviceAddModal();
  };
  // Stock Update Modal
  const [showStockUpdateModal, setShowStockUpdateModal] = useState(false);
  const [inputDataForStockUpdateModal, setInputDataForStockUpdateModal] = useState({});
  const handleCloseStockUpdateModal = () => {
    setShowStockUpdateModal(false)
    setInputDataForStockUpdateModal({});
  };
  const handleShowStockUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getStockById({ id: id }));
  };
  const handleUpdateStockUpdateModal = (values) => {
    dispatch(updateStock({ stock: { ...values, status: statusTranslator[values.status] } }));
    handleCloseStockUpdateModal();
  };
  // Stock Details Modal
  const [showStockDetailsModal, setShowStockDetailsModal] = useState(false);
  const [inputDataForStockDetailsModal, setInputDataForStockDetailsModal] = useState({});
  const handleCloseStockDetailsModal = () => {
    setShowStockDetailsModal(false)
    setInputDataForStockDetailsModal({});
  };
  const handleShowStockDetailsModal = (id) => {
    setDataRequester('detail');
    dispatch(getStockById({ id: id }));
  };
  // Stock Delete Modal
  const [showStockDeleteModal, setShowStockDeleteModal] = useState(false);
  const [inputDataForStockDeleteModal, setInputDataForStockDeleteModal] = useState(-1);
  const handleCloseStockDeleteModal = () => {
    setShowStockDeleteModal(false)
    setInputDataForStockDeleteModal(-1);
  };
  const handleShowStockDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getStockById({ id: id }));
  };
  const handleDeleteStockDeleteModal = () => {
    dispatch(deleteStock({ id: inputDataForStockDeleteModal.id }));
    handleCloseStockDeleteModal();
  };
  // Return Modals
  const modals = () => {
    return (
      <>
        <StockAddModal
          show={showStockAddModal}
          handleClose={handleCloseStockAddModal}
          handleAdd={handleAddStockAddModal}
        />
        <SupplierAddModal
          show={showSupplierAddModal}
          handleClose={handleCloseSupplierAddModal}
          handleAdd={handleAddSupplierAddModal}
        />
        <WarehouseAddModal
          show={showWarehouseAddModal}
          handleClose={handleCloseWarehouseAddModal}
          handleAdd={handleAddWarehouseAddModal}
        />
        <ShelfAddModal
          show={showShelfAddModal}
          handleClose={handleCloseShelfAddModal}
          handleAdd={handleAddShelfAddModal}
        />
        <BrandAddModal
          show={showBrandAddModal}
          handleClose={handleCloseBrandAddModal}
          handleAdd={handleAddBrandAddModal}
        />
        <DeviceAddModal
          show={showDeviceAddModal}
          handleClose={handleCloseDeviceAddModal}
          handleAdd={handleAddDeviceAddModal}
        />
        <StockUpdateModal
          show={showStockUpdateModal}
          handleClose={handleCloseStockUpdateModal}
          handleUpdate={handleUpdateStockUpdateModal}
          inputData={inputDataForStockUpdateModal}
        />
        <StockDetailModal
          show={showStockDetailsModal}
          handleClose={handleCloseStockDetailsModal}
          inputData={inputDataForStockDetailsModal}
        />
        <DeleteModal
          show={showStockDeleteModal}
          handleClose={handleCloseStockDeleteModal}
          handleDelete={handleDeleteStockDeleteModal}
          title="Stoğu Silmek İstediğinize Emin Misiniz??"
          message={`${inputDataForStockDeleteModal.name} adlı stoğu silmek istediğinize emin misiniz?`}
          deleteIcon={<LuPackageMinus className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <StandartTablePage
      title='Depo'
      Operations={operations}
      Table={() => tableContent}
      Modals={modals}
    />
  );
}

export default Warehouse;