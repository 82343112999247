// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';

function LoadingModal({ show }) {
  // HTML
  return (
    <Modal show={show} centered className="transparent-background-modal" backdropClassName="modal-backdrop">
      <Modal.Body>
        <h2><b>Yükleniyor...</b></h2>
      </Modal.Body>
    </Modal>
  );
}

export default LoadingModal;