// General
import { configureStore } from '@reduxjs/toolkit';
// Storages
import printReducer from './slices/PrintSlice';
import toastReducer from './slices/ToastSlice';
import districtReducer from './slices/DistrictSlice';
import loginReducer from './slices/LoginSlice';
import exchangeReducer from './slices/ExchangeSlice';
import serviceReducer from './slices/backend/ServiceSlice';
import serviceActionReducer from './slices/backend/ServiceActionSlice';
import customerReducer from './slices/backend/CustomerSlice';
import employeeReducer from './slices/backend/EmployeeSlice';
import checkoutReducer from './slices/backend/CheckoutSlice';
import vehicleReducer from './slices/backend/VehicleSlice';
import shelfReducer from './slices/backend/ShelfSlice';
import brandReducer from './slices/backend/BrandSlice';
import deviceReducer from './slices/backend/DeviceSlice';
import dealerReducer from './slices/backend/DealerSlice';
import supplierReducer from './slices/backend/SupplierSlice';
import warehousesReducer from './slices/backend/WarehousesSlice';
import roleReducer from './slices/backend/RoleSlice';
import offerReducer from './slices/backend/OfferSlice';
import stockReducer from './slices/backend/StockSlice';
import stockActionReducer from './slices/backend/StockActionSlice';
import operationReportReducer from './slices/backend/OperationReports';
import sourceReducer from './slices/backend/SourceSlice';
import consigmentReducer from './slices/backend/ConsigmentSlice';

export const store = configureStore({
  reducer: {
    print: printReducer,
    toast: toastReducer,
    district: districtReducer,
    exchange: exchangeReducer,
    login: loginReducer,
    service: serviceReducer,
    serviceaction: serviceActionReducer,
    customer: customerReducer,
    employee: employeeReducer,
    checkout: checkoutReducer,
    vehicle: vehicleReducer,
    shelf: shelfReducer,
    brand: brandReducer,
    device: deviceReducer,
    dealer: dealerReducer,
    supplier: supplierReducer,
    warehouses: warehousesReducer,
    role: roleReducer,
    offer: offerReducer,
    stock: stockReducer,
    stockAction: stockActionReducer,
    operationReport: operationReportReducer,
    source: sourceReducer,
    consigment: consigmentReducer,

  },
})