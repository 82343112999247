// General
import React from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Field, Form, Formik } from 'formik';
import { Autocomplete, TextField } from '@mui/material';
// Context
import { useData } from '../../../../contexts/DataContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
import { useValidations } from '../../../../contexts/ValidationContext';
// Icons
import { IoMdAddCircleOutline } from 'react-icons/io';
import { TbArrowBackUpDouble } from 'react-icons/tb';
import { RiFileSettingsFill } from "react-icons/ri";
// Components
import CustomerType from '../../components/CustomerType';
import ProductDynamicRow from '../../components/ProductDynamicRow';

function InvoiceUpdateModal({ show, handleClose, handleUpdate, inputData }) {
  // Context Import
  const { listAddItem, calculateAmountWithoutKdv } = useFunctions();
  const { validationSchema_Invoices } = useValidations();
  // Formik Variables
  const initialValues = {
    date: inputData.date || '',
    serviceId: inputData.serviceId || '',
    customerId: inputData.customerId || '',
    customerName: '',
    customerSurname: '',
    customerType: '',
    identityNo: '',
    taxNo: '',
    taxOffice: '',
    customerPhone: '',
    customerAddressCity: '',
    customerAddressDistrict: '',
    customerAddressText: '',
    products: inputData.products || [{ id: 1, product: '', count: 0, price: 0, subtotal: 0 }],
    withholdingCode: inputData.withholdingCode || '',
    withholdingRate: inputData.withholdingRate || 0,
    kdvCode: inputData.kdvCode || '',
    grandTotal: inputData.grandTotal || '',
    kdvRate: inputData.kdvRate || 0,
    kdvNote: inputData.kdvNote || '',
    description: inputData.description || '',
    productssubtotal: inputData.productssubtotal || '',
    generalkdvcost: inputData.generalkdvcost || 0,
    withholdingtotal: inputData.withholdingtotal || '',
  }
  return (
    <Modal show={show} onHide={handleClose} centered className='modal-xl'>
      <Modal.Header closeButton>
        <Modal.Title>Fatura Ekle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik validationSchema={validationSchema_Invoices} initialValues={initialValues} onSubmit={(values) => handleUpdate(values)}>
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form>
              <div className='row g-2'>
                <div className='col-12 col-md-12'>
                  <div className='card bg-light m-0 p-0'>
                    <div className='card-header m-0 p-2'>
                      <div className='row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100'>
                        <div className='col-12 col-md-6 d-flex align-items-center'>
                          <div className='card-title m-0 p-0'>Servis Bilgileri</div>
                        </div>
                        <div className="col-12 col-md-3">
                          <Field
                            as={TextField}
                            id='generalkdvcost'
                            name='generalkdvcost'
                            label="KDV'li fiyat"
                            type="number"
                            variant='outlined'
                            size='small'
                            fullWidth
                          />
                        </div>
                        <div className="col-12 col-md-3">
                          <Field
                            as={TextField}
                            id='pricewithoutvat'
                            name='pricewithoutvat'
                            label="KDV'siz fiyatı"
                            value={calculateAmountWithoutKdv(values.generalkdvcost, values.kdvRate)}
                            variant='outlined'
                            size='small'
                            fullWidth
                            slotProps={{ input: { readOnly: true } }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='card-body'>
                      <div className='row g-2'>
                        <div className='col-12 col-md-6'>
                          <div className='row g-2'>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={Autocomplete}
                                id="serviceId"
                                name="serviceId"
                                value={""}
                                onChange={(event, newValue) => {
                                  setFieldValue('serviceId', newValue ? newValue.id : null);
                                }}
                                onBlur={() => { setFieldTouched('serviceId', true); }}
                                variant='outlined'
                                size='small'
                                options={[]}
                                getOptionLabel={(option) => option.id}
                                renderOption={(props, option) => {
                                  const { key, ...otherProps } = props;
                                  return (
                                    <li key={option.id} {...otherProps}>
                                      {`${option.id}`}
                                    </li>
                                  )
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id} // value kontrolü
                                renderInput={(params) => (
                                  <TextField {...params} label="Servis Numarası" error={touched.serviceId && !!errors.serviceId} helperText={touched.serviceId && errors.serviceId} />
                                )}
                              />
                            </div>
                            <div className='col-12 col-md-6'>
                              <Field
                                as={TextField}
                                id="customerName"
                                name="customerName"
                                label="Müşteri Adı"
                                value={values.customerName}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-12 col-md-6'>
                              <Field
                                as={TextField}
                                id="customerSurname"
                                name="customerSurname"
                                label="Müşteri Soyadı"
                                value={values.customerSurname}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id="customerType"
                                name="customerType"
                                label="Müşteri Türü"
                                value={values.customerType}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <CustomerType errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} readOnly={true} />
                          </div>
                        </div>
                        <div className='col-12 col-md-6'>
                          <div className='row g-2'>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id='date'
                                name='date'
                                label={values.date ? 'Tarih' : ''}
                                type="date"
                                variant='outlined'
                                size='small'
                                value={values.date}
                                onChange={(e) => {
                                  const newValue = e.target.value || '';
                                  setFieldValue('date', newValue);
                                }}
                                slotProps={{ input: { shrink: "true" } }}
                                fullWidth
                                error={touched.date && !!errors.date}
                                helperText={touched.date && errors.date}
                              />
                            </div>
                            <div className='col-12 col-md-6'>
                              <Field
                                as={TextField}
                                id='customerAddressCity'
                                name='customerAddressCity'
                                label="İl"
                                value={values.customerAddressCity}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-12 col-md-6'>
                              <Field
                                as={TextField}
                                id='customerAddressDistrict'
                                name='customerAddressDistrict'
                                label="İlçe"
                                value={values.customerAddressDistrict}
                                slotProps={{ input: { readOnly: true } }}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </div>
                            <div className='col-12 col-md-12'>
                              <Field
                                as={TextField}
                                id="customerAddressText"
                                name='customerAddressText'
                                label="Adres"
                                value={values.customerAddressText}
                                onChange={(e) => setFieldValue('customerAddressText', e.target.value)}
                                multiline
                                minRows={3}
                                variant="outlined"
                                size="small"
                                slotProps={{ input: { readOnly: true } }}
                                fullWidth
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-12'>
                  <div className='card bg-light m-0 p-0'>
                    <div className='card-header m-0 p-2'>
                      <div className='row'>
                        <div className='col-12 col-md-9'>
                          <div className='d-flex align-items-center h-100'>
                            <div className='card-title m-0 p-0'>Servis Ürünleri</div>
                          </div>
                        </div>
                        <div className='col-12 col-md-3'>
                          <button
                            type='button'
                            className='button-with-icon btn btn-success w-100'
                            onClick={(e) => {
                              e.preventDefault();
                              const maxId = values.products.length > 0 ? Math.max(...values.products.map(product => product.id)) : 0;
                              const newProduct = { id: maxId + 1, product: '', count: 0, price: 0, subtotal: 0 };
                              setFieldValue('products', listAddItem(values.products, newProduct));
                            }}>
                            <IoMdAddCircleOutline className='button-icon' />Ekle
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='card-body'>
                      <ProductDynamicRow values={values} listtitle={"products"} setFieldValue={setFieldValue} productName={'product'} productCount={"count"} productPrice={'price'} productTotalCount={'subtotal'} errors={errors} touched={touched}></ProductDynamicRow>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='card bg-light m-0 p-0'>
                    <div className='card-header m-0 p-2'>
                      <div className='card-title m-0 p-0'>KDV Bilgileri</div>
                    </div>
                    <div className='card-body'>
                      <div className='row g-2'>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={Autocomplete}
                            id="withholdingCode"
                            name="withholdingCode"
                            value={null}
                            onChange={(event, newValue) => {
                              setFieldValue('withholdingCode', newValue ? newValue.id : 0);
                              setFieldValue('withholdingRate', newValue ? newValue.rate : 0);
                            }}
                            onBlur={() => { setFieldTouched('withholdingCode', true); }}
                            variant='outlined'
                            size='small'
                            options={[]}
                            getOptionLabel={(option) => option.id}
                            renderOption={(props, option) => {
                              const { key, ...otherProps } = props;
                              return (
                                <li key={option.id} {...otherProps}>
                                  {`${option.id} - ${option.name} (Oran: ${option.rate})`}
                                </li>
                              )
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value}
                            renderInput={(params) => (<TextField {...params} label="Tevkifat Kodu" error={touched.withholdingCode && !!errors.withholdingCode} helperText={touched.withholdingCode && errors.withholdingCode} />)}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={Autocomplete}
                            id="kdvCode"
                            name="kdvCode"
                            value={null}
                            onChange={(event, newValue) => {
                              setFieldValue('kdvCode', newValue ? newValue.id : 0);
                              setFieldValue('kdvRate', newValue ? newValue.rate : 0);
                            }}
                            onBlur={() => { setFieldTouched('kdvCode', true); }}
                            variant='outlined'
                            size='small'
                            options={[]}
                            getOptionLabel={(option) => option.id}
                            renderOption={(props, option) => {
                              const { key, ...otherProps } = props;
                              return (
                                <li key={option.id} {...otherProps}>
                                  {`${option.id} - ${option.name} (Oran: ${option.rate})`}
                                </li>
                              )
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value}
                            renderInput={(params) => (<TextField {...params} label="KDV Kodu" error={touched.kdvCode && !!errors.kdvCode} helperText={touched.kdvCode && errors.kdvCode} />)}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id="kdvNote"
                            name="kdvNote"
                            label="KDV Notu"
                            value={values.kdvNote}
                            onChange={(e) => setFieldValue('kdvNote', e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={touched.kdvNote && !!errors.kdvNote}
                            helperText={touched.kdvNote && errors.kdvNote}
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id="description"
                            name="description"
                            label="Fatura Açıklaması"
                            value={values.description}
                            onChange={(e) => setFieldValue('description', e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={touched.description && !!errors.description}
                            helperText={touched.description && errors.description}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='card bg-light m-0 p-0'>
                    <div className='card-header m-0 p-2'>
                      <div className='card-title m-0 p-0'>Fatura Bilgileri</div>
                    </div>
                    <div className='card-body'>
                      <div className='row g-2'>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id="productssubtotal"
                            name="productssubtotal"
                            label="Ara Toplam"
                            value={0}
                            slotProps={{ input: { readOnly: true } }}
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        </div>
                        <div className='col-12 col-md-4'>
                          <Field
                            as={Autocomplete}
                            id="kdvRate"
                            name="kdvRate"
                            onBlur={() => { setFieldTouched('kdvRate', true); }}
                            options={[]}
                            getOptionLabel={(option) => option}
                            value={0}
                            onChange={(event, newValue) => {
                              setFieldValue('kdvRate', newValue);
                            }}
                            variant='outlined'
                            size='small'
                            renderInput={(params) => (<TextField {...params} label="KDV Oranı" error={touched.kdvRate && !!errors.kdvRate} helperText={touched.kdvRate && errors.kdvRate} />)}
                          />
                        </div>
                        <div className='col-12 col-md-8'>
                          <Field
                            as={TextField}
                            id="kdvtotal"
                            name="kdvtotal"
                            label="Toplam KDV"
                            value={0}
                            onChange={(e) => { }}
                            slotProps={{ input: { readOnly: true } }}
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        </div>
                        <div className='col-12 col-md-4'>
                          <Field
                            as={Autocomplete}
                            id="withholdingRate"
                            name="withholdingRate"
                            onBlur={() => { setFieldTouched('withholdingRate', true); }}
                            options={[]}
                            getOptionLabel={(option) => option}
                            value={0}
                            onChange={(event, newValue) => { setFieldValue('withholdingRate', newValue); }}
                            variant='outlined'
                            size='small'
                            renderInput={(params) => (<TextField {...params} label="Tevkifat Oranı" error={touched.withholdingRate && !!errors.withholdingRate} helperText={touched.withholdingRate && errors.withholdingRate} />)}
                          />
                        </div>
                        <div className='col-12 col-md-8'>
                          <Field
                            as={TextField}
                            id="withholdingtotal"
                            name="withholdingtotal"
                            label="Toplam Tevkifat"
                            value={0}
                            slotProps={{ input: { readOnly: true } }}
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        </div>
                        <div className='col-12 col-md-12'>
                          <Field
                            as={TextField}
                            id="subtotal"
                            name="subtotal"
                            label="Genel Toplam"
                            value={0}
                            slotProps={{ input: { readOnly: true } }}
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-12'>
                  <div className='row g-2'>
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-secondary w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-primary w-100'><RiFileSettingsFill className='button-icon' />Güncelle</button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal >
  );
}

export default InvoiceUpdateModal;