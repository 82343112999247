// General
import React, { useEffect, useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { getDistricts } from '../../../../storages/slices/DistrictSlice';
// Context
import { useData } from '../../../../contexts/DataContext';
import { useValidations } from '../../../../contexts/ValidationContext';
import { useFunctions } from '../../../../contexts/FunctionsContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
import { RiUserSettingsFill } from "react-icons/ri";
// Components
import CustomerType from '../../components/CustomerType';

function CustomerUpdateModal({ show, handleClose, handleUpdate, inputData }) {
  // Storage Import
  const dispatch = useDispatch();
  const { districts } = useSelector((state) => state.district);
  // Context Import
  const { validationSchema_Customer } = useValidations();
  const { stringMaxLenght, stringOnlyNumbers } = useFunctions();
  const { cities } = useData();
  // Data & Api
  const [city, setCity] = useState('İstanbul');
  const fetchAllDataSequentially = async (dispatch, signal, selectedCity) => {
    try {
      await dispatch(getDistricts({ signal, city: selectedCity, }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (show && city) { fetchAllDataSequentially(dispatch, signal, city); }
    return () => { controller.abort(); };
  }, [city, show, dispatch]);
  // Formik Variables
  const initialValues = {
    id: inputData.id || 0,
    name: inputData.name || '',
    customerType: inputData.customerType || 'Bireysel',
    phone1: inputData.phone1 || '',
    phone2: inputData.phone2 || '',
    city: inputData.city || 'İstanbul',
    district: inputData.district || '',
    address: inputData.address || '',
    identityNumber: inputData.identityNumber || '',
    taxNumber: inputData.taxNumber || '',
    taxOffice: inputData.taxOffice || ''
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header closeButton>
          <Modal.Title>Müşteri Bilgilerini Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik initialValues={initialValues} validationSchema={validationSchema_Customer} onSubmit={(values) => handleUpdate(values)} >
            {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
              <Form>
                <div className='row g-2'>
                  <div className="col-12 col-md-12 ">
                    <Field
                      as={TextField}
                      id='CustomerUpdateModal_name'
                      name='name'
                      label='İsim'
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.name && !!errors.name}
                      helperText={touched.name && errors.name}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={Autocomplete}
                      id="CustomerUpdateModal_customerType"
                      name="customerType"
                      options={['Bireysel', 'Kurumsal']}
                      value={values.customerType}
                      onChange={(event, newValue) => { setFieldValue('customerType', newValue); }}
                      onBlur={() => { setFieldTouched('customerType', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Müşteri Türü" error={touched.customerType && !!errors.customerType} helperText={touched.customerType && errors.customerType} />}
                    />
                  </div>
                  <CustomerType errors={errors} touched={touched} values={values} setFieldValue={setFieldValue} readOnly={false} />
                  <div className="col-12 col-md-6">
                    <Field
                      as={TextField}
                      id="CustomerUpdateModal_phone1"
                      name='phone1'
                      label='Birincil Telefon Numarası'
                      value={values.phone1}
                      onChange={(e) => setFieldValue("phone1", stringMaxLenght(stringOnlyNumbers(e.target.value), 10))}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.phone1 && !!errors.phone1}
                      helperText={touched.phone1 && errors.phone1}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={TextField}
                      id="CustomerUpdateModal_phone2"
                      name='phone2'
                      label='İkincil Telefon Numarası'
                      value={values.phone2}
                      onChange={(e) => setFieldValue("phone2", stringMaxLenght(stringOnlyNumbers(e.target.value), 10))}
                      variant='outlined'
                      size='small'
                      fullWidth
                      error={touched.phone2 && !!errors.phone2}
                      helperText={touched.phone2 && errors.phone2}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={Autocomplete}
                      id="CustomerUpdateModal_city"
                      name="city"
                      options={cities}
                      value={values.city}
                      onChange={(event, newValue) => {
                        setCity(newValue ? newValue : '');
                        setFieldValue('city', newValue ? newValue : '');
                        setFieldValue('district', '');
                      }}
                      onBlur={() => { setFieldTouched('city', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="Şehir" error={touched.city && !!errors.city} helperText={touched.city && errors.city} />}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <Field
                      as={Autocomplete}
                      id="CustomerUpdateModal_district"
                      name="district"
                      options={districts}
                      value={values.district}
                      onChange={(event, newValue) => { if (city !== '') { setFieldValue("district", newValue); } }}
                      disabled={city === ''}
                      onBlur={() => { setFieldTouched('district', true); }}
                      variant='outlined'
                      size='small'
                      fullWidth
                      renderInput={(params) => <TextField {...params} label="İlçe" error={touched.district && !!errors.district} helperText={touched.district && errors.district} />}
                    />
                  </div>
                  <div className="col-12 col-md-12">
                    <Field
                      as={TextField}
                      id='CustomerUpdateModal_address'
                      name='address'
                      label="Adres"
                      value={values.address}
                      onChange={(e) => setFieldValue("address", e.target.value)}
                      slotProps={{ input: { style: { overflow: 'auto', resize: 'none', }, }, }}
                      multiline
                      rows={4}
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={touched.address && !!errors.address}
                      helperText={touched.address && errors.address}
                    />
                  </div>
                  <div className="row g-2 px-0 mx-0 d-flex justify-content-end align-items-center w-100">
                    <div className='col-12 col-md-6'>
                      <button type='button' className='button-with-icon btn btn-warning w-100' onClick={handleClose}>
                        <TbArrowBackUpDouble className='button-icon' />
                        Geri
                      </button>
                    </div>
                    <div className='col-12 col-md-6'>
                      <button type='submit' className='button-with-icon btn btn-success w-100' >
                        <RiUserSettingsFill className='button-icon' />
                        Güncelle
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default CustomerUpdateModal