// General
import React, { useEffect, useMemo, useState } from 'react';
// Storages
import { useDispatch, useSelector } from 'react-redux';
import { addToast } from '../../../storages/slices/ToastSlice';
import { exportPdf } from '../../../storages/slices/PrintSlice';
import { getAllConsigments, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetgetByIdStatus, resetError, getConsigmentById, addNewConsigment, updateConsigment, deleteConsigment } from '../../../storages/slices/backend/ConsigmentSlice';
import { getAllBrands } from '../../../storages/slices/backend/BrandSlice';
import { getAllDevices } from '../../../storages/slices/backend/DeviceSlice';
// Context
import { useData } from '../../../contexts/DataContext';
// Icons
import { TbTruckDelivery } from "react-icons/tb";
import { TbPrinter } from "react-icons/tb";
// Modals
import ConsigmentAddModal from '../modals/consigments/ConsigmentAddModal';
import ConsigmentUpdateModal from '../modals/consigments/ConsigmentUpdateModal';
import DeleteModal from '../modals/DeleteModal';
// Components
import TablePage from '../layouts/TablePage';
import LoadingPage from '../layouts/LoadingPage';
import DataErrorPage from '../layouts/DataErrorPage';
import Table from '../components/Table';


function Consigment() {
  // Storage Import
  const dispatch = useDispatch();
  const { consigments, consigment, getAllStatus, getByIdStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.consigment);
  // Context Import 
  const { myUser } = useData();
  // Variables
  const [canfetchData, setCanFetchData] = useState(false);
  // Data & Api
  const fetchAllDataSequentially = async (dispatch, signal) => {
    try {
      await dispatch(getAllConsigments({ signal }));
      await dispatch(getAllBrands({ signal }));
      await dispatch(getAllDevices({ signal }));
    } catch (error) { throw error; }
  };
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (canfetchData) { fetchAllDataSequentially(dispatch, signal); }
    return () => { controller.abort(); };
  }, [canfetchData, dispatch]);
  useEffect(() => { if (myUser) { setCanFetchData(true); } }, [myUser]);
  useEffect(() => {
    if (addStatus === "fulfilled") {
      handleCloseConsigmentAddModal();
      dispatch(resetAddStatus());
      dispatch(getAllConsigments({}));
      dispatch(addToast({ background: 'success', icon: 'consigment', message: 'Yeni Konsinye Cihazı Eklendi!', delay: 6000 }));
    }
    if (updateStatus === "fulfilled") {
      handleCloseConsigmentUpdateModal();
      dispatch(resetUpdateStatus());
      dispatch(getAllConsigments({}));
      dispatch(addToast({ background: 'primary', icon: 'consigment', message: 'Konsinye Cihazı Güncellendi!', delay: 6000 }));
    }
    if (deleteStatus === "fulfilled") {
      handleCloseConsigmentDeleteModal();
      dispatch(resetDeleteStatus());
      dispatch(getAllConsigments({}));
      dispatch(addToast({ background: 'danger', icon: 'consigment', message: 'Konsinye Cihazı Silindi!', delay: 6000 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, updateStatus, deleteStatus, dispatch]);
  const [dataRequester, setDataRequester] = useState('');
  useEffect(() => {
    if (getByIdStatus === 'fulfilled') {
      if (dataRequester === 'update') {
        setInputDataForConsigmentUpdateModal(consigment);
        setShowConsigmentUpdateModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
      else if (dataRequester === 'delete') {
        setInputDataForConsigmentDeleteModal({ id: consigment.id, model: consigment.model });
        setShowConsigmentDeleteModal(true);
        setDataRequester('');
        dispatch(resetgetByIdStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdStatus]);
  useEffect(() => {
    if (error && error !== "canceled") {
      dispatch(addToast({ background: 'danger', icon: 'error', message: error, delay: 2000 }));
      dispatch(resetError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
  // Table Content
  const rows = 10;
  const cols = [{ value: 'id', name: 'ID' }, { value: 'model', name: 'MODEL' }, { value: 'deviceName', name: 'CİHAZ ADI' }, { value: 'brandName', name: 'MARKA ADI' }, { value: 'status', name: 'DURUM' }, { value: 'quantity', name: 'MİKTAR' }, { value: 'description', name: 'AÇIKLAMA' }];
  const filters = ['id', 'model', 'deviceName', 'brandName', 'status', 'quantity', 'description'];
  const select = (id) => {
    handleShowConsigmentUpdateModal(id);
  }
  const buttons = [
    {
      text: 'Güncelle',
      icon: <TbTruckDelivery className='button-icon' />,
      onclick: ((rowData) => { handleShowConsigmentUpdateModal(rowData.id); }),
      variant: 'primary'
    },
    {
      text: 'Sil',
      icon: <TbTruckDelivery className='button-icon' />,
      onclick: ((rowData) => { handleShowConsigmentDeleteModal(rowData.id); }),
      variant: 'danger'
    }
  ];
  const tableContent = useMemo(() => {
    if (getAllStatus === 'fulfilled') {
      return (
        <Table
          data={consigments}
          rows={rows}
          columns={cols}
          filternames={filters}
          select={select}
          buttons={buttons}
        />
      );
    } else if (getAllStatus === 'pending') {
      return <LoadingPage />;
    } else {
      return <DataErrorPage />;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllStatus]);
  const operations = () => {
    return (
      <>
        <button className="dropdown-item" onClick={(e) => { e.stopPropagation(); handleShowConsigmentAddModal(); }}>
          <TbTruckDelivery className='button-icon' />
          Yeni Konsinye Cihaz Ekle
        </button >
        <button className="dropdown-item" onClick={() => { dispatch(exportPdf({ rowsPerPage: rows, data: consigments, cols: cols, pdftitle: "Konsinye Cihazlar" })) }}>
          <TbPrinter className='button-icon' />
          Yazdır
        </button>
      </>
    );
  }
  // Modals
  // Consigment Add Modal
  const [showConsigmentAddModal, setShowConsigmentAddModal] = useState(false);
  const handleCloseConsigmentAddModal = () => { setShowConsigmentAddModal(false) };
  const handleShowConsigmentAddModal = () => { setShowConsigmentAddModal(true) };
  const handleAddConsigmentAddModal = (values) => {
    dispatch(addNewConsigment({ newconsigment: values }));
    handleCloseConsigmentAddModal();
  }
  // Consigment Update Modal
  const [showConsigmentUpdateModal, setShowConsigmentUpdateModal] = useState(false);
  const [inputDataForConsigmentUpdateModal, setInputDataForConsigmentUpdateModal] = useState({});
  const handleCloseConsigmentUpdateModal = () => { setShowConsigmentUpdateModal(false); setInputDataForConsigmentUpdateModal({}); }
  const handleShowConsigmentUpdateModal = (id) => {
    setDataRequester('update');
    dispatch(getConsigmentById({ id: id }));
  }
  const handleUpdateConsigmentUpdateModal = (values) => {
    dispatch(updateConsigment({ consigment: values }));
    handleCloseConsigmentUpdateModal();
  }
  // Consigment Delete Modal
  const [showConsigmentDeleteModal, setShowConsigmentDeleteModal] = useState(false);
  const [inputDataForConsigmentDeleteModal, setInputDataForConsigmentDeleteModal] = useState(-1);
  const handleCloseConsigmentDeleteModal = () => { setShowConsigmentDeleteModal(false); setInputDataForConsigmentDeleteModal(-1); }
  const handleShowConsigmentDeleteModal = (id) => {
    setDataRequester('delete');
    dispatch(getConsigmentById({ id: id }));
  }
  const handleDeleteConsigmentDeleteModal = () => {
    dispatch(deleteConsigment({ id: inputDataForConsigmentDeleteModal.id }));
    handleCloseConsigmentDeleteModal();
  }
  // Return Modals
  const modals = () => {
    return (
      <>
        <ConsigmentAddModal
          show={showConsigmentAddModal}
          handleClose={handleCloseConsigmentAddModal}
          handleAdd={handleAddConsigmentAddModal}
        />
        <ConsigmentUpdateModal
          show={showConsigmentUpdateModal}
          handleClose={handleCloseConsigmentUpdateModal}
          handleUpdate={handleUpdateConsigmentUpdateModal}
          inputData={inputDataForConsigmentUpdateModal}
        />
        <DeleteModal
          show={showConsigmentDeleteModal}
          handleClose={handleCloseConsigmentDeleteModal}
          handleDelete={handleDeleteConsigmentDeleteModal}
          title="Konsinye Cihazı'nı Silmek İstediğinize Emin Misiniz?"
          message={`${inputDataForConsigmentDeleteModal.model} adlı konsinye cihazı silmek istediğinize emin misiniz?`}
          deleteIcon={<TbTruckDelivery className='button-icon' />}
        />
      </>
    );
  }
  // HTML
  return (
    <TablePage
      title='Konsinye Cihazlar'
      Operations={operations}
      Table={() => tableContent}
      Modals={modals}
    />
  );
}

export default Consigment;