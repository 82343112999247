// General
import React from 'react';

function LoadingPage() {
  // HTML
  return (
    <div className='d-flex justify-content-center align-items-center'><h3>Yükleniyor...</h3></div>
  );
}

export default LoadingPage;