// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/TransactionReports/';

// Async Thunks
export const getAllOperationReports = createAsyncThunk('backend_getalloperationreports', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllTransactionReports`, { signal, withCredentials: true });
    return res.data.data.map(item => ({ ...item, date: item.date.slice(0, 10) }));
  } catch (error) { throw error?.response?.data || error; }
});
export const getOperationReportById = createAsyncThunk('backend_getoperationreportbyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetTransactionReportById?id=${id}`, { withCredentials: true });
    return { ...res.data.data, date: res.data.data.date.slice(0, 10) };
  } catch (error) { throw error.response.data || error; }
});
export const addOperationReport = createAsyncThunk('backend_addnewoperationreport', async ({ newtransactionreport }) => {
  try {
    const res = await axios.post(`${baseurl}AddTransactionReport`, newtransactionreport, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateOperationReport = createAsyncThunk('backend_updateoperationreport', async ({ transactionreport }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateTransactionReport`, transactionreport, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteOperationReport = createAsyncThunk('backend_deleteoperationreport', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteTransactionReport?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});

// Slice
const operationReportSlice = createSlice({
  name: 'operationReport',
  initialState: {
    operationReports: [],
    operationReport: {},
    getAllStatus: null,
    getByIdStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetgetByIdStatus: (state) => { state.getByIdStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOperationReports.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllOperationReports.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.operationReports = action.payload || []; })
      .addCase(getAllOperationReports.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getOperationReportById.pending, (state) => { state.getByIdStatus = "pending"; })
      .addCase(getOperationReportById.fulfilled, (state, action) => { state.getByIdStatus = "fulfilled"; state.operationReport = action.payload || {}; })
      .addCase(getOperationReportById.rejected, (state, action) => { state.getByIdStatus = "rejected"; state.error = action.error.message; })
      .addCase(addOperationReport.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addOperationReport.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addOperationReport.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateOperationReport.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateOperationReport.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateOperationReport.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteOperationReport.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteOperationReport.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteOperationReport.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
})

export const { resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetgetByIdStatus, resetError } = operationReportSlice.actions;
export default operationReportSlice.reducer;