import { createSlice } from '@reduxjs/toolkit'
// PDF
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// Font 
import { font, fontbold } from '../../assets/fonts/PTSerifRegular';

// Slice
const printSlice = createSlice({
  name: 'print',
  initialState: {
    filtervalue: null,
    filterpageindex: 0,
  },
  reducers: {
    exportPdf: (state, action) => {
      const { rowsPerPage, data, cols, pdftitle } = action.payload;
      const filtereddata = state.filtervalue;
      const pageindexvalue = state.filterpageindex;
      const startindex = pageindexvalue * rowsPerPage;
      const endindex = (startindex) + rowsPerPage;
      const doc = new jsPDF();
      doc.addFileToVFS('PTSerif-Regular.ttf', font.trim());
      doc.addFont('PTSerif-Regular.ttf', 'PTSerif', 'normal');
      doc.addFileToVFS('PTSerif-Bold.ttf', fontbold.trim());
      doc.addFont('PTSerif-Bold.ttf', 'PTSerif', 'bold');
      doc.setFont('PTSerif', 'normal');
      doc.setFontSize(14);
      doc.text(pdftitle, doc.internal.pageSize.getWidth() / 2, 15, { align: "center" });
      doc.setFontSize(10);
      doc.text(`Tarih: ${new Date().toLocaleDateString()}`, 14, 20);
      const currentData = filtereddata !== null ? data.filter(item => Object.values(item).some(val => String(val).toLowerCase().includes(filtereddata.toLowerCase()))).slice(startindex, endindex)
        : data.slice(startindex, endindex);
      const statusIndex = cols.findIndex(col => col.name === 'DURUM');
      const costIndex = cols.findIndex(col => col.name === 'TUTAR');
      doc.autoTable({
        head: [cols.map(col => col.name)],
        body: currentData.map(item => cols.map(col => item[col.value])),
        styles: { font: "PTSerif", valign: 'middle', },
        didParseCell: function (data) {
          if (data.column.index === statusIndex || data.column.index === costIndex) {
            data.cell.styles.font = 'PTSerif';
            data.cell.styles.fontStyle = 'bold';
            const statusText = data.row.raw[statusIndex];
            if (statusText !== undefined) {
              if (statusText.toString().includes("Gelen Ödeme")) {
                if (data.column.index === costIndex) { data.cell.text = `+${data.cell.text}`; }
                data.cell.styles.textColor = "green";
              } else if (statusText.toString().includes("Giden Ödeme")) {
                if (data.column.index === costIndex) { data.cell.text = `-${data.cell.text}`; }
                data.cell.styles.textColor = "red";
              }
            }
          }
          else { data.cell.styles.font = 'PTSerif'; data.cell.styles.fontStyle = 'normal'; }
        },
        margin: { top: 25 },
      });
      doc.save(pdftitle);
    },
    setDtFilterValue: (state, action) => { state.filtervalue = action.payload; },
    setFilterPageIndex: (state, action) => { state.filterpageindex = action.payload; }
  },
})
export const { exportPdf, setDtFilterValue, setFilterPageIndex } = printSlice.actions;
export default printSlice.reducer;

