// General
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

const baseurl = 'https://tservis-api.tuyak.com.tr/api/Services/';

// Async Thunks
export const getAllServices = createAsyncThunk('backend_getallservices', async ({ signal = null }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllServices`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesByDate = createAsyncThunk('backend_getallservicesbydate', async ({ signal = null, start, end }) => {
  try {
    const res = await axios.get(`${baseurl}GetAllServicesByDate?start=${start}&end=${end}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { console.log(error.response.data); throw error.response.data || error; }
});
export const getServiceById = createAsyncThunk('backend_getservicebyid', async ({ id }) => {
  try {
    const res = await axios.get(`${baseurl}GetServiceById?id=${id}`, { withCredentials: true });
    return { ...res.data?.data, date: res.data?.data.date.slice(0, 10) };
  } catch (error) { throw error.response.data || error; }
});
export const addNewService = createAsyncThunk('backend_addnewservice', async ({ newservice }) => {
  try {
    const res = await axios.post(`${baseurl}AddService`, newservice, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const updateService = createAsyncThunk('backend_updateservice', async ({ service }) => {
  try {
    const res = await axios.post(`${baseurl}UpdateService`, service, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
export const deleteService = createAsyncThunk('backend_deleteservice', async ({ id }) => {
  try {
    const res = await axios.post(`${baseurl}DeleteService?id=${id}`, {}, { withCredentials: true });
    return res.data;
  } catch (error) { throw error.response.data || error; }
});
// Reports Async Thunks
export const getAllServicesByOperator = createAsyncThunk('backend_getallservicesbyoperator', async ({ signal = null, start, end, name }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByOperator?startDate=${start}&endDate=${end}&operatorName=${name}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesByTecnician = createAsyncThunk('backend_getallservicesbytecnician', async ({ signal = null, date, plate, id, assistant }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByTecnician?date=${date}&car=${plate}&userId=${id}&assistantTechnicianName=${assistant}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesByWorkshopTaken = createAsyncThunk('backend_getallservicesbyworkshoptaken', async ({ signal = null, start, end, id, device }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByWorkshopTaken?startDate=${start}&endDate=${end}2&userId=${id}&deviceName=${device}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesByWorkshopMaster = createAsyncThunk('backend_getallservicesbyworkshopmaster', async ({ signal = null, date, id }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByWorkShopMaster?date=${date}&userId=${id}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesByRepairedInWorkshop = createAsyncThunk('backend_getallservicesbyrepairedinworkshop', async ({ signal = null, date, id }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByRepairedInWorkshop?date=${date}&userId=${id}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesBySendShipping = createAsyncThunk('backend_getallservicesbysendshipping', async ({ signal = null, date, id }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesBySendShipping?date=${date}&userId=${id}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesByInTransit = createAsyncThunk('backend_getallservicesbyintransit', async ({ signal = null, date, id }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByInTransit?date=${date}&userId=${id}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesByDelivered = createAsyncThunk('backend_getallservicesbydelivered', async ({ signal = null, date, id }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByDelivered?date=${date}&userId=${id}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesByWaitingParts = createAsyncThunk('backend_getallservicesbywaitingparts', async ({ signal = null, date }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByWaitingParts?date=${date}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesByProductSold = createAsyncThunk('backend_getallservicesbyproductsold', async ({ signal = null, start, end }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByProductSold?startDate=${start}&endDate=${end}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesByConsigmentDevice = createAsyncThunk('backend_getallservicesbyconsigmentdevice', async ({ signal = null, brand }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByConsigmentDevice?brand=${brand}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesByDeletedService = createAsyncThunk('backend_getallservicesbydeletedservice', async ({ signal = null, start, end }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByDeletedService?startDate=${start}&endDate=${end}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
// Silinen Para Hareketleri Eklenecek
export const getAllServicesByIncomingCalls = createAsyncThunk('backend_getallservicesbyincomingcalls', async ({ signal = null, start, end, id, source, brand }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByIncomingCalls?startDate=${start}&endDate=${end}&userId=${id}&sourceName=${source}&brandName=${brand}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesByStage = createAsyncThunk('backend_getallservicesbystage', async ({ signal = null, start, end, stage }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByStage?startDate=${start}&endDate=${end}&stage=${stage}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesByDealer = createAsyncThunk('backend_getallservicesbydealer', async ({ signal = null, start, end, dealer }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByDealer?startDate=${start}&endDate=${end}&dealer=${dealer}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});
export const getAllServicesByEmergency = createAsyncThunk('backend_getallservicesbyemergency', async ({ signal = null, start, end }) => {
  try {
    const res = await axios.get(`${baseurl}GetServicesByEmergency?startDate=${start}&endDate=${end}`, { signal, withCredentials: true });
    return res.data.data;
  } catch (error) { throw error?.response?.data || error; }
});

// Slice
const serviceSlice = createSlice({
  name: 'service',
  initialState: {
    services: [],
    service: {},
    getAllStatus: null,
    getByIdStatus: null,
    addStatus: null,
    updateStatus: null,
    deleteStatus: null,
    error: null
  },
  reducers: {
    resetGetAllStatus: (state) => { state.getAllStatus = null; },
    resetgetByIdStatus: (state) => { state.getByIdStatus = null; },
    resetAddStatus: (state) => { state.addStatus = null; },
    resetUpdateStatus: (state) => { state.updateStatus = null; },
    resetDeleteStatus: (state) => { state.deleteStatus = null; },
    resetError: (state) => { state.error = null; }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllServices.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServices.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServices.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByDate.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByDate.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByDate.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByOperator.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByOperator.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByOperator.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByTecnician.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByTecnician.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByTecnician.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByWorkshopTaken.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByWorkshopTaken.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByWorkshopTaken.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByWorkshopMaster.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByWorkshopMaster.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByWorkshopMaster.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByRepairedInWorkshop.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByRepairedInWorkshop.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByRepairedInWorkshop.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesBySendShipping.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesBySendShipping.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesBySendShipping.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByInTransit.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByInTransit.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByInTransit.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByDelivered.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByDelivered.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByDelivered.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByWaitingParts.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByWaitingParts.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByWaitingParts.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByProductSold.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByProductSold.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByProductSold.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByConsigmentDevice.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByConsigmentDevice.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByConsigmentDevice.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByDeletedService.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByDeletedService.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByDeletedService.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      // Buraya Silinen Para Hareketleri Gelcek
      .addCase(getAllServicesByIncomingCalls.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByIncomingCalls.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByIncomingCalls.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByStage.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByStage.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByStage.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByDealer.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByDealer.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByDealer.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getAllServicesByEmergency.pending, (state) => { state.getAllStatus = "pending"; })
      .addCase(getAllServicesByEmergency.fulfilled, (state, action) => { state.getAllStatus = "fulfilled"; state.services = action.payload || []; })
      .addCase(getAllServicesByEmergency.rejected, (state, action) => { state.getAllStatus = "rejected"; state.error = action.error.message; })
      .addCase(getServiceById.pending, (state) => { state.getByIdStatus = "pending"; })
      .addCase(getServiceById.fulfilled, (state, action) => { state.getByIdStatus = "fulfilled"; state.service = action.payload || {}; })
      .addCase(getServiceById.rejected, (state, action) => { state.getByIdStatus = "rejected"; state.error = action.error.message; })
      .addCase(addNewService.pending, (state) => { state.addStatus = "pending"; })
      .addCase(addNewService.fulfilled, (state) => { state.addStatus = "fulfilled"; })
      .addCase(addNewService.rejected, (state, action) => { state.addStatus = "rejected"; state.error = action.error.message; })
      .addCase(updateService.pending, (state) => { state.updateStatus = "pending"; })
      .addCase(updateService.fulfilled, (state) => { state.updateStatus = "fulfilled"; })
      .addCase(updateService.rejected, (state, action) => { state.updateStatus = "rejected"; state.error = action.error.message; })
      .addCase(deleteService.pending, (state) => { state.deleteStatus = "pending"; })
      .addCase(deleteService.fulfilled, (state) => { state.deleteStatus = "fulfilled"; })
      .addCase(deleteService.rejected, (state, action) => { state.deleteStatus = "rejected"; state.error = action.error.message; });
  }
})

export const { resetGetAllStatus, resetgetByIdStatus, resetAddStatus, resetUpdateStatus, resetDeleteStatus, resetError } = serviceSlice.actions;
export default serviceSlice.reducer;