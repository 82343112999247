// General
import React, { useEffect, useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
// Components
import Table from '../../components/Table';

function UserServicesModal({ show, handleClose, inputData }) {
  // Context Import
  const users = [];
  // Variables
  const [username, setUsername] = useState();
  useEffect(() => {
    if (inputData.length === 0) {
      setUsername('');
    } else {
      setUsername(users.find(item => item.id === inputData[0].userId)?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData]);
  // HTML
  return (
    <Modal show={show} onHide={handleClose} centered className='modal-lg'>
      <Modal.Header closeButton>
        <Modal.Title><b>{username}</b> Adlı Personele Atanmış Aktif Servisler</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table
          data={inputData}
          rows={10}
          columns={[{ value: 'id', name: 'ID' }, { value: 'serviceDate', name: 'TARİH' }, { value: 'status', name: 'DURUM' }]}
          headerAlign='center'
        />
      </Modal.Body>
    </Modal>
  );
}

export default UserServicesModal;