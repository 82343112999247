// General
import { Autocomplete, Button, styled, TextField } from '@mui/material';
import React, { useState } from 'react';
// Component
import Table from '../../components/Table';
// Styles
import { Modal, Tab, Tabs } from 'react-bootstrap';
// Icons
import { TbArrowBackUpDouble } from 'react-icons/tb';
import { MdCloudUpload } from "react-icons/md";
// Modals
import StockTransactionAddModal from './StockTransactionAddModal';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

function StockDetailModal({ show, handleClose, inputData }) {
  // Data & Api
  const data = [
    { productid: inputData.id, date: '10.05.2024', no: '144854', price: '50000', operation: 'onarım', personalName: 'Emir', personalSurname: 'Aksoydan', piece: '10', },];
  const combineddata = data.map((item) => {
    const personalfullname = (
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <p className='m-0 p-0'>{item.personalName}  {item.personalSurname}</p>
      </div>
    )
    const piece = item.piece;
    return { piece: piece, personalName: personalfullname };
  });
  // Variables
  const operations = ["Hepsi", "Alış", "Serviste Kullanım", "Personele Gönder"];
  const [selectOperation, setSelectOperation] = useState('');

  // Table Content
  const cols1 = [{ value: 'date', name: 'TARİH' }, { value: 'operation', name: 'İŞLEM' }, { value: 'no', name: 'NO' }, { value: 'piece', name: 'ADET' }, { value: 'price', name: 'FİYAT' }];
  const cols2 = [{ value: 'personalName', name: 'PERSONEL' }, { value: 'piece', name: 'ADET' }];
  const stocktable1 = () => {
    return (
      <Table
        data={data}
        rows={2}
        columns={cols1}
      />
    );
  }
  const personaltable2 = () => {
    return (
      <Table
        data={combineddata}
        rows={2}
        columns={cols2}
        headerAlign='center'
      />
    );
  }
  //File input style
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  // Modals
  // StockTransaction Add Modal
  const [showStockTransactionAddModal, setShowStockTransactionAddModal] = useState(false);
  const handleCloseStockTransactionAddModal = () => { setShowStockTransactionAddModal(false) };
  const handleShowStockTransactionAddModal = () => { setShowStockTransactionAddModal(true) };
  const handleAddStockTransactionAddModal = (values) => {
    // dispatch(addNewStock({ newstock: values }));
    handleCloseStockTransactionAddModal();
  };
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header closeButton className="border-0">
          <Modal.Title>{inputData.id} numaralı id'ye sahip {inputData.productName} ürününün detayları </Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-0 pt-0'>
          <Tabs defaultActiveKey="warehouse-product-stock" >
            <Tab eventKey="warehouse-product-stock" title='Stok hareketleri'>
              <div className='row m-0 w-100 d-flex justify-content-end pt-4'>
                <div className='col-md-8'>
                  <Autocomplete
                    id="StockDetailModal_operation"
                    size='small'
                    options={operations}
                    value={selectOperation}
                    onChange={(event, newValue) => { setSelectOperation(newValue) }}
                    variant='outlined'
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="İşlem" />}
                  />
                </div>
                <div className='col-md-4'>
                  <button className='btn btn-success w-100 ' onClick={handleShowStockTransactionAddModal}>
                    Hareket Ekle
                  </button>
                </div>
                <div className=' col-md-12'>
                  {stocktable1()}
                </div>
              </div>
            </Tab>
            <Tab eventKey="warehouse-personal-stock" title='Personel Stokları'>
              <div className="row m-0 w-100 justify-content-center pt-4">
                <div className="col-md-12  ">
                  {personaltable2()}
                </div>
              </div>
            </Tab>
            <Tab eventKey="warehouse-product-photos" title='Fotoğraflar'>
              <div className='row justify-content-center px-3  pt-4 '>
                <Button
                  className='fs-7'
                  component="label"
                  role={undefined}
                  variant="outlined"
                  tabIndex={-1}
                  startIcon={<MdCloudUpload />}
                >
                  Resim dosyası ekleyin (en fazla 5 MB jpg,png dosyaları yüklenebilir )
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileChange}
                    multiple
                  />
                </Button>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer className='px-0'>
          <div className='row m-0 d-flex justify-content-end align-items-center w-100'>
            <div className='col-12 col-md-5'>
              <button className='button-with-icon btn btn-warning w-100' onClick={handleClose}><TbArrowBackUpDouble className='button-icon' />Geri</button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <StockTransactionAddModal
        show={showStockTransactionAddModal}
        handleClose={handleCloseStockTransactionAddModal}
        handleAddTransaction={handleAddStockTransactionAddModal}>
      </StockTransactionAddModal>
    </div>
  );
}

// Local Functions
function handleFileChange(event) {
  const files = event.target.files;
  if (files.length > 0) {
    [...files].map((file) => {
      const selectfile = file;
      const fileName = selectfile.name.toLowerCase();
      const fileSize = selectfile.size;
      const validFileExtensions = ['.jpg', '.jpeg', '.png'];
      const fileExtension = fileName.slice(fileName.lastIndexOf('.'));
      if (!validFileExtensions.includes(fileExtension)) {
        alert('Sadece JPG ve PNG dosyalarına izin verilmektedir.');
        event.target.value = null;
        return;
      }
      if (fileSize > MAX_FILE_SIZE) {
        alert('Dosya boyutu 5 MB\'ı geçemez.');
        event.target.value = null;
        return;
      }
      console.log('Geçerli dosya:', file);
    })
  }
}

export default StockDetailModal;