
//General
import React, { useEffect, useState } from 'react';
// Styles
import { Modal } from 'react-bootstrap';
// Context
import { useFunctions } from '../../../../contexts/FunctionsContext';
// Icons
import { TbArrowBackUpDouble } from "react-icons/tb";
// Components
import Table from '../../components/Table';

function DealerDetailModal({ show, handleClose, inputData }) {
  // Context Import
  const { openNewPage } = useFunctions();
  // Filtering
  const filters = ['serviceid', 'device', 'operation'];
  // Data & Api
  const [dataToTable, setDataToTable] = useState([]);
  useEffect(() => {
    const data = inputData;
    if (JSON.stringify(data) !== '{}') {
      const toTable = {
        serviceid: data.serviceid,
        device: data.device,
        operation: data.operation
      };
      setDataToTable(toTable);
    }
  }, [inputData]);
  // Table Content
  const cols = [{ value: 'serviceid', name: 'SERVİS ID' }, { value: 'device', name: 'CİHAZ ADI' }, { value: 'operation', name: 'İŞLEM' }];
  const table = () => {
    return (
      <Table
        data={[dataToTable]}
        rows={10}
        columns={cols}
        filternames={filters}
        select={OpenServicePage}
        headerAlign='center'
        searchfullwidth={true}

      />
    );
  }
  // Local Functions
  const OpenServicePage = () => {
    openNewPage("Servisler", inputData.serviceid)
  }
  // HTML
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered className='modal'>
        <Modal.Header className='py-2'>
          <Modal.Title> Detaylar </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {table()}
        </Modal.Body>
      </Modal>
    </div >
  )
}

export default DealerDetailModal