// General
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// Styles
import './assets/css/app.css';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primereact/resources/primereact.css';
// Contexts
import { PrimeReactProvider } from 'primereact/api';
import { DataProvider } from './contexts/DataContext';
import { FunctionProvider } from './contexts/FunctionsContext';
import { ValidationsProvider } from './contexts/ValidationContext';
// Components
import ToastMessages from './view/pages/components/ToastMessages';
// Pages
import Login from './view/pages/contents/Login';
import Layout from './view/Layout';
import Dashboard from './view/pages/contents/Dashboard';
import Services from './view/pages/contents/Services';
import Customers from './view/pages/contents/Customers';
import Employees from './view/pages/contents/Employees';
import Warehouse from './view/pages/contents/Warehouse';
import Offers from './view/pages/contents/Offers';
import Reminder from './view/pages/contents/Reminder';
import Surveys from './view/pages/contents/Surveys';
import Statistics from './view/pages/contents/Statistics';
import Checkout from './view/pages/contents/Checkout';
import Invoices from './view/pages/contents/Invoices';
import BulkMessages from './view/pages/contents/BulkMessages';
import OperationReports from './view/pages/contents/OperationReports';
import Vehicles from './view/pages/contents/Vehicles';
import Suppliers from './view/pages/contents/Suppliers';
import Warehouses from './view/pages/contents/Warehouses';
import Shelves from './view/pages/contents/Shelves';
import Roles from './view/pages/contents/Roles';
import Brands from './view/pages/contents/Brands';
import Devices from './view/pages/contents/Devices';
import Dealers from './view/pages/contents/Dealers';
import StockActions from './view/pages/contents/StockActions';
import Error404 from './view/pages/contents/Error404';
import Sources from './view/pages/contents/Sources';
import Consigments from './view/pages/contents/Consigments';

function App() {
  // HTML
  return (
    <DataProvider>
      <ValidationsProvider>
        <FunctionProvider>
          <PrimeReactProvider>
            <Router>
              <AppContent />
            </Router>
          </PrimeReactProvider>
        </FunctionProvider>
      </ValidationsProvider>
    </DataProvider>
  );
}

function AppContent() {
  // HTML
  return (
    <div>
      <Routes>
        <Route path="/girisyap" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/anasayfa" />} />
          <Route path="anasayfa" element={<Dashboard />} />
          <Route path="servisler" element={<Services />} />
          <Route path="musteriler" element={<Customers />} />
          <Route path="personeller" element={<Employees />} />
          <Route path="depo" element={<Warehouse />} />
          <Route path="toplumesaj" element={<BulkMessages />} />
          <Route path="kasa" element={<Checkout />} />
          <Route path="fatura" element={<Invoices />} />
          <Route path="teklifler" element={<Offers />} />
          <Route path="islemraporları" element={<OperationReports />} />
          <Route path="hatirlatici" element={<Reminder />} />
          <Route path="istatistikler" element={<Statistics />} />
          <Route path="anketler" element={<Surveys />} />
          <Route path="araclar" element={<Vehicles />} />
          <Route path="tedarikciler" element={<Suppliers />} />
          <Route path="depolar" element={<Warehouses />} />
          <Route path="raflar" element={<Shelves />} />
          <Route path="roller" element={<Roles />} />
          <Route path="markalar" element={<Brands />} />
          <Route path="cihazlar" element={<Devices />} />
          <Route path="bayiler" element={<Dealers />} />
          <Route path="stokaksiyonlari" element={<StockActions />} />
          <Route path="kaynaklar" element={<Sources />} />
          <Route path="konsinyecihazlar" element={<Consigments />} />
        </Route>
        <Route path="/*" element={<Error404 />} />
      </Routes>
      <ToastMessages />
    </div>
  );
}

export default App;